import BaseRoute from 'uplisting-frontend/pods/base/route';
import { type Registry as Services, inject as service } from '@ember/service';
import Transition from '@ember/routing/transition';
import ClientsManagementFeeRulesShowController from 'uplisting-frontend/pods/clients/management-fee-rules/edit/controller';
import RSVP from 'rsvp';

export default class ClientsManagementFeeRulesShowRoute extends BaseRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/management-fee-rule')
  managementFeeRuleRepository!: Services['repositories/management-fee-rule'];

  model({ id }: { id: string }) {
    const feeRule =
      id === 'new'
        ? this.managementFeeRuleRepository.createRecord()
        : this.managementFeeRuleRepository.findRecord(id, {
            include: 'properties',
          });

    return RSVP.hash({
      feeRule,
      feeRules: this.managementFeeRuleRepository.findAll({
        include: 'properties',
      }),
      properties: this.propertyRepository.findAll({ reload: true }),
    });
  }

  setupController(
    controller: ClientsManagementFeeRulesShowController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }

  resetController(controller: ClientsManagementFeeRulesShowController) {
    if (!controller.feeRule?.id) {
      controller.feeRule?.unloadRecord();
    }

    controller.sampleError = undefined;
    controller.sampleResult = undefined;
  }
}
