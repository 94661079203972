import Model, { attr, hasMany } from '@ember-data/model';
import { IStripeConnectionSchema } from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';

export default class StripeConnectionModel
  extends Model
  implements IStripeConnectionSchema
{
  @attr('string') name!: string;
  @attr('string') code!: string;
  @attr('string') stripeUserId!: string;
  @attr('string') status!: string;

  @hasMany('property', { inverse: 'stripeConnection', async: false })
  properties!: PropertyModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'stripe-connection': StripeConnectionModel;
  }
}
