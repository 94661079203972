import { helper } from '@ember/component/helper';
import { format } from 'date-fns/format';
import config from 'ember-get-config';

export function timeFormat([time]: [string]): string {
  if (!time || config.IS_TEST) {
    return time;
  }

  const [hours, minutes] = time.split(':');
  if (!hours || !minutes) {
    return time;
  }

  const date = new Date();

  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));

  return format(date, 'h:mmaaa');
}

export default helper(timeFormat);
