import Component from '@glimmer/component';
import ClientStatementModel from 'uplisting-frontend/models/client-statement';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { runTask } from 'ember-lifeline';
import { modifier } from 'ember-modifier';

interface IArgs {
  isFinalizedView: boolean;
  statement: ClientStatementModel;
}

interface StatementsPreviewSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const scaleStep = 25;

interface IScaleOption {
  name: string;
  percentage: number;
  default?: boolean;
}

export default class UiStatementsPreviewComponent extends Component<StatementsPreviewSignature> {
  @service intl!: Services['intl'];

  iframeMainElement!: HTMLElement;
  iframeElement!: HTMLIFrameElement;

  @cached @tracked _previewScale?: IScaleOption;
  @cached @tracked isLoaded = false;

  @cached @tracked previewScales: IScaleOption[] = [
    {
      name: '50%',
      percentage: 50,
    },
    {
      name: '75%',
      percentage: 75,
    },
    {
      name: '100%',
      percentage: 100,
      default: true,
    },
    {
      name: '125%',
      percentage: 125,
    },
    {
      name: '150%',
      percentage: 150,
    },
    {
      name: '175%',
      percentage: 175,
    },
    {
      name: '200%',
      percentage: 200,
    },
  ];

  @cached
  get previewScale(): IScaleOption {
    return (
      this._previewScale ||
      (this.previewScales.find((scale) => scale.default) as IScaleOption)
    );
  }

  set previewScale(scale: IScaleOption) {
    this._previewScale = scale;
  }

  @cached
  get isFinalizedView(): boolean {
    return this.args.isFinalizedView;
  }

  @cached
  get isIncreaseScaleDisabled(): boolean {
    const { previewScale, previewScales } = this;

    const maxScale = previewScales[previewScales.length - 1] as IScaleOption;

    return previewScale.percentage + scaleStep > maxScale.percentage;
  }

  @cached
  get isDecreaseScaleDisabled(): boolean {
    const { previewScale, previewScales } = this;

    const minScale = previewScales[0] as IScaleOption;

    return previewScale.percentage <= minScale.percentage;
  }

  @action
  handleChangeScale(scale: IScaleOption): void {
    this.previewScale = scale;

    this.updateFrameScale();
  }

  @action
  handleIncreaseScale(): void {
    if (this.isIncreaseScaleDisabled) {
      return;
    }

    const activeIndex = this.previewScales.indexOf(this.previewScale);
    this.previewScale = this.previewScales[activeIndex + 1] as IScaleOption;

    this.updateFrameScale();
  }

  @action
  handleDecreaseScale(): void {
    if (this.isDecreaseScaleDisabled) {
      return;
    }

    const activeIndex = this.previewScales.indexOf(this.previewScale);
    this.previewScale = this.previewScales[activeIndex - 1] as IScaleOption;

    this.updateFrameScale();
  }

  handleFrameInsert = modifier((iframe: HTMLIFrameElement) => {
    runTask(
      this,
      () => {
        const iframeMainElement =
          iframe.contentWindow?.document.getElementsByTagName('html')[0];

        this.iframeElement = iframe;
        this.iframeMainElement = iframeMainElement as HTMLElement;

        this.updateFrameScale(true);
        this.isLoaded = true;
      },
      50,
    );
  });

  private updateFrameScale(isInitialSetup?: boolean): void {
    const { iframeMainElement } = this;

    if (!iframeMainElement) {
      return;
    }

    const scale = isInitialSetup
      ? this.calculateInitialScale(iframeMainElement)
      : this.previewScale.percentage / 100;

    const translatePosition = ((1 - scale) / 2) * 100;

    iframeMainElement.style.transformOrigin = '0 0';
    iframeMainElement.style.transition = 'transform 0.6s ease-in';
    iframeMainElement.style.transform = `translateX(${
      translatePosition > 0 ? translatePosition : 0
    }%) scale(${scale})`;
  }

  private calculateInitialScale(element: HTMLElement): number {
    const page = element.querySelector('.preview-page');

    const defaultScale = this.previewScale.percentage / 100;

    if (
      !page ||
      !page.parentElement ||
      page.clientWidth <= page.parentElement.clientWidth
    ) {
      return defaultScale;
    }

    const customScale = Number(
      (page.parentElement.clientWidth / page.clientWidth).toFixed(1),
    );

    const scalePercentage = customScale * 100;

    const newScale = {
      name: this.intl.t('clients_statements_show.preview.fit_width'),
      percentage: scalePercentage,
      default: true,
    };

    this.previewScale = newScale;
    this.previewScales = [newScale, ...this.previewScales];

    return customScale;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Statements::Preview': typeof UiStatementsPreviewComponent;
  }
}
