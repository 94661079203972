import PropertyModel from 'uplisting-frontend/models/property';

export enum AirbnbOfficialLinkStatus {
  inactive = 'inactive',
  confirmed = 'confirmed',
  readOnly = 'read_only',
}

export interface IAirbnbOfficialLinkSchema {
  status?: string;

  property: PropertyModel;
}
