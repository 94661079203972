import Transform from '@ember-data/serializer/transform';
import { isNone as none } from '@ember/utils';

export default class StringOrEmptyTransform extends Transform {
  deserialize(serialized): string {
    return none(serialized) ? '' : String(serialized);
  }

  serialize(deserialized): string {
    return none(deserialized) ? '' : String(deserialized);
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'string-or-empty': StringOrEmptyTransform;
  }
}
