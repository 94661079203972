import Model, { attr, hasMany } from '@ember-data/model';
import PropertyModel from 'uplisting-frontend/models/property';
import { IPropertyTagSchema } from 'uplisting-frontend/models/schemas';
import { cached } from '@glimmer/tracking';

export default class PropertyTagModel
  extends Model
  implements IPropertyTagSchema
{
  @attr('string') name!: string;

  @hasMany('property', { inverse: 'tags', async: false })
  properties!: PropertyModel[];

  @cached
  get searchName(): string {
    return this.name;
  }

  @cached
  get propertyIds(): string[] {
    return this.properties.map((property) => property.id);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'property-tag': PropertyTagModel;
  }
}
