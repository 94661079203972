import Component from '@glimmer/component';
import { OccasionSingleSelectFilter } from 'uplisting-frontend/utils/occasion-filters';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';

interface IArgs {
  filter: OccasionSingleSelectFilter;
}

interface ReportFiltersOptionsSignature {
  Element: HTMLUListElement;

  Args: IArgs;
}

export default class UiReportFiltersOptionsSingleSelectComponent extends Component<ReportFiltersOptionsSignature> {
  @cached
  get filter(): OccasionSingleSelectFilter {
    return this.args.filter;
  }

  @action
  handleOptionSelect(option: string): void {
    this.filter.updateValues(option);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options::SingleSelect': typeof UiReportFiltersOptionsSingleSelectComponent;
  }
}
