import Component from '@glimmer/component';
import {
  ITab,
  TableSection,
} from 'uplisting-frontend/pods/dashboard/index/controller';
import { SectionIds } from 'uplisting-frontend/models/occasion';

interface IArgs {
  tabs: ITab[];
  field: TableSection;
  isActiveTab(field: TableSection, id: SectionIds): boolean;
  handleTabChange(field: TableSection, id: SectionIds): void;
}

interface DashboardTabSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

/**
 * @description dashboard tabs, which are rendering table sections. by clicking on tab items - new table section becomes active
 */
export default class UiDashboardTabsComponent extends Component<DashboardTabSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Dashboard::Tabs': typeof UiDashboardTabsComponent;
  }
}
