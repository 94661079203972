import Component from '@glimmer/component';
import Model from '@ember-data/model';
import { IExtra } from 'uplisting-frontend/pods/components/ui/filters/filter/multi-select/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Select } from 'ember-power-select/components/power-select';

interface IArgs {
  select: Select;
  option: Model;
  extra: IExtra;
}

interface FiltersFilterMultiSelectSelectedSignature {
  Element: null;

  Args: IArgs;
}

export default class FiltersFilterMultiSelectSelectedComponent extends Component<FiltersFilterMultiSelectSelectedSignature> {
  @cached
  get value(): string {
    return this.args.option[this.args.extra.modelField] as string;
  }

  @action
  handleReset(): void {
    this.args.extra.handleChange(null);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Filters::Filter::MultiSelect::Selected': typeof FiltersFilterMultiSelectSelectedComponent;
    'ui/filters/filter/multi-select/selected': typeof FiltersFilterMultiSelectSelectedComponent;
  }
}
