import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import AccountConfigurationModel from 'uplisting-frontend/models/account-configuration';
import DS from 'ember-data';
import { AccountConfigurationValidation } from 'uplisting-frontend/validations';

export default class AccountConfigurationRepositoryService extends BaseRepositoryService<AccountConfigurationModel> {
  @service analytics!: Services['analytics'];

  recordName = 'account-configuration';
  implementMethods = ['findRecord', 'unloadAll', 'buildChangeset'];

  validation = AccountConfigurationValidation;

  currentAccountConfiguration!: AccountConfigurationModel;

  public async findRecord(
    id: string,
  ): Promise<DS.PromiseObject<AccountConfigurationModel>> {
    const record = await super.findRecord(id);

    this.currentAccountConfiguration = record;

    return record;
  }

  public async enableClientManagement(): Promise<void> {
    this.currentAccountConfiguration.clientManagementEnabled = true;

    await this.currentAccountConfiguration.save();

    this.analytics.trackEvent(AnalyticsEvents.clientManagementEnabled);
  }

  public async disableClientManagement(): Promise<void> {
    this.currentAccountConfiguration.clientManagementEnabled = false;
    const clientStatementsWerePreviouslyEnabled =
      this.currentAccountConfiguration.clientStatementsEnabled;

    await this.currentAccountConfiguration.save();

    this.analytics.trackEvent(AnalyticsEvents.clientManagementDisabled);

    if (clientStatementsWerePreviouslyEnabled) {
      this.analytics.trackEvent(AnalyticsEvents.clientStatementsDisabled);
    }
  }

  public async enableClientStatements(): Promise<void> {
    this.currentAccountConfiguration.clientStatementsEnabled = true;
    const clientManagementWasPreviouslyDisabled =
      !this.currentAccountConfiguration.clientManagementEnabled;

    await this.currentAccountConfiguration.save();

    this.analytics.trackEvent(AnalyticsEvents.clientStatementsEnabled);

    if (clientManagementWasPreviouslyDisabled) {
      this.analytics.trackEvent(AnalyticsEvents.clientManagementEnabled);
    }
  }

  public async disableClientStatements(): Promise<void> {
    this.currentAccountConfiguration.clientStatementsEnabled = false;

    await this.currentAccountConfiguration.save();

    this.analytics.trackEvent(AnalyticsEvents.clientStatementsDisabled);
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/account-configuration': AccountConfigurationRepositoryService;
  }
}
