import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import AdvancedReportConfigurationModel from 'uplisting-frontend/models/advanced-report-configuration';
import { IFieldInfo } from 'uplisting-frontend/services/occasions';
import { dropTask } from 'ember-concurrency';
import OccasionModel, {
  OccasionAttribute,
  propertyToFieldMapping,
  fieldToPropertyMapping,
} from 'uplisting-frontend/models/occasion';
import { action } from '@ember/object';
import { OccasionType } from 'uplisting-frontend/models/schemas';
import { toggleValue, handleTableScrollSize } from 'uplisting-frontend/utils';
import { ref } from 'ember-ref-bucket';
import {
  OccasionFilter,
  OccasionFilterGroup,
} from 'uplisting-frontend/utils/occasion-filters';
import { scheduleTask, runTask } from 'ember-lifeline';
import { isNone } from '@ember/utils';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import isEqual from 'lodash-es/isEqual';

export default class ReportsShowController extends BaseController {
  @service('occasions') occasionsService!: Services['occasions'];

  @service('repositories/occasion')
  occasionRepositoryService!: Services['repositories/occasion'];

  @service('repositories/advanced-report-configuration')
  advancedReportConfigurationRepository!: Services['repositories/advanced-report-configuration'];

  @ref('table') table!: HTMLTableElement;

  @cached @tracked report!: AdvancedReportConfigurationModel;
  @cached @tracked reportToEdit?: AdvancedReportConfigurationModel;
  @cached @tracked occasions: OccasionModel[] = [];
  @cached @tracked showFormValidation = false;

  @cached @tracked filterGroup!: OccasionFilterGroup;

  @cached @tracked activePage = 1;
  @cached @tracked totalPages: number | undefined;

  activeObserver!: IntersectionObserver;
  observerElements!: Element[];

  @cached
  get fields(): string[] {
    return this.report.criteria.fields.occasions.split(',');
  }

  set fields(value: string[]) {
    this.report.criteria.fields = {
      occasions: value.join(','),
    };
  }

  @cached
  get selectedItems(): OccasionAttribute[] {
    return this.fields.map((field) => propertyToFieldMapping[field]);
  }

  @cached
  get selectedFieldInfo(): IFieldInfo[] {
    return this.occasionsService.fieldsInfo.filter((x) =>
      this.selectedItems.includes(x.property),
    );
  }

  @cached
  get changeset(): GenericChangeset<AdvancedReportConfigurationModel> {
    return this.advancedReportConfigurationRepository.buildChangeset(
      this.reportToEdit || this.report,
    );
  }

  @cached
  get hasAnyChanges(): boolean {
    const { report } = this;

    const savedFilters = this.occasionsService
      .createGroupFromFilters(report.criteria.filter.occasion_filters)
      .toQuery();
    const currentFilters = this.filterGroup.toQuery();

    if (!isEqual(savedFilters, currentFilters)) {
      return true;
    }

    return (
      this.changeset.name !== this.report.name || report.criteria.hasChanges
    );
  }

  @cached
  get timezone(): string {
    return this.report.criteria.timeZone || this.defaultTimezone;
  }

  @action
  async handleSaveReport(isCreatingNew = false): Promise<void> {
    if (!isCreatingNew) {
      this.discardReportToEdit();
    }

    this.changeset.criteria.filter.occasion_filters =
      this.filterGroup.toQuery();

    // can't use #isSaveDisabled method here, because changeset doesn't support nested data types comparison
    await this.changeset.validate();

    if (this.changeset.isInvalid) {
      return;
    }

    try {
      const data = await this.changeset.save();

      this.notifications.success('notifications.applied');

      await this.router.transitionTo('reports.show', data.id);
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleCreateNewReport(): Promise<void> {
    const { report, advancedReportConfigurationRepository: repository } = this;

    this.showFormValidation = true;

    this.reportToEdit = repository.createRecord({
      name: this.changeset.name,
      criteria: report.criteria.toQuery(),
    });

    await this.handleSaveReport(true);
  }

  @action
  handleColumnsApply(keys: OccasionAttribute[]): void {
    const { fieldsInfo: info } = this.occasionsService;

    const indexOfKey = (key: OccasionAttribute) =>
      info.indexOf(info.find((item) => item.property === key) as IFieldInfo);

    const sorted = keys.sort((x, y) => indexOfKey(x) - indexOfKey(y));

    this.fields = sorted.map((property) => fieldToPropertyMapping[property]);

    this.calculateTableSize();
  }

  @action
  handleAddGroup(): OccasionFilterGroup {
    const newGroup = this.occasionsService.createFilterGroup([]);

    this.filterGroup.values = [...this.filterGroup.values, newGroup];

    return newGroup;
  }

  @action
  async handleRemoveFilterGroup(
    filterGroup: OccasionFilterGroup,
  ): Promise<void> {
    toggleValue(this.filterGroup, 'values', filterGroup);

    if (filterGroup.isEmpty) {
      return;
    }

    await this.fetchData(true);
  }

  async handleAddFilter(
    filter: OccasionFilter,
    group: OccasionFilterGroup,
  ): Promise<void> {
    const filterGroup = group || this.filterGroup;

    filterGroup.values = [...filterGroup.values, filter];

    await this.fetchData(true);
  }

  @action
  async handleChangeFilter(
    filter: OccasionFilter,
    group: OccasionFilterGroup,
    editableFilterIndex: number,
  ): Promise<void> {
    if (!isNone(editableFilterIndex)) {
      group.values[editableFilterIndex] = filter;
      group.values = [...group.values];
    } else {
      toggleValue(group, 'values', filter);
    }

    await this.fetchData(true);
  }

  @action
  public async fetchData(isInitial = false): Promise<void> {
    if (this.fetchDataTask.isRunning) {
      return;
    }

    if (isInitial) {
      this.totalPages = undefined;
      this.activePage = 1;
    }

    if (!isNone(this.totalPages) && this.totalPages < this.activePage) {
      return;
    }

    await this.fetchDataTask.perform(isInitial);

    this.discardReportToEdit();
  }

  @action
  async handleRequestReport(): Promise<void> {
    if (this.hasAnyChanges) {
      return;
    }

    const report = this.store.createRecord('advanced-report', {
      advancedReportConfiguration: this.report,
    });

    try {
      await report.save();

      report.unloadRecord();

      this.notifications.success('reports_show.report_requested');
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleTimeZoneChange(timezone: string): Promise<void> {
    this.report.criteria.timeZone = timezone;

    this.resetPagination();

    await this.fetchDataTask.perform(true);
  }

  public fetchDataTask = dropTask(async (isInitial) => {
    const data = await this.occasionRepositoryService.query({
      ...this.report.criteria.toQuery(),
      fields: {
        occasions: this.occasionRepositoryService.allBookingFields,
      },
      time_zone: this.timezone,
      filter: {
        occasion_filters: this.filterGroup.toQuery(),
        occasion_type: OccasionType.booking,
      },
      sort: this.report.criteria.sort || undefined,
      page: {
        number: this.activePage,
        size: 20,
      },
    });

    if (isInitial) {
      // @ts-expect-error - we have no typings for the meta
      this.totalPages = data.meta.total_pages;
      this.occasions.forEach((occasion) => {
        if (data.includes(occasion) || occasion.isDestroyed) {
          return;
        }
        occasion.unloadRecord();
      });
      this.occasions = data.slice();
    } else {
      this.occasions = [...this.occasions, ...data.slice()];
    }

    this.activePage++;

    this.calculateTableSize();
  });

  public calculateTableSize(): void {
    runTask(
      this,
      () => {
        handleTableScrollSize(this, this.table);

        scheduleTask(this, 'render', () => {
          this.setIntersectionObserver();
        });
      },
      10,
    );
  }

  public setDefaultFilterGroup(): void {
    this.filterGroup = this.occasionsService.createGroupFromFilters(
      this.report.criteria.filter.occasion_filters,
    );
  }

  public discardReportToEdit(): void {
    if (this.reportToEdit) {
      const { name } = this.changeset;

      if (!this.reportToEdit.id) {
        this.reportToEdit.unloadRecord();
      }

      this.reportToEdit = undefined;

      this.changeset.name = name;
    }
  }

  public resetPagination(): void {
    this.activePage = 1;
    this.totalPages = undefined;
  }

  private setIntersectionObserver(): void {
    this.clearActiveObserver();

    const items = this.getLastRowItems();

    if (!items) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          const isOutOfScreen = entry.intersectionRatio === 0;

          if (isOutOfScreen) {
            return;
          }

          const isLastItemReached = items.some((item) => entry.target === item);

          if (isLastItemReached) {
            await this.fetchData();
          }
        });
      },
      {
        threshold: 0.25,
      },
    );

    items.forEach((item) => {
      observer.observe(item);
    });

    this.activeObserver = observer;
    this.observerElements = items;
  }

  private getLastRowItems(): Element[] | undefined {
    const tBody = this.table?.tBodies?.[0];

    if (!tBody) {
      return;
    }

    const lastRow = tBody.children[tBody.children.length - 1] as Element;

    return [...lastRow.children];
  }

  private clearActiveObserver(): void {
    if (this.observerElements?.length) {
      this.observerElements.forEach((element) => {
        this.activeObserver.unobserve(element);
      });
    }

    this.activeObserver?.disconnect();
  }
}
