import Model, { attr } from '@ember-data/model';
import { ICustomPropertyAttributeSchema } from 'uplisting-frontend/models/schemas';
import { cached } from '@glimmer/tracking';

export default class CustomPropertyAttributeModel
  extends Model
  implements ICustomPropertyAttributeSchema
{
  @attr('string') name!: string;
  @attr('string') description!: string;

  @cached
  get tagCode(): string {
    return `{{{${this.name}}}}`;
  }

  @cached
  get isPersisted(): boolean {
    return !!this.id;
  }

  @cached
  get order(): number {
    if (!this.isPersisted) {
      return 0;
    }

    return Number(this.id);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'custom-property-attribute': CustomPropertyAttributeModel;
  }
}
