import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { ISeamLockableSchema } from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import SeamConnectionModel from 'uplisting-frontend/models/seam-connection';
import { toggleHasManyValue } from 'uplisting-frontend/utils';
import { cached } from '@glimmer/tracking';

type BatteryStatus = 'critical' | 'low' | 'good' | 'full';

function getBatteryStatus(level: number): BatteryStatus {
  if (level >= 0.8) {
    return 'full';
  } else if (level >= 0.4 && level < 0.8) {
    return 'good';
  } else if (level >= 0.2 && level < 0.4) {
    return 'low';
  }

  return 'critical';
}

export default class SeamLockableModel
  extends Model
  implements ISeamLockableSchema
{
  @attr('string') deviceId!: string;
  @attr('string') deviceType!: string;
  @attr('string') name!: string;

  @attr('number') batteryLevel!: number;
  @attr('number') keypadBatteryLevel!: number | null;
  @attr('boolean') doorOpen!: boolean;
  @attr('boolean') locked!: boolean;
  @attr('boolean') online!: boolean;

  @hasMany('property', { async: false, inverse: null })
  properties!: PropertyModel[];

  @hasMany('multi-unit', { async: false, inverse: null })
  multiUnits!: MultiUnitModel[];

  @belongsTo('seam-connection', { async: false, inverse: null })
  seamConnection!: SeamConnectionModel;

  @cached
  get batteryStatus(): BatteryStatus {
    return getBatteryStatus(this.batteryLevel);
  }

  @cached
  get keypadBatteryStatus(): BatteryStatus {
    return getBatteryStatus(this.keypadBatteryLevel as number);
  }

  public toggleOption(option: PropertyModel | MultiUnitModel): void {
    if (option instanceof PropertyModel) {
      toggleHasManyValue(this, 'properties', option);

      option.multiUnits.forEach((multiUnit) => {
        if (this.multiUnits.includes(multiUnit)) {
          this.multiUnits.splice(this.multiUnits.indexOf(multiUnit), 1);
        }
      });
    } else {
      toggleHasManyValue(this, 'multiUnits', option);

      if (this.properties.includes(option.property)) {
        this.properties.splice(this.properties.indexOf(option.property), 1);
      }
    }
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'seam-lockable': SeamLockableModel;
  }
}
