import { validatePresence } from 'ember-changeset-validations/validators';
import {
  validateUniqueness,
  customMessageValidator,
} from 'uplisting-frontend/validators';

export const CustomPropertyAttributeValidation = {
  name: [
    customMessageValidator(
      'custom_property_attribute_name',
      validatePresence({
        presence: true,
      }),
    ),
    customMessageValidator(
      'unique_custom_property_attribute',
      validateUniqueness(),
    ),
  ],

  description: [
    customMessageValidator(
      'custom_property_attribute_description',
      validatePresence({
        presence: true,
      }),
    ),
  ],
};
