import Component from '@glimmer/component';

interface IArgs {
  text: string;
}

interface EmptyStateSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default?: [];
  };
}

export default class UiEmptyStateComponent extends Component<EmptyStateSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::EmptyState': typeof UiEmptyStateComponent;
  }
}
