import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached } from '@glimmer/tracking';
import { ILink } from 'uplisting-frontend/pods/components/ui/nav-links/component';
import { type Registry as Services, inject as service } from '@ember/service';

export default class ReportsController extends BaseController {
  @service features!: Services['features'];

  @service('repositories/advanced-report-configuration')
  advancedReportConfigurationRepository!: Services['repositories/advanced-report-configuration'];

  @cached
  get navLinks(): ILink[] {
    return this.advancedReportConfigurationRepository
      .peekAll()
      .filter((report) => report.id)
      .map((report) => ({
        id: report.id,
        name: report.name,
        route: 'reports.show',
        permitted: true,
      }));
  }
}
