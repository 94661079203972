import BaseController from 'uplisting-frontend/pods/base/controller';
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import ClientsRoute from 'uplisting-frontend/pods/clients/route';

export default class ClientsDeactivateController extends BaseController {
  @action
  async handleModalSubmit(): Promise<void> {
    try {
      await this.accountConfigurationRepository.disableClientManagement();

      const clientsRoute = getOwner(this)?.lookup(
        'route:clients',
      ) as ClientsRoute;

      await clientsRoute.refresh();
    } catch {
      // ignore in case of error, here `TransitionAborted` is being thrown for some reason
    }
  }
}
