import { helper } from '@ember/component/helper';
import { dayNames } from 'uplisting-frontend/utils';

export function dayFormat([date]: [Date]): string {
  const day = date.getDay();

  return dayNames[day];
}

export default helper(dayFormat);
