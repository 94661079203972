import DS from 'ember-data';
import ApplicationAdapter from 'uplisting-frontend/adapters/application';

const REQUEST_V1_ENDPOINT_METHODS = ['findRecord', 'updateRecord'];

export default class PropertyAdapter extends ApplicationAdapter {
  buildURL<K extends string | number>(
    modelName?: K | undefined,
    id?: string | object | any[] | null | undefined,
    snapshot?: any[] | DS.Snapshot<K> | null | undefined,
    requestType?: string | undefined,
    query?: object | undefined,
  ): string {
    const useV1Namespace = REQUEST_V1_ENDPOINT_METHODS.includes(
      requestType as string,
    );

    this.namespace = useV1Namespace ? '' : 'v2';

    return super.buildURL(modelName, id, snapshot, requestType, query);
  }
}

declare module 'ember-data/types/registries/adapter' {
  interface AdapterRegistry {
    property: PropertyAdapter;
  }
}
