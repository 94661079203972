import {
  FilterId,
  FilterOptionsType,
  MonthFilter as BaseMonthFilter,
} from 'uplisting-frontend/utils/filters/abstract';

export class MonthFilter extends BaseMonthFilter {
  id = FilterId.period;
  optionsType = FilterOptionsType.list;
  optionsList = [];
}
