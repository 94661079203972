export default {
  "menu-wrapper": "_menu-wrapper_lv6jtp",
  "sections-wrapper": "_sections-wrapper_lv6jtp",
  "section": "_section_lv6jtp",
  "section-header": "_section-header_lv6jtp",
  "section-body": "_section-body_lv6jtp",
  "search-input": "_search-input_lv6jtp",
  "disabled": "_disabled_lv6jtp",
  "footer": "_footer_lv6jtp"
};
