import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import PropertyTagModel from 'uplisting-frontend/models/property-tag';

export default class PropertyTagRepositoryService extends BaseRepositoryService<PropertyTagModel> {
  recordName = 'property-tag';
  implementMethods = ['findAll', 'peekAll', 'peekRecord'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/property-tag': PropertyTagRepositoryService;
  }
}
