import Component from '@glimmer/component';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import { IActionCard } from 'uplisting-frontend/pods/components/ui/action-card/component';
import shuffle from 'lodash-es/shuffle';
import config from 'ember-get-config';

interface IArgs {
  title: string;
  description: string;
}

interface FeatureListSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

/**
 * @description renders all feature cards, provides name, short description, and links to read more
 */
export default class UiFeatureListComponent extends Component<FeatureListSignature> {
  @service intl!: Services['intl'];

  @cached
  get features(): IActionCard[] {
    const { intl } = this;

    const data = [
      {
        title: intl.t('dashboard_index.feature_cards.titles.automated_review'),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.automated_review',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.automated_review',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.automated_review',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.automated_review',
        ),
      },
      {
        title: intl.t(
          'dashboard_index.feature_cards.titles.direct_booking_website',
        ),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.direct_booking_website',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.direct_booking_website',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.direct_booking_website',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.direct_booking_website',
        ),
      },
      {
        title: intl.t(
          'dashboard_index.feature_cards.titles.automated_guest_messaging',
        ),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.automated_guest_messaging',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.automated_guest_messaging',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.automated_guest_messaging',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.automated_guest_messaging',
        ),
      },
      {
        title: intl.t(
          'dashboard_index.feature_cards.titles.custom_property_attributes',
        ),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.custom_property_attributes',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.custom_property_attributes',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.custom_property_attributes',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.custom_property_attributes',
        ),
      },
      {
        title: intl.t(
          'dashboard_index.feature_cards.titles.enquiry_auto_responder',
        ),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.enquiry_auto_responder',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.enquiry_auto_responder',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.enquiry_auto_responder',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.enquiry_auto_responder',
        ),
      },
      {
        title: intl.t(
          'dashboard_index.feature_cards.titles.guest_identity_verification',
        ),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.guest_identity_verification',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.guest_identity_verification',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.guest_identity_verification',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.guest_identity_verification',
        ),
      },
      {
        title: intl.t(
          'dashboard_index.feature_cards.titles.automated_security_deposits',
        ),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.automated_security_deposits',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.automated_security_deposits',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.automated_security_deposits',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.automated_security_deposits',
        ),
      },
      {
        title: intl.t(
          'dashboard_index.feature_cards.titles.cleaning_scheduler',
        ),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.cleaning_scheduler',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.cleaning_scheduler',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.cleaning_scheduler',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.cleaning_scheduler',
        ),
      },
      {
        title: intl.t(
          'dashboard_index.feature_cards.titles.esign_rental_agreements',
        ),
        description: intl.t(
          'dashboard_index.feature_cards.descriptions.esign_rental_agreements',
        ),
        featureText: intl.t('dashboard_index.feature_cards.click_button_text'),
        featureLink: intl.t(
          'dashboard_index.feature_cards.links.esign_rental_agreements',
        ),
        documentationLink: intl.t(
          'dashboard_index.feature_cards.documentation_links.esign_rental_agreements',
        ),
        documentationText: intl.t(
          'dashboard_index.feature_cards.documentation.esign_rental_agreements',
        ),
      },
    ];

    if (config.IS_TEST) {
      return data;
    }

    return shuffle(data);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::FeaturesList': typeof UiFeatureListComponent;
  }
}
