import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import SeamLockableModel from 'uplisting-frontend/models/seam-lockable';
import SeamConnectionModel from 'uplisting-frontend/models/seam-connection';
import { isNone } from '@ember/utils';

interface IArgs {
  connection: SeamConnectionModel;
  lockable: SeamLockableModel;
  isLoading: boolean;
  showKeypadBattery: boolean;
}

interface ConnectionsSeamLockableStatusRowSignature {
  Element: null;

  Args: IArgs;
}

function getBatteryPercentage(level: number): string {
  const percentage = Math.ceil(level * 100);

  return `${percentage}%`;
}

export default class UiConnectionsSeamLockableStatusRowComponent extends Component<ConnectionsSeamLockableStatusRowSignature> {
  @cached
  get lockable(): SeamLockableModel {
    return this.args.lockable;
  }

  @cached
  get isOnline(): boolean {
    return this.lockable.online;
  }

  @cached
  get isLocked(): boolean {
    return this.lockable.locked;
  }

  @cached
  get isDoorOpen(): boolean {
    return this.lockable.doorOpen;
  }

  @cached
  get keypadBatteryLevelPresent(): boolean {
    return !isNone(this.lockable.keypadBatteryLevel);
  }

  @cached
  get batteryStatusIcon(): string {
    switch (this.lockable.batteryStatus) {
      case 'full':
        return 'lock-battery-full';
      case 'good':
        return 'lock-battery-good';
      case 'low':
        return 'lock-battery-low';
      case 'critical':
        return 'lock-battery-critical';
    }
  }

  @cached
  get keypadBatteryStatusIcon(): string {
    switch (this.lockable.keypadBatteryStatus) {
      case 'full':
        return 'lockpad-battery-full';
      case 'good':
        return 'lockpad-battery-good';
      case 'low':
        return 'lockpad-battery-low';
      case 'critical':
        return 'lockpad-battery-critical';
    }
  }

  @cached
  get batteryStatusPercentage(): string {
    return getBatteryPercentage(this.lockable.batteryLevel);
  }

  @cached
  get keypadBatteryStatusPercentage(): string {
    return getBatteryPercentage(this.lockable.keypadBatteryLevel as number);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connections::Seam::LockableStatusRow': typeof UiConnectionsSeamLockableStatusRowComponent;
  }
}
