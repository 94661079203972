import { OccasionType } from 'uplisting-frontend/models/schemas';
import { TableSectionFields } from 'uplisting-frontend/models/occasion';

export enum OccasionFilterType {
  and = 'and',
  or = 'or',
  in = 'in',
  between = 'between',
  notIn = 'not_in',
  gt = 'gt',
  lt = 'lt',
}

export interface IOccasionFilter {
  field: TableSectionFields;
  type: OccasionFilterType;
  values: string[] | IOccasionFilter[] | boolean[];
}

export interface IOccasionFilterGroup {
  type: OccasionFilterType;

  // eslint-disable-next-line no-use-before-define
  values: OccasionFilterItemType[];
}

export type OccasionFilterItemType = IOccasionFilter | IOccasionFilterGroup;
export type OccasionFilters = OccasionFilterItemType[];

export interface IAdvancedReportConfigurationCriteriaFilter {
  occasion_type: OccasionType;
  occasion_filters: OccasionFilters;
}

export interface IAdvancedReportConfigurationCriteriaFields {
  occasions: string;
}

export interface IAdvancedReportConfigurationCriteria {
  filter: IAdvancedReportConfigurationCriteriaFilter;
  time_zone?: string;
  fields: IAdvancedReportConfigurationCriteriaFields;
  sort?: string;
}

export interface IAdvancedReportConfigurationSchema {
  name: string;
  criteria: IAdvancedReportConfigurationCriteria;
}
