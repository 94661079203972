import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import { isWithinInterval } from 'date-fns/isWithinInterval';

interface IArgs {
  date: Date;
  propertyId: string;
  bookings: BookingModel[];
}

interface CalendarTableCellSignature {
  Element: HTMLTableCellElement;

  Args: IArgs;
}

export default class UiCalendarTableCellComponent extends Component<CalendarTableCellSignature> {
  @cached
  get guestNames(): string | undefined {
    const bookings = this.computeDateBooking();

    if (!bookings) {
      return;
    }

    return bookings.map((booking) => booking.guestName).join(' ');
  }

  private computeDateBooking(): BookingModel[] | undefined {
    return this.args.bookings
      .filter((booking) => booking.property.id === this.args.propertyId)
      .filter((booking) =>
        isWithinInterval(this.args.date, {
          start: new Date(booking.checkIn),
          end: new Date(booking.checkOut),
        }),
      );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Calendar::TableCell': typeof UiCalendarTableCellComponent;
  }
}
