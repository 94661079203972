import Component from '@glimmer/component';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface ActionTableRowEmptySignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiActionTableRowEmptyComponent extends Component<ActionTableRowEmptySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Row::Empty': typeof UiActionTableRowEmptyComponent;
  }
}
