export default {
  "statement-preview-wrapper": "_statement-preview-wrapper_r02xa8",
  "loaded": "_loaded_r02xa8",
  "statement-preview-controls": "_statement-preview-controls_r02xa8",
  "scale-select": "_scale-select_r02xa8",
  "scale-actions": "_scale-actions_r02xa8",
  "disabled": "_disabled_r02xa8",
  "statement-preview-frame-wrapper": "_statement-preview-frame-wrapper_r02xa8",
  "finalized": "_finalized_r02xa8"
};
