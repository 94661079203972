import Component from '@glimmer/component';
import PaymentRuleModel from 'uplisting-frontend/models/payment-rule';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { cached } from '@glimmer/tracking';
import {
  PaymentRuleChannel,
  PaymentRulePreposition,
  PaymentRuleEvent,
} from 'uplisting-frontend/models/schemas';
import { type Registry as Services, inject as service } from '@ember/service';
import PropertyModel from 'uplisting-frontend/models/property';
import { action } from '@ember/object';
import { toggleHasManyValue } from 'uplisting-frontend/utils';

interface IArgs {
  changeset: GenericChangeset<PaymentRuleModel>;
  onSave(): Promise<void>;
  onClose(): void;
  onDelete(): void;
}

interface AutomatePaymentRuleFormSignature {
  Element: HTMLFormElement;

  Args: IArgs;
}

export default class UiAutomatePaymentRuleFormComponent extends Component<AutomatePaymentRuleFormSignature> {
  @service store!: Services['store'];

  @cached
  get changeset(): GenericChangeset<PaymentRuleModel> {
    return this.args.changeset;
  }

  @cached
  get eventOptions(): string[] {
    return Object.values(PaymentRuleEvent);
  }

  @cached
  get prepositionOptions(): string[] {
    return Object.values(PaymentRulePreposition);
  }

  @cached
  get channelOptions(): string[] {
    return Object.values(PaymentRuleChannel);
  }

  @cached
  get isDefaultRule(): boolean {
    return this.changeset.default;
  }

  @cached
  get canDestroy(): boolean {
    return this.changeset.isPersisted && !this.isDefaultRule;
  }

  @cached
  get properties(): PropertyModel[] {
    return this.store.peekAll('property').slice();
  }

  @cached
  get selectedProperties(): PropertyModel[] {
    return this.properties.filter((property) =>
      this.changeset.properties.includes(property),
    );
  }

  @cached
  get isAllSelected(): boolean {
    return this.properties.every((property) =>
      this.changeset.properties.includes(property),
    );
  }

  @action
  handleToggleProperty(property: PropertyModel): void {
    toggleHasManyValue(this.changeset, 'properties', property);
  }

  @action
  handelSelectAllClick(): void {
    this.changeset.properties = this.isAllSelected ? [] : this.properties;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::PaymentRule::Form': typeof UiAutomatePaymentRuleFormComponent;
  }
}
