import DirectBaseController from 'uplisting-frontend/pods/direct/base-controller';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import config from 'ember-get-config';
import { isAnyInvalid } from 'uplisting-frontend/validations/helpers';
import DirectBookingConfigurationModel, {
  CustomSubdomainOptions,
  CUSTOM_SUBDOMAIN_OPTIONS,
} from 'uplisting-frontend/models/direct-booking-configuration';

type FieldToEdit = 'subdomain' | 'customDomain';

export default class DirectLinkController extends DirectBaseController {
  @service analytics!: Services['analytics'];

  directlyBookedHost = config.directlyBooked.host;

  customSubdomainOptions = CUSTOM_SUBDOMAIN_OPTIONS;

  fieldToDelete?: FieldToEdit;

  @cached @tracked showSubdomainValidation = false;
  @cached @tracked showCustomDomainValidation = false;
  @cached @tracked showConfirmDeleteModal = false;
  @cached @tracked showConfirmUpdateModal = false;
  @cached @tracked showCustomDomainCreatedModal = false;

  @cached
  get directlyBookedLink(): string {
    if (this.configuration.customDomain && this.isCustomDomainEnabled) {
      return this.configuration.customDomain;
    }

    if (!this.configuration.hasSubdomain) {
      return '';
    }

    return `https://${this.configuration.subdomain}.${this.directlyBookedHost}`;
  }

  @cached
  get customDomainLink(): string {
    return this.configuration.customDomainLink as string;
  }

  @cached
  get subdomainPlaceholderText(): string {
    return this.configuration.subdomain || 'your-domain';
  }

  @cached
  get isCustomDomainEnabled(): boolean {
    return this.configuration.customDomainVerified;
  }

  @cached
  get subdomainValidationMessages(): string[] {
    return this.getValidationMessagesFor('subdomain');
  }

  @cached
  get customDomainValidationMessages(): string[] {
    return this.getValidationMessagesFor('customDomain');
  }

  @cached
  get customDomainCname(): string {
    return this.configuration.customDomainCname as string;
  }

  @cached
  get dnsTarget(): string {
    return this.configuration.dnsTarget as string;
  }

  @cached
  get isSubdomainInvalid(): boolean {
    return (
      !this.changeset.change['subdomain'] ||
      !!this.subdomainValidationMessages.length
    );
  }

  @cached
  get isCustomDomainInvalid(): boolean {
    const { changeset } = this;

    if (!changeset.customDomain) {
      return true;
    }

    const isSameSubUrl =
      this.configuration.customSubdomainOption ===
      changeset.customSubdomainOption;

    const customDomainHasNoChanges = !this.changeset.change['customDomain'];
    const isCustomDomainInvalid = !!this.customDomainValidationMessages.length;

    return isSameSubUrl && (customDomainHasNoChanges || isCustomDomainInvalid);
  }

  @cached
  get isCustomSubdomainOptionWww(): boolean {
    return this.changeset.customSubdomainOption === CustomSubdomainOptions.www;
  }

  @action
  handleShowSubdomainValidation(): void {
    this.showSubdomainValidation = true;
  }

  @action
  handleShowCustomDomainValidation(): void {
    this.showCustomDomainValidation = true;
  }

  @action
  handleSelectCustomSubdomainOption(option: CustomSubdomainOptions): void {
    const { changeset } = this;

    // when custom sub url option is selected, and user selects new option
    // we want to remove any present sub url, that is present in the custom domain
    if (
      changeset.customDomain &&
      changeset.customSubdomainOption === CustomSubdomainOptions.custom
    ) {
      changeset.customDomain = (changeset.customDomain as string).replace(
        `${changeset.customDomainCname as string}.`,
        '',
      );
    }

    this.changeset.customSubdomainOption = option;
  }

  @action
  handleShowDeleteDomainModal(field: FieldToEdit): void {
    this.fieldToDelete = field;
    this.showConfirmDeleteModal = true;
  }

  @action
  async handleDeleteDomain(): Promise<void> {
    this.changeset[this.fieldToDelete as FieldToEdit] = null;

    await this.changeset.save();

    this.notifications.info('notifications.applied');
  }

  @action
  handleCancelDeleteDomain(): void {
    this.showConfirmDeleteModal = false;
    this.fieldToDelete = undefined;
  }

  @action
  async handleFormSubmit(field: FieldToEdit): Promise<void> {
    const validations = await this.changeset.validate(field);

    if (isAnyInvalid(validations)) {
      return;
    }

    try {
      const translationKey = this.configuration.hasSubdomain
        ? 'direct_link.notifications.success_update'
        : 'direct_link.notifications.success_create';

      await this.changeset.save();

      this.analytics.trackEvent(AnalyticsEvents.directBookingEngineEnabled);

      if (field === 'customDomain') {
        this.showCustomDomainCreatedModal = true;
      } else {
        this.notifications.info(translationKey);
      }
    } catch {
      this.notifications.error('direct_link.notifications.error', {
        subdomain: this.configuration[field],
      });

      this.configuration[field] = '';
    }
  }

  private getValidationMessagesFor(
    key: keyof DirectBookingConfigurationModel,
  ): string[] {
    return (this.changeset.error?.[key]?.validation as string[]) ?? [];
  }
}
