import FilterComponent from 'uplisting-frontend/pods/components/ui/filters/filter';
import { MultiSelectFilter } from 'uplisting-frontend/utils/filters/types';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import DataModel from '@ember-data/model';
import { Select } from 'ember-power-select/components/power-select';

export interface IExtra {
  modelField: keyof DataModel;
  counter?: string;
  handleChange(value: null): void;
}

export default class UiFiltersFilterMultiSelectComponent extends FilterComponent<MultiSelectFilter> {
  @cached
  get modelField(): keyof DataModel {
    return this.filter.modelField;
  }

  @cached
  get title(): string {
    const { filter, modelField } = this;
    const selectedRecords = filter.params.selectedRecords as DataModel[];

    if (selectedRecords) {
      return selectedRecords.map((record) => record[modelField]).join(', ');
    }

    return '';
  }

  @cached
  get extra(): IExtra {
    const { filter } = this;

    const base: IExtra = {
      modelField: this.modelField,
      handleChange: this.handleChange.bind(this),
    };

    const value = filter.params.value as string[];

    if (value.length >= 2) {
      base.counter = `+${value.length - 1}`;
    }

    return base;
  }

  @action
  handleChange(data: string | null): void {
    if (data === null) {
      this.filter.reset();
    } else {
      this.filter.updateValue(data);
    }

    this.args.onFilterUpdate();
  }

  @action
  buildSelection(item) {
    return item;
  }

  @action
  handleOpen(_select: Select, event: MouseEvent): false | undefined {
    // ignore open when click is made on the reset filter icon
    if (
      (event.target as HTMLSpanElement)?.classList.contains(
        'ember-power-select-clear-btn',
      )
    ) {
      return false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/filters/filter/MultiSelect': typeof UiFiltersFilterMultiSelectComponent;
    'Ui::Filters::Filter::MultiSelect': typeof UiFiltersFilterMultiSelectComponent;
  }
}
