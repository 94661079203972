import Helper from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import { SafeString } from 'handlebars';

const helpDocsUrl = 'https://support.uplisting.io/docs';

export function faqLink([topic, text]: [string, string]): SafeString {
  const href = `${helpDocsUrl}/${topic}`;

  return htmlSafe(
    `<a class="faq-link" target="_blank" rel="noopener noreferrer" href="${href}">${text}</a>`,
  );
}

export default Helper.helper(faqLink);
