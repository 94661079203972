import { UiAutomateEditableRowComponent } from 'uplisting-frontend/pods/components/ui/automate/editable-row/component';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import CustomMessageTagModel from 'uplisting-frontend/models/custom-message-tag';
import { IAutomateMessageTagsTableColSizes } from 'uplisting-frontend/pods/automate/message-tags/controller';

export default class UiAutomateCustomMessageTagRowComponent extends UiAutomateEditableRowComponent<
  CustomMessageTagModel,
  IAutomateMessageTagsTableColSizes
> {
  @service('repositories/custom-message-tag')
  repository!: Services['repositories/custom-message-tag'];

  eventOnUpdate = AnalyticsEvents.customMessageTagEdit;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::CustomMessageTag::Row': typeof UiAutomateCustomMessageTagRowComponent;
  }
}
