import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'ember-get-config';
import 'ember-cached-decorator-polyfill';
import '@glint/environment-ember-loose';
import { startSentry } from 'uplisting-frontend/sentry';

startSentry();

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
