import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import GuestIdentityVerificationConfigurationModel from 'uplisting-frontend/models/guest-identity-verification-configuration';
import DS from 'ember-data';
import { cached, tracked } from '@glimmer/tracking';

export default class GuestIdentityVerificationConfigurationRepositoryService extends BaseRepositoryService<GuestIdentityVerificationConfigurationModel> {
  recordName = 'guest-identity-verification-configuration';
  implementMethods = ['findRecord', 'createRecord', 'unloadAll'];

  @cached
  @tracked
  currentConfiguration!: GuestIdentityVerificationConfigurationModel;

  public async getDefaultRecord(): Promise<
    DS.PromiseObject<GuestIdentityVerificationConfigurationModel>
  > {
    let record;

    try {
      record = await this.findRecord('me');
    } catch {
      record = this.createRecord();
    }

    this.currentConfiguration = record;

    return record;
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/guest-identity-verification-configuration': GuestIdentityVerificationConfigurationRepositoryService;
  }
}
