import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import { type Registry as Services, inject as service } from '@ember/service';

export default class DirectRoute extends PermittedRoute {
  @service('repositories/direct-booking-configuration')
  directBookingConfigurationRepository!: Services['repositories/direct-booking-configuration'];

  permission = 'direct';

  model() {
    return this.directBookingConfigurationRepository.getDefaultRecord();
  }
}
