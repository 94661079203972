import BulkChangeoverSettingModel from 'uplisting-frontend/models/bulk-changeover-setting';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import ChangeoverSettingModel from 'uplisting-frontend/models/changeover-setting';
import { action } from '@ember/object';
import PropertyModel from 'uplisting-frontend/models/property';

export default class AutomateCleaningController extends BaseController {
  @cached @tracked properties!: PropertyModel[];
  @cached @tracked changeoverSettings!: ChangeoverSettingModel[];

  @cached @tracked cleaningActivated!: boolean;
  @cached @tracked showActivateCleaningModal = false;
  @cached @tracked isSaving = false;

  bulkChangeoverSetting!: BulkChangeoverSettingModel;

  @cached
  get hasEnabledCleaning(): boolean {
    return this.changeoverSettings.some((item) => item.enabled);
  }

  @cached
  get isCleaningActive(): boolean {
    return this.hasEnabledCleaning || this.cleaningActivated;
  }

  @cached
  get isEnabledForAll(): boolean {
    const { changeoverSettings } = this;

    return (
      !!changeoverSettings.length &&
      changeoverSettings.every((item) => item.enabled)
    );
  }

  @action
  async handleToggleSettingField(
    setting: ChangeoverSettingModel,
    field: 'automatic' | 'enabled',
  ): Promise<void> {
    try {
      setting[field] = !setting[field];

      await setting.save();

      this.notifications.info('notifications.applied');
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleChangeEnableForAll(): Promise<void> {
    this.isSaving = true;

    try {
      this.bulkChangeoverSetting.enabled = !this.isEnabledForAll;

      await this.bulkChangeoverSetting.save();

      this.notifications.info('notifications.applied');
    } catch {
      this.notifications.error();
    }

    this.isSaving = false;
  }

  public setDefaultState(): void {
    this.cleaningActivated = this.hasEnabledCleaning;
  }
}
