import Component from '@glimmer/component';
import { BsFormSignature } from 'ember-bootstrap/components/bs-form';
import DataModel from '@ember-data/model';
import { ref } from 'ember-ref-bucket';
import { insertAtCursor } from 'uplisting-frontend/utils';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import CustomMessageTagModel from 'uplisting-frontend/models/custom-message-tag';
import CustomPropertyAttributeModel from 'uplisting-frontend/models/custom-property-attribute';

interface IArgs<T extends DataModel> {
  property: string;
  form: BsFormSignature<T>;
  tagsTitle: string;
  tagsTooltip: string;
  label?: string;
  placeholder?: string;
  tags?: string[];
  showMessageTags?: false;
  showCustomPropertyAttributes?: false;
}

interface DynamicContentEditorSignature<T extends DataModel> {
  Element: HTMLSelectElement;

  Args: IArgs<T>;
}

const DEFAULT_TAGS = [
  'guest_first_name',
  'guest_surname',
  'host_first_name',
  'host_surname',
  'listing_name',
  'listing_address',
  'listing_location',
  'length_of_stay',
  'check_in_time',
  'check_in_date',
  'check_out_time',
  'check_out_date',
  'guest_booking_link',
  'guest_email',
  'guest_phone',
  'guest_phone_last_4_digits',
  'guest_phone_last_5_digits',
  'guest_phone_last_6_digits',
  'number_of_guests',
  'booking_site_name',
  'wifi_name',
  'wifi_password',
  'arrival_time',
  'departure_time',
  'todays_date',
  'direct_booking_website',
  'multi_unit_name',
  'lock_code',
];

export default class UiDynamicContentEditorComponent<
  T extends DataModel,
> extends Component<DynamicContentEditorSignature<T>> {
  @service('repositories/custom-message-tag')
  customMessageTagRepository!: Services['repositories/custom-message-tag'];

  @service('repositories/custom-property-attribute')
  customPropertyAttributeRepository!: Services['repositories/custom-property-attribute'];

  @ref('inputEditor') inputEditor!: HTMLTextAreaElement;

  @cached
  get tags(): string[] {
    return this.args.tags ?? DEFAULT_TAGS;
  }

  @cached
  get customMessageTags(): CustomMessageTagModel[] {
    return this.customMessageTagRepository.peekAll();
  }

  @cached
  get customPropertyAttributes(): CustomPropertyAttributeModel[] {
    return this.customPropertyAttributeRepository.peekAll();
  }

  @cached
  get showMessageTags(): boolean {
    return this.args.showMessageTags ?? true;
  }

  @cached
  get showCustomPropertyAttributes(): boolean {
    return this.args.showCustomPropertyAttributes ?? true;
  }

  @action
  handleAddReservedTag(tag: string): void {
    this.handleAddTag(`{${tag}}`);
  }

  @action
  handleAddTag(tag: string): void {
    const { property, form } = this.args;

    const value = insertAtCursor(
      this.inputEditor.selectionStart,
      tag,
      form.model[property] as string,
    );

    form.model.set(property, value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::DynamicContentEditor': typeof UiDynamicContentEditorComponent;
  }
}
