import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import ClientPropertyModel from 'uplisting-frontend/models/client-property';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import {
  isSaveDisabled,
  StateMachine,
  toggleValue,
} from 'uplisting-frontend/utils';
import ClientModel from 'uplisting-frontend/models/client';
import { runTask } from 'ember-lifeline';
import orderBy from 'lodash-es/orderBy';
import { modifier } from 'ember-modifier';

interface IArgs {
  open: boolean;
  onSubmit(): void;
  onCancel(): void;
}

interface ModalCreateClientSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiIModalCreateClientComponent extends Component<ModalCreateClientSignature> {
  @service intl!: Services['intl'];
  @service('repositories/client')
  clientRepository!: Services['repositories/client'];

  @service('repositories/client-property')
  clientPropertyRepository!: Services['repositories/client-property'];

  @cached @tracked clientProperties!: ClientPropertyModel[];
  @cached @tracked selectedClientProperties: ClientPropertyModel[] = [];
  @cached @tracked changeset!: GenericChangeset<ClientModel>;

  @cached @tracked isSubmitting = false;

  @cached
  get clientInfoSection(): string {
    return this.intl.t('clients_index.create.states.client_info');
  }

  @cached
  get statementDetailsSection(): string {
    return this.intl.t('clients_index.create.states.statement_details');
  }

  @cached
  get clientPropertiesSection(): string {
    return this.intl.t('clients_index.create.states.client_properties');
  }

  stateMachine = new StateMachine([
    this.clientInfoSection,
    this.statementDetailsSection,
    this.clientPropertiesSection,
  ]);

  @cached
  get hasAnyPropertiesSelected(): boolean {
    return this.selectedClientProperties.length >= 1;
  }

  @cached
  get isInvalid(): boolean {
    return this.changeset.isInvalid || !this.hasAnyPropertiesSelected;
  }

  @cached
  get clientPropertiesOrdered(): ClientPropertyModel[] {
    return orderBy(
      this.clientProperties.slice(),
      ['hasOwner', 'name'],
      ['asc', 'asc'],
    );
  }

  @cached
  get availableProperties(): ClientPropertyModel[] {
    return this.clientProperties.filter(
      (property) => !this.computeIsDisabledProperty(property),
    );
  }

  @cached
  get isAllSelected(): boolean {
    return this.availableProperties.every((property) =>
      this.selectedClientProperties.includes(property),
    );
  }

  @action
  handelSelectAllClick(): void {
    if (this.isAllSelected) {
      this.selectedClientProperties = [];
    } else {
      this.selectedClientProperties = this.availableProperties;
    }
  }

  @action
  async handleNext(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    this.stateMachine.goToNextStep();
  }

  @action
  handlePrev(): void {
    this.stateMachine.goToPrevStep();
  }

  @action
  handleCancel(): void {
    this.resetState();

    this.args.onCancel();
  }

  @action
  handleClientPropertyClick(clientProperty: ClientPropertyModel): void {
    toggleValue(this, 'selectedClientProperties', clientProperty);
  }

  @action
  async handleSubmit(): Promise<void> {
    this.isSubmitting = true;
    this.changeset.clientProperties.push(...this.selectedClientProperties);

    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    await this.clientRepository.save(this.changeset);

    this.resetState();
    this.args.onSubmit();
  }

  @action
  async handleFetchClientProperties(): Promise<void> {
    this.changeset = this.clientRepository.buildChangeset();

    const data = await this.clientPropertyRepository.findAll({ reload: true });

    this.clientProperties = data.slice();
  }

  @action
  computeIsDisabledProperty(property: ClientPropertyModel): boolean {
    return property.hasOwner;
  }

  @action
  computeDisabledText(property: ClientPropertyModel): string {
    const text = this.intl.t(
      'clients_index.create.form.client_properties.already_linked',
    );
    return `(${text} <b>${property.client.name}</b>)`;
  }

  handleInputFocus = modifier((input: HTMLInputElement) => {
    // Adding here small delay to wait for the component to be actually rendered
    runTask(
      this,
      () => {
        input.focus();
      },
      100,
    );
  });

  private resetState(): void {
    this.stateMachine.reset();
    this.isSubmitting = false;

    // unload data if record wasn't created
    if (!this.changeset.id) {
      this.changeset.data.unloadRecord();
    }

    // we need to reset that field, because that component is always rendered, but not always shown
    // so when we close it, the component itself is still present and rendered
    // and for the next time we open it - we want to have and empty list of selected client properties
    this.selectedClientProperties = [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Modal::CreateClient': typeof UiIModalCreateClientComponent;
  }
}
