import {
  validatePresence,
  validateNumber,
  validateLength,
} from 'ember-changeset-validations/validators';

export const ClientStatementExpenseValidation = {
  description: [validatePresence(true), validateLength({ min: 1, max: 100 })],
  quantity: [validatePresence(true), validateNumber({ gt: 0 })],
  unitPrice: [validatePresence(true), validateNumber()],
};
