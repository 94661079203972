import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import {
  deserializeDateQuery,
  getEndOfMonthInUtc,
  getStartOfMonthInUtc,
  dateToQuery,
  formatDateRange,
} from 'uplisting-frontend/utils';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import PropertyModel from 'uplisting-frontend/models/property';
import PropertyTagModel from 'uplisting-frontend/models/property-tag';
import { IApplyCompareDateRangeParams } from 'uplisting-frontend/pods/components/ui/compare-date-range/component';
import { isSameDay } from 'date-fns/isSameDay';
import { FilterOption } from 'uplisting-frontend/pods/components/ui/properties/filter/component';

export function getDefaultStartDate(): string {
  return dateToQuery(getStartOfMonthInUtc(new Date())) as string;
}

export function getDefaultEndDate(): string {
  return dateToQuery(getEndOfMonthInUtc(new Date())) as string;
}

export default abstract class InsightsBaseController<T> extends BaseController {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/property-tag')
  propertyTagRepository!: Services['repositories/property-tag'];

  dateFormat = 'MMM d, yyyy';
  @cached @tracked isLoading = false;

  @cached @tracked record!: T;
  @cached @tracked compareToRecord?: T;

  @cached @tracked propertyIds: string[] = [];
  @cached @tracked compareToPropertyIds: string[] = [];

  @cached @tracked propertyTagIds: string[] = [];
  @cached @tracked compareToPropertyTagIds: string[] = [];

  @cached @tracked start = getDefaultStartDate();
  @cached @tracked end = getDefaultEndDate();

  @cached @tracked compareToStart?: string;
  @cached @tracked compareToEnd?: string;

  @cached
  get properties(): PropertyModel[] {
    return this.propertyRepository.peekAll();
  }

  @cached
  get propertyTags(): PropertyTagModel[] {
    return this.propertyTagRepository
      .peekAll()
      .filter((tag) => tag.properties.length);
  }

  @cached
  get selectedStartDate(): Date {
    return deserializeDateQuery(this.start as string) as Date;
  }

  set selectedStartDate(value: Date) {
    this.start = dateToQuery(value) as string;
  }

  @cached
  get selectedEndDate(): Date {
    return deserializeDateQuery(this.end as string) as Date;
  }

  set selectedEndDate(value: Date) {
    this.end = dateToQuery(value) as string;
  }

  @cached
  get selectedCompareToStartDate(): Date | undefined {
    return deserializeDateQuery(this.compareToStart as string) as Date;
  }

  set selectedCompareToStartDate(value: Date | undefined) {
    this.compareToStart = dateToQuery(value);
  }

  @cached
  get selectedCompareToEndDate(): Date | undefined {
    return deserializeDateQuery(this.compareToEnd as string) as Date;
  }

  set selectedCompareToEndDate(value: Date | undefined) {
    this.compareToEnd = dateToQuery(value);
  }

  @cached
  get isPropertiesFilterComparisonDisabled(): boolean {
    return !!this.compareToEnd && !!this.compareToStart;
  }

  @cached
  get dateTitle(): string {
    const { dateFormat, compareToEnd, compareToStart } = this;

    const base = formatDateRange(
      this.start as string,
      this.end as string,
      dateFormat,
    );

    if (compareToEnd && compareToStart) {
      const compareToDateFormatted = formatDateRange(
        compareToStart,
        compareToEnd,
        dateFormat,
      );

      return `${base} ${this.intl.t('general.vs')} ${compareToDateFormatted}`;
    }

    return base;
  }

  @cached
  get title(): string {
    let base = this.dateTitle;

    if (this.selectedItems.length) {
      base += ` ${this.intl.t('general.for')} ${this.propertiesTitle(
        this.selectedItems,
      )}`;
    }

    if (this.selectedCompareToItems.length) {
      base += ` ${this.intl.t('general.vs')} ${this.propertiesTitle(
        this.selectedCompareToItems,
      )}`;
    }

    return base;
  }

  @cached
  get selectedItems(): FilterOption[] {
    if (this.propertyIds.length) {
      return this.propertyIds.map(
        (id) => this.properties.find((item) => item.id === id) as PropertyModel,
      );
    }

    return this.propertyTagIds.map(
      (id) =>
        this.propertyTags.find((item) => item.id === id) as PropertyTagModel,
    );
  }

  @cached
  get selectedCompareToItems(): FilterOption[] {
    if (this.compareToPropertyIds.length) {
      return this.compareToPropertyIds.map(
        (id) => this.properties.find((item) => item.id === id) as PropertyModel,
      );
    }

    return this.compareToPropertyTagIds.map(
      (id) =>
        this.propertyTags.find((item) => item.id === id) as PropertyTagModel,
    );
  }

  @cached
  get isComparing(): boolean {
    return !!this.compareToRecord;
  }

  @action
  handleDateRangeSelect(params: IApplyCompareDateRangeParams): void {
    if (
      isSameDay(this.selectedStartDate, params.start) &&
      isSameDay(this.selectedEndDate, params.end) &&
      this.isSame(this.selectedCompareToStartDate, params.compareToStart) &&
      this.isSame(this.selectedCompareToEndDate, params.compareToEnd)
    ) {
      return;
    }

    this.isLoading = true;

    this.selectedStartDate = params.start;
    this.selectedEndDate = params.end;

    this.selectedCompareToStartDate = params.compareToStart;
    this.selectedCompareToEndDate = params.compareToEnd;
  }

  private isSame(x: Date | undefined, y: Date | undefined): boolean {
    if (x === undefined && y === undefined) {
      return true;
    }

    return isSameDay(x as Date, y as Date);
  }

  private propertiesTitle(items: FilterOption[]): string {
    const { length } = items;

    const title = (items[0] as FilterOption).searchName;

    if (length === 1) {
      return title;
    }

    return `${title} +${length - 1} ${this.intl.t('general.more')}`;
  }

  public resetState(): void {
    this.start = getDefaultStartDate();
    this.end = getDefaultEndDate();

    this.compareToStart = undefined;
    this.compareToEnd = undefined;

    this.propertyIds = [];
    this.compareToPropertyIds = [];
  }
}
