import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import numeral from 'numeral';
import { isNone } from '@ember/utils';

interface IArgs {
  title: string;
  metricValue: string | number;
  isComparing?: boolean;
  currency?: string;
  compareToMetricValue?: string | number;
}

interface InsightCardSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiInsightCardComponent extends Component<InsightCardSignature> {
  @cached
  get currency(): string | undefined {
    return this.args.currency;
  }

  @cached
  get isComparing(): boolean {
    return this.args.isComparing ?? false;
  }

  @cached
  get metricValue(): string | number {
    const { metricValue } = this.args;

    return isNone(metricValue) ? '0' : metricValue;
  }

  @cached
  get compareToMetricValue(): string | number | undefined {
    const { compareToMetricValue } = this.args;

    return isNone(compareToMetricValue) ? '0' : compareToMetricValue;
  }

  @cached
  get difference(): number {
    const [value, compareToValue] = this.getValues();

    const isValueZero = value === 0;
    const isCompareValueZero = compareToValue === 0;

    if (isValueZero && isCompareValueZero) {
      return 0;
    } else if (isCompareValueZero) {
      return 100;
    } else if (isValueZero) {
      return -100;
    }

    return (value / compareToValue) * 100 - 100;
  }

  @cached
  get differenceFormatted(): string {
    return Math.abs(this.difference).toFixed(2);
  }

  private getValues(): [number, number] {
    const { metricValue: value, compareToMetricValue: compareToMetric } = this;

    if (typeof value === 'number') {
      return [value, compareToMetric as number];
    } else if (value.endsWith('%')) {
      return [
        Number(value.slice(0, -1)),
        Number((compareToMetric as string).slice(0, -1)),
      ];
    } else {
      const { stringToNumber } = numeral._;

      return [stringToNumber(value), stringToNumber(compareToMetric)];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::InsightCard': typeof UiInsightCardComponent;
  }
}
