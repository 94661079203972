import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ClientStatementModel from 'uplisting-frontend/models/client-statement';

export default class ClientStatementRepositoryService extends BaseRepositoryService<ClientStatementModel> {
  recordName = 'client-statement';
  implementMethods = ['findRecord', 'findAll', 'query'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/client-statement': ClientStatementRepositoryService;
  }
}
