import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import AdvancedReportConfigurationModel from 'uplisting-frontend/models/advanced-report-configuration';
import { AdvancedReportConfigurationValidation } from 'uplisting-frontend/validations';

export default class AdvancedReportConfigurationRepositoryService extends BaseRepositoryService<AdvancedReportConfigurationModel> {
  recordName = 'advanced-report-configuration';
  implementMethods = [
    'findAll',
    'peekRecord',
    'peekAll',
    'buildChangeset',
    'createRecord',
  ];

  validation = AdvancedReportConfigurationValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/advanced-report-configuration': AdvancedReportConfigurationRepositoryService;
  }
}
