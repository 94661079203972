import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ClientStatementAccountDetailModel from 'uplisting-frontend/models/client-statement-account-detail';
import { ClientStatementAccountDetailValidation } from 'uplisting-frontend/validations';

export default class ClientStatementAccountDetailRepositoryService extends BaseRepositoryService<ClientStatementAccountDetailModel> {
  recordName = 'client-statement-account-detail';
  implementMethods = ['findAll', 'buildChangeset'];

  validation = ClientStatementAccountDetailValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/client-statement-account-detail': ClientStatementAccountDetailRepositoryService;
  }
}
