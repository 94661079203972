import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  title?: string;
  description?: string;
}

interface HeaderSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    title?: [];
  };
}

export default class UiHeaderComponent extends Component<HeaderSignature> {
  @cached
  get title(): string | undefined {
    return this.args.title;
  }

  @cached
  get description(): string | undefined {
    return this.args.description;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Header': typeof UiHeaderComponent;
  }
}
