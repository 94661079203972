import { validatePresence } from 'ember-changeset-validations/validators';
import {
  validateUniqueness,
  customMessageValidator,
} from 'uplisting-frontend/validators';

export const AdvancedReportConfigurationValidation = {
  name: [
    validatePresence({
      presence: true,
      ignoreBlank: true,
    }),
    customMessageValidator(
      'unique_report_configuration_name',
      validateUniqueness(),
    ),
  ],
};
