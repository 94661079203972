import Route from '@ember/routing/route';
import { type Registry as Services, inject as service } from '@ember/service';
import Transition from '@ember/routing/transition';
import {
  serializeArrayQueryParam,
  deserializeArrayQueryParam,
} from 'uplisting-frontend/utils';

export default class BaseRoute extends Route {
  @service store!: Services['store'];
  @service router!: Services['router'];
  @service session!: Services['session'];

  protected isComingFromSameRoute(transition: Transition): boolean {
    return transition.from?.name === this.fullRouteName;
  }

  serializeQueryParam(value, key, valueType) {
    return (
      serializeArrayQueryParam(value, key, valueType) ||
      // @ts-expect-error - accessing private API
      super.serializeQueryParam(value, key, valueType)
    );
  }

  deserializeQueryParam(value, key, valueType) {
    return (
      deserializeArrayQueryParam(value, key, valueType) ||
      // @ts-expect-error - accessing private API
      super.serializeQueryParam(value, key, valueType)
    );
  }
}
