import Helper from '@ember/component/helper';
import { type Registry as Services, inject as service } from '@ember/service';

export default class IsRouteActiveHelper extends Helper {
  @service router!: Services['router'];

  compute([route]: [string]): boolean {
    const { currentRouteName } = this.router;

    if (!currentRouteName) {
      return false;
    }

    return currentRouteName.startsWith(route);
  }
}
