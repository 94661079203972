export default {
  "image-upload": "_image-upload_7zfoze",
  "form-image-upload": "_form-image-upload_7zfoze",
  "upload-message-text": "_upload-message-text_7zfoze",
  "reset-image": "_reset-image_7zfoze",
  "form-image-upload-wrapper": "_form-image-upload-wrapper_7zfoze",
  "upload-image-title": "_upload-image-title_7zfoze",
  "active": "_active_7zfoze",
  "upload-image-icon": "_upload-image-icon_7zfoze",
  "upload-message": "_upload-message_7zfoze",
  "upload-error-icon": "_upload-error-icon_7zfoze",
  "upload-waring-icon": "_upload-waring-icon_7zfoze"
};
