import BaseRoute from 'uplisting-frontend/pods/base/route';
import UserModel from 'uplisting-frontend/models/user';
import { waitForProperty } from 'ember-concurrency';
import Transition from '@ember/routing/transition';
import { isRouteForbiddenWhenLapsed } from 'uplisting-frontend/router';

export default class PermittedActionRoute extends BaseRoute {
  actionPermission!: string;

  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    const { session, actionPermission } = this;

    if (!actionPermission) {
      throw new Error('You must define `actionPermission` property');
    }

    await waitForProperty(
      session,
      'currentUser',
      (item) => item instanceof UserModel,
    );

    const { currentUser } = session;

    if (!currentUser.hasAccessToAction(actionPermission)) {
      transition.abort();

      window.open('/', '_self');
    }

    if (
      currentUser.lapsed &&
      isRouteForbiddenWhenLapsed(transition.targetName)
    ) {
      await transition.abort();
    }
  }
}
