import { scheduleTask } from 'ember-lifeline';

/**
 * @description we want to conditionally change table size behavior, based on the columns shown and selected
 * so in case we have all fields and columns shown - we want each column to have a concrete width/space taken for it's data
 * in order for that to work we are adding a class `scrollable` which will change table `display` css rule
 * and if less fields selected - we want table to go full screen width
 *
 * @param {HTMLTableElement} table - for which table to test for the scroll size
 */
export function handleTableScrollSize(
  context: any,
  table: HTMLTableElement,
): void {
  scheduleTask(context, 'render', () => {
    if (!table) {
      return;
    }

    const isScrollPresent = table.scrollWidth > table.clientWidth;

    if (!isScrollPresent && table.classList.contains('scrollable')) {
      table.classList.remove('scrollable');
    }

    scheduleTask(context, 'render', () => {
      const { tHead } = table;

      // tHead can be not present, when table is in the `empty` state
      if (!tHead) {
        return;
      }

      const columns = tHead.querySelectorAll('th');

      const isSmallerThanShouldBe = [...columns].every((column) => {
        const expectedMinWidth = Number(
          (column.classList[0] as string).replace('width-', ''),
        );

        return column.clientWidth <= expectedMinWidth;
      });

      if (isSmallerThanShouldBe) {
        table.classList.add('scrollable');
      }
    });
  });
}
