export enum ExternalErrors {
  LOGO_UPLOAD = 'LOGO_UPLOAD_FAILED',
}

interface IErrorWithCause<T> {
  name: T;
  message: string;
  cause: any;
}

class ErrorBase<T extends string> extends Error {
  name!: T;
  message!: string;
  cause!: any;

  constructor(error: IErrorWithCause<T>) {
    super();

    this.name = error.name;
    this.message = error.message;
    this.cause = error.cause;
  }
}

export class ExternalError extends ErrorBase<ExternalErrors> {}
