import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import PropertyModel from 'uplisting-frontend/models/property';
import StripeConnectionModel from 'uplisting-frontend/models/stripe-connection';
import config from 'ember-get-config';
import { scheduleTask } from 'ember-lifeline';
import { action } from '@ember/object';

export default class ConnectStripeController extends BaseController {
  queryParams = ['problem'];

  @cached @tracked problem;

  @cached @tracked properties!: PropertyModel[];
  @cached @tracked stripeConnections!: StripeConnectionModel[];

  @cached
  get connectLink(): string {
    let connectLink = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${config.stripe.connectKey}&scope=${config.stripe.connectScope}`;

    if (config.stripe.redirectUri) {
      connectLink += `&redirect_uri=${config.stripe.redirectUri}`;
    }

    return connectLink;
  }

  @action
  async handleSave(): Promise<void> {
    await this.store.findAll('stripe-connection', { reload: true });
  }

  public handleCallbackRedirect(): void {
    if (this.problem) {
      this.notifications.error();

      scheduleTask(this, 'render', () => {
        this.problem = undefined;
      });
    }
  }
}
