import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';

type CheckboxType = 'checkbox' | 'radio';

interface IArgs {
  isChecked: boolean;
  text?: string;
  type?: CheckboxType;
  isDisabled?: boolean;
  position?: string;
  onLabel?: string;
  offLabel?: string;
  onClick?(): void;
}

interface CheckBoxSignature {
  Element: HTMLLabelElement;

  Args: IArgs;
}

export default class UiCheckboxComponent extends Component<CheckBoxSignature> {
  @cached
  get isDisabled(): boolean {
    return this.args.isDisabled ?? false;
  }

  @cached
  get type(): CheckboxType {
    return this.args.type ?? 'checkbox';
  }

  @cached
  get position(): string {
    return `position-${this.args.position ?? 'left'}`;
  }

  @cached
  get isLabeledCheckbox(): boolean {
    return !!(this.args.onLabel && this.args.offLabel);
  }

  @action
  handleClick(event: MouseEvent): void {
    if (this.args.onClick) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.isDisabled) {
      return;
    }

    this.args.onClick?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Checkbox': typeof UiCheckboxComponent;
  }
}
