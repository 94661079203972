import BookingModel from 'uplisting-frontend/models/booking';
import PropertyModel from 'uplisting-frontend/models/property';
import CalendarModel from 'uplisting-frontend/models/calendar';
import TeammateModel from 'uplisting-frontend/models/teammate';

export enum ChangeoverStatus {
  started = 'started',
  finished = 'finished',
  pending = 'pending',
  accepted = 'accepted',
}

export interface IChangeoverSchema {
  cleanerName: string | null;
  hostNote: string | null;
  status: ChangeoverStatus;
  scheduledAt: Date;

  booking: BookingModel;
  property: PropertyModel;
  calendar: CalendarModel;
  teammate?: TeammateModel;
  nextBooking?: BookingModel;
}
