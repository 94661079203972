import Model, { attr } from '@ember-data/model';
import { IBookingRentalAgreementSettingSchema } from 'uplisting-frontend/models/schemas';

export default class BookingRentalAgreementSettingModel
  extends Model
  implements IBookingRentalAgreementSettingSchema
{
  @attr('string', { defaultValue: '' }) content!: string;
  @attr('boolean') enabledForUplisting!: boolean;
  @attr('boolean') enabledForBookingDotCom!: boolean;
  @attr('boolean') enabledForAirbnb!: boolean;
  @attr('boolean') enabledForHomeAway!: boolean;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-rental-agreement-setting': BookingRentalAgreementSettingModel;
  }
}
