import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import GuestIdentityVerificationConfigurationModel from 'uplisting-frontend/models/guest-identity-verification-configuration';
import PropertyModel from 'uplisting-frontend/models/property';

interface IChannel {
  field: 'airbnb' | 'bookingDotCom' | 'homeAway' | 'uplisting';
  translation: string;
}

export default class AutomateIdentityController extends BaseController {
  @service pricing!: Services['pricing'];
  @service analytics!: Services['analytics'];

  @cached
  @tracked
  configuration!: GuestIdentityVerificationConfigurationModel;

  @cached @tracked properties!: PropertyModel[];

  @cached @tracked guestIdentityActivated!: boolean;
  @cached @tracked showActivateGuestIdentityModal = false;

  channels: IChannel[] = [
    {
      field: 'uplisting',
      translation: 'uplisting',
    },

    {
      field: 'bookingDotCom',
      translation: 'booking_dot_com',
    },

    {
      field: 'airbnb',
      translation: 'airbnb',
    },

    {
      field: 'homeAway',
      translation: 'vrbo',
    },
  ];

  @cached
  get isGuestIdentityActive() {
    return this.configuration.isEnabled || this.guestIdentityActivated;
  }

  @action
  async handleToggleGuestIdentityVerificationConfiguration(
    channel: IChannel,
  ): Promise<void> {
    const { configuration } = this;
    const { field, translation } = channel;
    const prevValue = configuration[field];
    const isEnabled = !prevValue;

    try {
      configuration[field] = isEnabled;

      await configuration.save();

      const event = isEnabled
        ? AnalyticsEvents.guestIdentityVerificationEnabled
        : AnalyticsEvents.guestIdentityVerificationDisabled;

      const { intl } = this;

      this.notifications.info('automate_identity.notifications.saved', {
        status: intl.t(
          `automate_identity.notifications.status.${isEnabled ? 'enabled' : 'disabled'}`,
        ),
        name: intl.t(`automate_identity.notifications.channels.${translation}`),
      });

      this.analytics.trackEvent(event);
    } catch {
      configuration[field] = prevValue;

      this.notifications.error();
    }
  }

  @action
  handleShowActivateGuestIdentityModal(): void {
    this.showActivateGuestIdentityModal = true;
  }

  @action
  handleActivateGuestIdentity(): void {
    this.guestIdentityActivated = true;

    this.analytics.trackEvent(AnalyticsEvents.guestIdentityActivated);
  }

  public setDefaultState(): void {
    this.guestIdentityActivated = this.configuration.isEnabled;
  }
}
