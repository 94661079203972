import Model, { attr } from '@ember-data/model';
import {
  IStaySchema,
  OccasionChannel,
  OccasionStatus,
  OccasionType,
} from 'uplisting-frontend/models/schemas';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { isSameDay } from 'date-fns/isSameDay';

const today = new Date();

export default class StayModel extends Model implements IStaySchema {
  @service intl!: Services['intl'];
  @service translations!: Services['translations'];

  @attr('string') channel!: OccasionChannel;
  @attr('date') checkIn!: Date;
  @attr('date') checkOut!: Date;
  @attr('string') guestName!: string;
  @attr('string') multiUnitName!: string;
  @attr('number') numberOfGuests!: number;
  @attr('string') propertyNickname!: string;
  @attr('string') status!: OccasionStatus;
  @attr('string') type!: OccasionType;
  @attr('string') uid!: string;

  pastDelimiter?: boolean;
  cancelledDelimiter?: boolean;

  @cached
  get channelName(): string {
    return this.translations.getChannelNameFor(this.channel);
  }

  @cached
  get humanType(): string {
    return this.intl.t(`action.occasion_search.human_type.${this.type}`);
  }

  @cached
  get isInPast(): boolean {
    return isBefore(this.checkOut, today);
  }

  @cached
  get isOngoing(): boolean {
    const { checkIn, checkOut } = this;

    return (
      (isSameDay(checkIn, today) || isBefore(checkIn, today)) &&
      (isSameDay(checkOut, today) || isAfter(checkOut, today))
    );
  }

  @cached
  get isInFuture(): boolean {
    return isAfter(this.checkIn, today);
  }

  @cached
  get isCancelled(): boolean {
    return this.status === 'cancelled';
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    stay: StayModel;
  }
}
