import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import SavedReplyModel from 'uplisting-frontend/models/saved-reply';
import { SavedReplyValidation } from 'uplisting-frontend/validations';

export default class SavedReplyRepositoryService extends BaseRepositoryService<SavedReplyModel> {
  recordName = 'saved-reply';
  implementMethods = [
    'findAll',
    'findRecord',
    'buildChangeset',
    'createRecord',
  ];

  validation = SavedReplyValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/saved-reply': SavedReplyRepositoryService;
  }
}
