import BaseController from 'uplisting-frontend/pods/base/controller';
import BookingModel from 'uplisting-frontend/models/booking';
import { cached, tracked } from '@glimmer/tracking';

const PRICE_CHANNELS = [
  'airbnb_official',
  'booking_dot_com',
  'google',
  'uplisting',
  'home_away',
  'partner_ical',
];

export default class ActionBookingsController extends BaseController {
  @cached @tracked booking!: BookingModel;

  @cached
  get canEditPrice(): boolean {
    return (
      this.currentUser.hasAccessToAction('bookings.price') &&
      PRICE_CHANNELS.includes(this.booking.channel) &&
      this.booking.price.visible
    );
  }

  @cached
  get canMessageGuest(): boolean {
    return (
      this.currentUser.hasAccessToAction('bookings.messages') &&
      this.booking.canSendMessage
    );
  }

  @cached
  get canSeeGuestIdentity(): boolean {
    const { guestIdentityVerification } = this.booking;

    if (!guestIdentityVerification) {
      return false;
    }

    return (
      this.currentUser.hasAccessToAction('bookings.identity') &&
      guestIdentityVerification.succeeded
    );
  }

  @cached
  get isViewingReplies(): boolean {
    return this.router.currentRouteName?.includes('bookings.replies');
  }
}
