import OccasionModel from 'uplisting-frontend/models/occasion';

export interface IOccasionSectionMeta {
  total: number;
  filtered_count: number;
}

export interface IOccasionRequestMeta {
  meta: IOccasionSectionMeta;
}

export interface ISectionCount {
  total: number | '-';
  count?: number | '-';
}

export interface IBookingData {
  occasionBookings: OccasionModel[];
}

export interface IActivityData {
  occasionActivity: OccasionModel[];
}

export interface IActionData {
  occasionAction: OccasionModel[];
}

export interface IDashboardBookingData extends IBookingData {
  checkedInCount: ISectionCount;
  checkedOutCount: ISectionCount;
  staysCount: ISectionCount;
}

export interface IDashboardActivityData extends IActivityData {
  newBookingsCount: ISectionCount;
  enquiriesCount: ISectionCount;
  bookingRequestCount: ISectionCount;
  pendingBookingCount: ISectionCount;
  cancelledBookingsCount: ISectionCount;
}

export interface IDashboardActionData extends IActionData {
  bookingPaymentsCount: ISectionCount;
  securityDepositsCount: ISectionCount;
  bookingGuestIdentityVerificationsCount: ISectionCount;
  bookingRentalAgreementsCount: ISectionCount;
}

export interface IDashboardRecords {
  occasionBookings: OccasionModel[];
  occasionActivity: OccasionModel[];
  occasionActions: OccasionModel[];
}

export interface IDashboardData extends IDashboardRecords {
  checkedInCount: ISectionCount;
  checkedOutCount: ISectionCount;
  staysCount: ISectionCount;
  newBookingsCount: ISectionCount;
  enquiriesCount: ISectionCount;
  bookingRequestCount: ISectionCount;
  pendingBookingCount: ISectionCount;
  cancelledBookingsCount: ISectionCount;
  bookingPaymentsCount: ISectionCount;
  securityDepositsCount: ISectionCount;
  bookingGuestIdentityVerificationsCount: ISectionCount;
  bookingRentalAgreementsCount: ISectionCount;
}

// NOTE: giving here proper naming for the enum, because it's being later stored in the page QP's
// and we want them to be more meaningful, rather then store there `1,2,3...` and so on
export enum DashboardDateOptions {
  yesterday = 'yesterday',
  today = 'today',
  tomorrow = 'tomorrow',
  next_7_days = 'next7Days',
}
