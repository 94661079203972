import { cached, tracked } from '@glimmer/tracking';
import { toggleValue } from 'uplisting-frontend/utils';
import IntlService from 'ember-intl/services/intl';
import { TableSectionFields } from 'uplisting-frontend/models/occasion';
import {
  OccasionStatus,
  SecurityDepositStatus,
  GuestIdentityVerificationStatus,
  BookingRentalAgreementStatus,
  PaymentStatus,
  OccasionChannel,
  IOccasionFilter,
  OccasionFilterType,
  OccasionFilters,
  IOccasionFilterGroup,
} from 'uplisting-frontend/models/schemas';
import StoreService from '@ember-data/store';
import ModelRegistry from 'ember-data/types/registries/model';
import DataModel from '@ember-data/model';
import PropertyModel from 'uplisting-frontend/models/property';
import PropertyTagModel from 'uplisting-frontend/models/property-tag';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';

export enum OccasionFilterComponent {
  multiSelect,
  singleSelect,
  storeMultiSelect,
  numerical,
  date,
}

abstract class BaseFilter<TOptionsType> {
  abstract optionsList: TOptionsType[];
  abstract field: TableSectionFields;
  abstract component: OccasionFilterComponent;

  intl!: IntlService;
  store!: StoreService;

  @cached @tracked type = OccasionFilterType.in;
  @cached @tracked values: string[] = [];

  constructor(values: string[] = [], type?: OccasionFilterType) {
    this.values = values;

    if (type) {
      this.type = type;
    }
  }

  @cached
  get value(): string {
    return this.values[0] as string;
  }

  @cached
  get isValid(): boolean {
    return !!this.value;
  }

  @cached
  get prettifiedValue(): string {
    return this.intl.t(`${this.field}.${this.value}`);
  }

  public updateValues(item: string | TOptionsType): void {
    this.values = [item as string];
  }

  public updateType(type: OccasionFilterType): void {
    this.type = type;
  }

  public toQuery(): IOccasionFilter {
    return {
      field: this.field,
      type: this.type || OccasionFilterType.in,
      values: this.values,
    };
  }

  public clone<TOccasionFilter extends BaseFilter<unknown>>(
    this: TOccasionFilter,
  ): TOccasionFilter {
    return new (this.constructor as any)(
      this.values,
      this.type,
    ) as TOccasionFilter;
  }

  public reset(): void {
    this.values = [];
  }
}

export abstract class SingleSelectFilter<T> extends BaseFilter<T> {
  component = OccasionFilterComponent.singleSelect;
}

export abstract class MultiSelectFilter extends BaseFilter<string> {
  component = OccasionFilterComponent.multiSelect;

  public updateValues(item: string): void {
    toggleValue(this, 'values', item);
  }

  @cached
  get prettifiedValue(): string {
    const { values, intl } = this;

    return values
      .map((value) => intl.t(`${this.field.replace('booking_', '')}.${value}`))
      .join(', ');
  }
}

export abstract class NumericalFilter extends BaseFilter<number> {
  types = [
    OccasionFilterType.in,
    OccasionFilterType.notIn,
    OccasionFilterType.gt,
    OccasionFilterType.lt,
  ];

  component = OccasionFilterComponent.numerical;

  @cached
  get prettifiedValue(): string {
    return `${this.intl.t(`${this.field}.${this.type}`)} ${this.value}`;
  }
}

export abstract class DateFilter extends BaseFilter<string> {
  @cached @tracked type = OccasionFilterType.between;

  component = OccasionFilterComponent.date;

  optionsList = [
    'today',
    'tomorrow',
    'yesterday',
    'this_week',
    'next_week',
    'last_week',
    'this_month',
    'next_month',
    'last_month',
    'this_year',
    'last_year',
    'next_7_days',
    'past_7_days',
    'next_14_days',
    'past_14_days',
    'next_30_days',
    'past_30_days',
  ];

  constructor(values: string[] = [], type?: OccasionFilterType) {
    super(values, type);

    if (!values.length) {
      this.values = [this.optionsList[0] as string];
    }
  }

  @cached
  get isValid(): boolean {
    const { value, optionsList } = this;

    return !!(optionsList.includes(value) || value?.includes(':'));
  }

  @cached
  get prettifiedValue(): string {
    const { value } = this;

    if (this.optionsList.includes(value)) {
      return this.intl.t(`date_options.${value}`);
    }

    return value.split(':').join(' - ');
  }
}

export abstract class StoreFilter<T extends DataModel> extends BaseFilter<T> {
  abstract modelName: keyof ModelRegistry;
  abstract modelField: keyof T;

  component = OccasionFilterComponent.storeMultiSelect;

  @cached
  get optionsList(): T[] {
    return this.store.peekAll(this.modelName).slice();
  }

  @cached
  get prettifiedValue(): string {
    const { values, optionsList, modelField } = this;

    const items = optionsList
      .filter((item) => values.includes(item.id))
      .map((item) => item[modelField]);

    const { length } = items;

    if (length > 5) {
      return `${items.slice(0, 5).join(', ')} +${length - 5} ${this.intl.t(
        'general.more',
      )}`;
    }

    return items.join(', ');
  }

  public updateValues(item: T): void {
    toggleValue(this, 'values', item.id);
  }
}

export class BookingStatusFilter extends MultiSelectFilter {
  field = TableSectionFields.bookingStatus;
  optionsList = [
    OccasionStatus.pending,
    OccasionStatus.confirmed,
    OccasionStatus.needsCheckIn,
    OccasionStatus.needsCheckOut,
    OccasionStatus.checkedIn,
    OccasionStatus.checkedOut,
    OccasionStatus.cancelled,
  ];
}

export class BookingSecurityDepositStatusFilter extends MultiSelectFilter {
  field = TableSectionFields.depositStatus;
  optionsList = [
    SecurityDepositStatus.authorized,
    SecurityDepositStatus.collected,
    SecurityDepositStatus.overridden,
    SecurityDepositStatus.needsPaymentInformation,
    SecurityDepositStatus.hasPaymentInformation,
    SecurityDepositStatus.failing,
    SecurityDepositStatus.failed,
  ];
}

export class BookingRentalAgreementStatusFilter extends MultiSelectFilter {
  field = TableSectionFields.bookingRentalAgreementStatus;
  optionsList = [
    BookingRentalAgreementStatus.pending,
    BookingRentalAgreementStatus.processing,
    BookingRentalAgreementStatus.processed,
    BookingRentalAgreementStatus.markedAsSigned,
  ];
}

export class BookingGuestIdentityVerificationStatusFilter extends MultiSelectFilter {
  field = TableSectionFields.bookingGuestIdentityVerificationStatus;
  optionsList = [
    GuestIdentityVerificationStatus.overridden,
    GuestIdentityVerificationStatus.processing,
    GuestIdentityVerificationStatus.requiresAction,
    GuestIdentityVerificationStatus.requiresInput,
    GuestIdentityVerificationStatus.succeeded,
    GuestIdentityVerificationStatus.verified,
  ];
}

export class BookingPaymentStatusFilter extends SingleSelectFilter<string> {
  field = TableSectionFields.paid;
  optionsList = [PaymentStatus.paid, PaymentStatus.notPaid];

  constructor(values: string[] = [], type?: OccasionFilterType) {
    super(values, type);

    if (!values.length) {
      this.values = [PaymentStatus.paid];
    } else {
      this.values =
        values[0] === 'true' ? [PaymentStatus.paid] : [PaymentStatus.notPaid];
    }
  }

  public clone<TOccasionFilter extends BaseFilter<unknown>>(
    this: TOccasionFilter,
  ): TOccasionFilter {
    return new BookingPaymentStatusFilter(
      this.values[0] === PaymentStatus.paid ? ['true'] : ['false'],
      this.type,
    ) as TOccasionFilter;
  }

  public toQuery(): IOccasionFilter {
    const query = super.toQuery();

    query.values = [`${query.values[0] === PaymentStatus.paid}`];

    return query;
  }
}

export class BookingChannelFilter extends MultiSelectFilter {
  field = TableSectionFields.channel;
  optionsList = Object.values(OccasionChannel);
}

export class BookingPropertyIdFilter extends StoreFilter<PropertyModel> {
  field = TableSectionFields.propertyId;
  modelName = 'property';
  modelField = 'searchName' as keyof DataModel;
}

export class BookingPropertyTagIdFilter extends StoreFilter<PropertyTagModel> {
  field = TableSectionFields.propertyTagId;
  modelName = 'property-tag';
  modelField = 'searchName' as keyof DataModel;
}

export class BookingMultiUnitFilter extends StoreFilter<MultiUnitModel> {
  field = TableSectionFields.multiUnitId;
  modelName = 'multi-unit';
  modelField = 'name' as keyof DataModel;
}

export class BookingDurationFilter extends NumericalFilter {
  field = TableSectionFields.duration;
  optionsList = [];
}

export class BookingCreationDateFilter extends DateFilter {
  field = TableSectionFields.newBookings;
}

export class BookingCancellationDateFilter extends DateFilter {
  field = TableSectionFields.cancelledBookings;
}

export class BookingCheckInDateFilter extends DateFilter {
  field = TableSectionFields.checkIn;
}

export class BookingCheckOutDateFilter extends DateFilter {
  field = TableSectionFields.checkOut;
}

export class BookingStaysFilter extends DateFilter {
  field = TableSectionFields.stays;
}

export type OccasionStoreFilter =
  | BookingPropertyIdFilter
  | BookingPropertyTagIdFilter
  | BookingMultiUnitFilter;

export type OccasionDateFilter =
  | BookingCreationDateFilter
  | BookingCancellationDateFilter
  | BookingCheckInDateFilter
  | BookingCheckOutDateFilter
  | BookingStaysFilter;

export type OccasionNumericalFilter = BookingDurationFilter;
export type OccasionSingleSelectFilter = BookingPaymentStatusFilter;

export type OccasionPredefinedOptionsFilter =
  | BookingStatusFilter
  | BookingSecurityDepositStatusFilter
  | BookingRentalAgreementStatusFilter
  | BookingGuestIdentityVerificationStatusFilter
  | BookingPaymentStatusFilter
  | BookingChannelFilter;

export type OccasionFilter =
  | BookingStatusFilter
  | BookingSecurityDepositStatusFilter
  | BookingRentalAgreementStatusFilter
  | BookingGuestIdentityVerificationStatusFilter
  | BookingPaymentStatusFilter
  | BookingChannelFilter
  | BookingPropertyIdFilter
  | BookingPropertyTagIdFilter
  | BookingMultiUnitFilter
  | BookingDurationFilter
  | BookingCreationDateFilter
  | BookingCancellationDateFilter
  | BookingCheckInDateFilter
  | BookingCheckOutDateFilter
  | BookingStaysFilter;

// eslint-disable-next-line no-use-before-define
export type OccasionFilterGroupItems = (OccasionFilter | OccasionFilterGroup)[];

interface IOccasionFilterGroupParams {
  type: OccasionFilterType;
  values: OccasionFilterGroupItems;
}

export class OccasionFilterGroup implements IOccasionFilterGroupParams {
  @cached @tracked type!: OccasionFilterType;
  @cached @tracked values: OccasionFilterGroupItems = [];

  isGroup = true;

  constructor(params: IOccasionFilterGroupParams) {
    Object.assign(this, params);
  }

  @cached
  get isEmpty(): boolean {
    return this.values.length === 0;
  }

  public toQuery(): OccasionFilters {
    return [
      {
        type: this.type,
        values: this.values
          .filter((item) => item.values.length)
          .map((item) => item.toQuery())
          .map((item) => {
            if ((item as OccasionFilters).length) {
              return item[0];
            }

            return item;
          }) as OccasionFilters,
      },
    ];
  }

  public toUnwrappedQuery(): OccasionFilters {
    if (this.isEmpty) {
      return [];
    }

    const data = this.toQuery();

    return (data[0] as IOccasionFilterGroup).values;
  }
}
