import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ChannelNotificationModel from 'uplisting-frontend/models/channel-notification';

export default class ChannelNotificationRepositoryService extends BaseRepositoryService<ChannelNotificationModel> {
  recordName = 'channel-notification';
  implementMethods = ['findRecord', 'unloadAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/channel-notification': ChannelNotificationRepositoryService;
  }
}
