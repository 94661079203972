export default {
  "table-title": "_table-title_ay91li",
  "expenses-table": "_expenses-table_ay91li",
  "expense-actions": "_expense-actions_ay91li",
  "no-expenses-present": "_no-expenses-present_ay91li",
  "add-new-expense": "_add-new-expense_ay91li",
  "invoice-table": "_invoice-table_ay91li",
  "sales-table": "_sales-table_ay91li",
  "sales-total-row": "_sales-total-row_ay91li",
  "no-income-items-present": "_no-income-items-present_ay91li",
  "add-expense-form": "_add-expense-form_ay91li",
  "form-item": "_form-item_ay91li",
  "cancel-button": "_cancel-button_ay91li"
};
