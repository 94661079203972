import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import PropertyModel from 'uplisting-frontend/models/property';
import EdgestateConnectionModel from 'uplisting-frontend/models/edgestate-connection';
import config from 'ember-get-config';
import { scheduleTask } from 'ember-lifeline';

export default class ConnectEdgestateController extends BaseController {
  queryParams = ['problem'];
  @cached @tracked problem;

  @cached @tracked properties!: PropertyModel[];
  @cached @tracked edgestateConnections!: EdgestateConnectionModel[];

  @cached
  get connectLink(): string {
    return `https://connect.remotelock.com/oauth/authorize?response_type=code&client_id=${config.edgestate.clientId}&scope=read_write&redirect_uri=${config.edgestate.redirectUri}`;
  }

  public handleCallbackRedirect(): void {
    if (this.problem) {
      this.notifications.error();

      scheduleTask(this, 'render', () => {
        this.problem = undefined;
      });
    }
  }
}
