import {
  validateNumber,
  validatePresence,
} from 'ember-changeset-validations/validators';

export const PropertyManagementFeeSettingValidation = {
  accommodationPercentage: [
    validatePresence({
      presence: true,
      ignoreBlank: true,
    }),
    validateNumber({ gte: 0, lte: 100 }),
  ],

  cleaningFeePercentage: [
    validatePresence({
      presence: true,
      ignoreBlank: true,
    }),
    validateNumber({ gte: 0, lte: 100 }),
  ],
};
