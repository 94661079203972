import BaseController from 'uplisting-frontend/pods/base/controller';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import PropertyModel from 'uplisting-frontend/models/property';
import ChangeoverModel from 'uplisting-frontend/models/changeover';
import PropertyTagModel from 'uplisting-frontend/models/property-tag';
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval';
import { isSameDay } from 'date-fns/isSameDay';
import { action } from '@ember/object';
import { readWithDefault, CookieKey } from 'uplisting-frontend/utils/cookies';
import { OccasionStatus } from 'uplisting-frontend/models/schemas';
import {
  addDays,
  subDays,
  formatDateQuery,
  getStartOfMonthInUtc,
  getEndOfMonthInUtc,
  toggleValue,
  searchOptions,
  formatDateRange,
  dateToQuery,
  startOfDay,
  endOfDay,
} from 'uplisting-frontend/utils';
import { underscore } from '@ember/string';
import uniqBy from 'lodash-es/uniqBy';
import { subMonths } from 'date-fns/subMonths';
import { addMonths } from 'date-fns/addMonths';
import { isBefore } from 'date-fns/isBefore';
import { debounceTask } from 'ember-lifeline';
import StayModel from 'uplisting-frontend/models/stay';
import { toZonedTime } from 'date-fns-tz';
import { IDropdown } from 'ember-bootstrap/components/bs-dropdown';

enum ActionPeriod {
  day = 'day',
  week = 'week',
  month = 'month',
}

enum FilterOption {
  checkIn = 'checkIn',
  checkOut = 'checkOut',
  changeover = 'changeover',
  empty = 'empty',
}

export type ActionDataItem = BookingModel | ChangeoverModel;
export type ActionDataFormat = Record<string, ActionDataItem[]>;

interface IPeriodOption {
  id: ActionPeriod;
  text: string;
}

interface IFilterOption {
  id: FilterOption;
  text: string;
}

const periodDaysDiff = {
  [ActionPeriod.day]: 1,
  [ActionPeriod.week]: 7,
  [ActionPeriod.month]: 30,
};

const SUPPORTED_REPORT_ACTION_TYPES = [
  FilterOption.checkIn,
  FilterOption.checkOut,
];

export default class ActionController extends BaseController {
  @service cookies!: Services['cookies'];
  @service translations!: Services['translations'];

  @service('repositories/stay')
  stayRepository!: Services['repositories/stay'];

  @service('repositories/calendar')
  calendarRepository!: Services['repositories/calendar'];

  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/property-tag')
  propertyTagRepository!: Services['repositories/property-tag'];

  queryParams = ['from'];

  @cached @tracked from!: string;
  @cached @tracked selectedPeriod!: IPeriodOption;
  @cached @tracked selectedFilterIds!: FilterOption[];

  @cached @tracked selectedItems: (PropertyModel | PropertyTagModel)[] = [];

  @cached @tracked isFetchingData = false;

  @cached @tracked inputValue = '';

  @cached
  get properties(): PropertyModel[] {
    return this.propertyRepository.peekAll();
  }

  @cached
  get filteredProperties(): PropertyModel[] {
    return searchOptions<PropertyModel>(
      this.properties,
      this.inputValue,
      'searchName',
    );
  }

  @cached
  get propertyTags(): PropertyTagModel[] {
    return this.propertyTagRepository
      .peekAll()
      .filter((tag) => tag.properties.length);
  }

  @cached
  get filteredPropertyTags(): PropertyTagModel[] {
    return searchOptions<PropertyTagModel>(
      this.propertyTags,
      this.inputValue,
      'searchName',
    );
  }

  @cached
  get noDataMatches(): boolean {
    return !this.filteredProperties.length && !this.filteredPropertyTags.length;
  }

  @cached
  get activePeriodDaysDiff(): number {
    return periodDaysDiff[this.selectedPeriod.id];
  }

  @cached
  get fromAsDate(): Date {
    const from = new Date(this.from);

    if (this.selectedPeriod.id === ActionPeriod.month) {
      return getStartOfMonthInUtc(from);
    }

    return new Date(from);
  }

  @cached
  get toAsDate(): Date {
    if (this.selectedPeriod.id === ActionPeriod.month) {
      return getEndOfMonthInUtc(this.fromAsDate);
    }

    return addDays(this.fromAsDate, this.activePeriodDaysDiff - 1);
  }

  @cached
  get bookings(): BookingModel[] {
    return this.store.peekAll('booking').slice();
  }

  @cached
  get filteredBookings(): BookingModel[] {
    const { bookings, selectedItems } = this;

    if (!selectedItems.length) {
      return bookings;
    }

    const ids = selectedItems.flatMap((item) => {
      if (item instanceof PropertyTagModel) {
        return item.propertyIds;
      }

      return item.id;
    });

    return bookings.filter((booking) => ids.includes(booking.property.id));
  }

  @cached
  get changeovers(): ChangeoverModel[] {
    return this.store.peekAll('changeover').slice();
  }

  @cached
  get filteredChangeovers(): ChangeoverModel[] {
    const { changeovers, selectedItems } = this;

    if (!selectedItems.length) {
      return changeovers;
    }

    const ids = selectedItems.flatMap((item) => {
      if (item instanceof PropertyTagModel) {
        return item.propertyIds;
      }

      return item.id;
    });

    return changeovers.filter((booking) => ids.includes(booking.property.id));
  }

  @cached
  get periodOptions(): IPeriodOption[] {
    const { intl } = this;

    return [
      {
        id: ActionPeriod.day,
        text: intl.t('action.period_options.day'),
      },
      {
        id: ActionPeriod.week,
        text: intl.t('action.period_options.week'),
      },
      {
        id: ActionPeriod.month,
        text: intl.t('action.period_options.month'),
      },
    ];
  }

  @cached
  get filterOptions(): IFilterOption[] {
    const { intl } = this;

    return [
      {
        id: FilterOption.checkIn,
        text: intl.t('action.filter_options.check_in'),
      },
      {
        id: FilterOption.checkOut,
        text: intl.t('action.filter_options.check_out'),
      },
      {
        id: FilterOption.changeover,
        text: intl.t('action.filter_options.changeover'),
      },
      {
        id: FilterOption.empty,
        text: intl.t('action.filter_options.empty'),
      },
    ];
  }

  @cached
  get hiddenFiltersCount(): number {
    return this.filterOptions.length - this.selectedFilterIds.length;
  }

  @cached
  get prevDate(): Date {
    if (this.selectedPeriod.id === ActionPeriod.month) {
      return getStartOfMonthInUtc(subMonths(this.fromAsDate, 1));
    }

    return subDays(this.fromAsDate, this.activePeriodDaysDiff);
  }

  @cached
  get nextDate(): Date {
    if (this.selectedPeriod.id === ActionPeriod.month) {
      return getStartOfMonthInUtc(addMonths(this.fromAsDate, 1));
    }

    return addDays(this.fromAsDate, this.activePeriodDaysDiff);
  }

  @cached
  get selectedPeriodPrettified(): string {
    return formatDateRange(
      dateToQuery(this.fromAsDate) as string,
      dateToQuery(this.toAsDate) as string,
      'MMM d, yyyy',
    );
  }

  @cached
  get data(): ActionDataFormat {
    const interval = eachDayOfInterval({
      start: toZonedTime(this.fromAsDate, 'Etc/UTC'),
      end: toZonedTime(this.toAsDate, 'Etc/UTC'),
    });

    const { filteredBookings, filteredChangeovers } = this;
    const data: ActionDataFormat = {};

    interval.forEach((date) => {
      const dateAsKey = date.toJSON();

      const items: ActionDataItem[] = [];

      if (this.showDataForFilterOption(FilterOption.changeover)) {
        items.push(
          ...filteredChangeovers.filter((item) =>
            this.isOfType(item, date, 'scheduledAtInUTC'),
          ),
        );
      }

      if (this.showDataForFilterOption(FilterOption.checkOut)) {
        items.push(
          ...filteredBookings.filter((item) =>
            this.isOfType(item, date, 'checkOut'),
          ),
        );
      }

      if (this.showDataForFilterOption(FilterOption.checkIn)) {
        items.push(
          ...filteredBookings.filter((item) =>
            this.isOfType(item, date, 'checkIn'),
          ),
        );
      }

      data[dateAsKey] = items.sort((x, y) => {
        const xValue = this.getSortingValue(x, date);
        const yValue = this.getSortingValue(y, date);

        if (xValue === yValue) {
          return x.displayName.localeCompare(y.displayName);
        }

        return xValue.localeCompare(yValue);
      });
    });

    if (!this.showDataForFilterOption(FilterOption.empty)) {
      Object.keys(data).forEach((key) => {
        if (!data[key]?.length) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete data[key];
        }
      });
    }

    return data;
  }

  @cached
  get reportActionTypesForCSV(): string[] {
    return this.selectedFilterIds
      .filter((id) => SUPPORTED_REPORT_ACTION_TYPES.includes(id))
      .map((id) => underscore(id));
  }

  @cached
  get reportPropertiesForCSV(): PropertyModel[] {
    const { selectedItems } = this;

    if (!selectedItems.length) {
      return uniqBy(
        [
          ...this.properties,
          ...this.propertyTags.flatMap((tag) => tag.properties),
        ],
        'id',
      );
    }

    return selectedItems.flatMap((item) => {
      if (item instanceof PropertyTagModel) {
        return item.properties;
      }

      return item;
    });
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  async handleItemClick(item: PropertyModel | PropertyTagModel): Promise<void> {
    toggleValue(this, 'selectedItems', item);

    this.writeSelectedItems();

    await this.handleLoadData();
  }

  @action
  handleClosePropertiesSelectorFilter(): void {
    this.inputValue = '';
  }

  @action
  async handleLoadData(isInitialRequest = false): Promise<void> {
    if (isInitialRequest) {
      this.setDefaultConfig();
    }

    let ids: string | undefined;

    if (this.selectedItems) {
      ids = this.selectedItems
        .flatMap((item) => {
          if (item instanceof PropertyTagModel) {
            return item.propertyIds;
          }

          return item.id;
        })
        .join(',');
    }

    try {
      this.isFetchingData = true;

      await this.calendarRepository.loadData(
        this.fromAsDate,
        this.toAsDate,
        ids,
      );
    } finally {
      this.isFetchingData = false;
    }
  }

  @action
  async handleSelectFrom(date: Date): Promise<void> {
    this.from = formatDateQuery(date);

    await this.handleLoadData();
  }

  @action
  async handleSelectPeriod(period: IPeriodOption): Promise<void> {
    this.selectedPeriod = period;

    this.cookies.write(CookieKey.ACTION_PERIOD, period.id);

    const fromDate =
      this.selectedPeriod.id === ActionPeriod.month
        ? getStartOfMonthInUtc(this.fromAsDate)
        : this.fromAsDate;

    this.handleSelectFrom(fromDate);
  }

  @action
  handleSelectFilter(value: IFilterOption): void {
    toggleValue(this, 'selectedFilterIds', value.id);

    this.cookies.write(
      CookieKey.ACTION_FILTERS,
      this.selectedFilterIds.join(','),
    );
  }

  @action
  async handleGoToToday(): Promise<void> {
    this.handleSelectFrom(new Date());
  }

  @action
  async handleGoToPrev(): Promise<void> {
    this.handleSelectFrom(this.prevDate);
  }

  @action
  async handleGoToNext(): Promise<void> {
    this.handleSelectFrom(this.nextDate);
  }

  @action
  handleShowAll(): void {
    this.selectedFilterIds = this.filterOptions.map((item) => item.id);

    this.cookies.write(
      CookieKey.ACTION_FILTERS,
      this.selectedFilterIds.join(','),
    );
  }

  @action
  async handleExportToCsv(): Promise<void> {
    const report = this.store.createRecord('report', {
      to: endOfDay(this.toAsDate),
      from: startOfDay(this.fromAsDate),
      types: this.reportActionTypesForCSV,
      properties: this.reportPropertiesForCSV,
    });

    try {
      await report.save();

      this.notifications.info('action.notifications.export_to_csv_success');
    } catch {
      this.notifications.error();
    }
  }

  @action
  handlePrint(): void {
    window.print();
  }

  @action
  findStays(query: string) {
    return new Promise((resolve) => {
      debounceTask(this, 'fetchStays', query, resolve, 600);
    });
  }

  @action
  onInput(text: string): boolean {
    return text.length > 2;
  }

  @action
  async handleUnselectAllProperties(dd: IDropdown): Promise<void> {
    this.selectedItems = [];
    this.writeSelectedItems();

    dd.closeDropdown();

    await this.handleLoadData();
  }

  private async fetchStays(query: string, resolve) {
    const stays = await this.stayRepository.query({ query });

    const sorted = (stays.slice() as StayModel[]).sort((x, b) => {
      if (isBefore(x.checkOut, b.checkOut)) {
        return -1;
      } else if (isBefore(b.checkOut, x.checkOut)) {
        return 1;
      }

      return 0;
    });

    const ongoing = sorted.filter(
      (item) => item.isOngoing && !item.isCancelled,
    );
    const past = sorted
      .filter((item) => item.isInPast && !item.isCancelled)
      .reverse();
    const future = sorted.filter(
      (item) => item.isInFuture && !item.isCancelled,
    );
    const cancelled = sorted.filter((item) => item.isCancelled);

    if (past.length) {
      (past[0] as StayModel).pastDelimiter = true;
    }

    if (cancelled.length) {
      (cancelled[0] as StayModel).cancelledDelimiter = true;
    }

    return resolve([...ongoing, ...future, ...past, ...cancelled]);
  }

  private setDefaultConfig(): void {
    this.setActionConfig();
    this.setFilterConfig();
  }

  private setActionConfig(): void {
    const currentPeriod = readWithDefault(
      this.cookies,
      CookieKey.ACTION_PERIOD,
      ActionPeriod.week,
    );

    this.selectedPeriod = this.periodOptions.find(
      (item) => item.id === currentPeriod,
    ) as IPeriodOption;
  }

  private setFilterConfig(): void {
    this.selectedFilterIds = readWithDefault(
      this.cookies,
      CookieKey.ACTION_FILTERS,
      this.filterOptions.map((option) => option.id).join(','),
    ).split(',') as FilterOption[];
  }

  public setPropertiesConfig(): void {
    const data = this.cookies.read<string>(CookieKey.ACTION_PROPERTIES);

    if (!data) {
      return;
    }

    const object = JSON.parse(data);

    const { properties, propertyTags } = this;

    object.propertyIds.forEach((id) => {
      const item = properties.find((property) => property.id === id);

      if (!item) {
        return;
      }

      this.selectedItems.push(item);
    });

    object.propertyTagIds.forEach((id) => {
      const item = propertyTags.find((tag) => tag.id === id);

      if (!item) {
        return;
      }

      this.selectedItems.push(item);
    });
  }

  private getSortingField(item: ActionDataItem, date: Date): string {
    if (item instanceof ChangeoverModel) {
      return 'scheduledAtInUTC';
    } else if (this.isOfType(item, date, 'checkIn')) {
      return 'arrivalTime';
    }

    return 'departureTime';
  }

  private getSortingValue(item: ActionDataItem, date: Date): string {
    const field = this.getSortingField(item, date);
    const value = item[field];

    if (value instanceof Date) {
      let hours: string | number = value.getHours();
      let minutes: string | number = value.getMinutes();

      if (hours < 10) {
        hours = `0${hours}`;
      }

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      return `${hours}:${minutes}:00`;
    }

    return value;
  }

  private showDataForFilterOption(option: FilterOption): boolean {
    const { selectedFilterIds } = this;

    return selectedFilterIds.includes(option) || selectedFilterIds.length === 0;
  }

  private isOfType(item: ActionDataItem, date: Date, field: string): boolean {
    return this.isNotCancelled(item) && isSameDay(date, new Date(item[field]));
  }

  private isNotCancelled(item: ActionDataItem): boolean {
    return item.status !== OccasionStatus.cancelled;
  }

  private writeSelectedItems(): void {
    const { selectedItems } = this;

    const data = {
      propertyIds: selectedItems
        .filter((item) => item instanceof PropertyModel)
        .map((item) => item.id),

      propertyTagIds: selectedItems
        .filter((item) => item instanceof PropertyTagModel)
        .map((item) => item.id),
    };

    this.cookies.write(CookieKey.ACTION_PROPERTIES, JSON.stringify(data));
  }
}
