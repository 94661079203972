import BaseController from 'uplisting-frontend/pods/base/controller';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import CustomMessageTagModel from 'uplisting-frontend/models/custom-message-tag';
import { type Registry as Services, inject as service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';

export interface IAutomateMessageTagsTableColSizes {
  tagCode: number;
  description: number;
  edit: number;
}

export default class AutomateMessageTagsController extends BaseController {
  @service('repositories/custom-message-tag')
  customMessageTagRepository!: Services['repositories/custom-message-tag'];

  colSizes: IAutomateMessageTagsTableColSizes = {
    tagCode: 3,
    description: 8,
    edit: 2,
  };

  @cached
  get messageTagsSorted(): CustomMessageTagModel[] {
    return sortBy(this.customMessageTagRepository.peekAll(), 'order');
  }

  @cached
  get isCreateNewDisabled(): boolean {
    return this.messageTagsSorted.some((model) => !model.isPersisted);
  }

  @action
  handleCreateMessageTag(): void {
    this.customMessageTagRepository.createRecord();
  }
}
