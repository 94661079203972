import ApplicationSerializer from 'uplisting-frontend/serializers/application';
import DS from 'ember-data';

export default class ClientStatementExpenseSerializer extends ApplicationSerializer {
  serialize(snapshot: DS.Snapshot, options: object) {
    const data = super.serialize(snapshot, options);

    // Until we find some solution for the types of the json api object
    // ignoring the type errors
    // @ts-expect-error - we have no typings for the json api
    if (!data?.data.relationships?.tax?.data) {
      // @ts-expect-error - we have no typings for the json api
      delete data.data.relationships?.tax;
    }

    return data;
  }
}

declare module 'ember-data/types/registries/serializer' {
  interface SerializerRegistry {
    'client-statement-expense': ClientStatementExpenseSerializer;
  }
}
