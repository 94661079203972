import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import { type Registry as Services, inject as service } from '@ember/service';
import Transition from '@ember/routing/transition';

export default class AutomatePropertyAttributesRoute extends PermittedRoute {
  @service('repositories/custom-property-attribute')
  customPropertyAttributeRepository!: Services['repositories/custom-property-attribute'];

  permission = 'automate.property_attributes';

  model() {
    return this.customPropertyAttributeRepository.findAll();
  }

  // don't unload `custom-property-attribute` records as they are used on the following routes:
  // - `automate.message-tags`
  // - `automate.rental-agreements`
  resetController(_controller, _isExiting, transition: Transition): void {
    const newRouteName = transition.to?.name;

    if (
      newRouteName !== 'automate.message-tags' &&
      newRouteName !== 'automate.rental-agreements'
    ) {
      this.customPropertyAttributeRepository.unloadAll();
    }
  }
}
