import InsightsBaseController from 'uplisting-frontend/pods/insights/base-controller';
import { cached } from '@glimmer/tracking';
import ReviewStatisticModel from 'uplisting-frontend/models/review-statistic';
import { action } from '@ember/object';
import isEqual from 'lodash-es/isEqual';
import { IApplyPropertiesFilterParams } from 'uplisting-frontend/pods/components/ui/properties/filter/component';
import PropertyModel from 'uplisting-frontend/models/property';
import { underscore } from '@ember/string';

export default class InsightsAirbnbController extends InsightsBaseController<ReviewStatisticModel> {
  queryParams = [
    'start',
    'end',

    'compareToStart',
    'compareToEnd',

    'propertyIds',
    'compareToPropertyIds',
  ];

  @cached
  get isDateFilterComparisonDisabled(): boolean {
    return !!this.compareToPropertyIds.length;
  }

  @cached
  get properties(): PropertyModel[] {
    return super.properties.filter(
      (property) => property.hasConfirmedAirbnbLink,
    );
  }

  @cached
  get positiveTag(): string | undefined {
    return this.getTranslationFor(this.record.mostCommonPositiveTag);
  }

  @cached
  get negativeTag(): string | undefined {
    return this.getTranslationFor(this.record.mostCommonNegativeTag);
  }

  @action
  handlePropertiesFilterApply(obj: IApplyPropertiesFilterParams): void {
    const ids = obj.items.map((item) => item.id);
    const compareToIds = obj.compareToItems.map((item) => item.id);

    const selectedIds = this.selectedItems.map((item) => item.id);
    const selectedCompareToIds = this.selectedCompareToItems.map(
      (item) => item.id,
    );

    if (
      isEqual(selectedIds, ids) &&
      isEqual(selectedCompareToIds, compareToIds)
    ) {
      return;
    }

    if (obj.isSelectingProperties) {
      this.propertyIds = ids;
      this.compareToPropertyIds = compareToIds;
    } else {
      this.propertyIds = [];
      this.compareToPropertyIds = [];
    }

    this.isLoading = true;
  }

  private getTranslationFor(value?: string): string | undefined {
    const translationKey = `insights_airbnb.airbnb_tags.${underscore(value || '')}`;

    if (this.intl.exists(translationKey)) {
      return this.intl.t(translationKey);
    }
  }
}
