import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import RSVP from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import ConnectSeamController from 'uplisting-frontend/pods/connect/seam/controller';
import Transition from '@ember/routing/transition';

export default class ConnectSeamRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/seam-lockable')
  seamLockableRepositoryService!: Services['repositories/seam-lockable'];

  @service('repositories/seam-connection')
  seamConnectionRepositoryService!: Services['repositories/seam-connection'];

  permission = 'connect.smart_locks';

  model() {
    return RSVP.hash({
      properties: this.propertyRepository.findAll(),
      seamConnections: this.seamConnectionRepositoryService.findAll(),
    });
  }

  setupController(
    controller: ConnectSeamController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }

  resetController(): void {
    this.seamConnectionRepositoryService.unloadAll();
    this.seamLockableRepositoryService.unloadAll();
  }
}
