import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { modifier } from 'ember-modifier';

interface IArgs {
  categories: string;
}

export interface ZapierChangelogSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const ZAPIER_URL = 'https://zapier.com/apps/embed/widget.js?';

export default class ZapierWidgetComponent extends Component<ZapierChangelogSignature> {
  @cached
  get id(): string {
    return guidFor(this);
  }

  handleInsert = modifier(() => {
    const script = document.createElement('script');

    script.src = `${ZAPIER_URL}&html_id=${this.id}&guided_zaps=${this.args.categories}`;
    script.async = true;

    (document.getElementsByTagName('head')[0] as HTMLHeadElement).appendChild(
      script,
    );
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Widgets::Zapier': typeof ZapierWidgetComponent;
  }
}
