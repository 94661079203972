import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import { type Registry as Services, inject as service } from '@ember/service';

export default class NotificationsAirbnbRoute extends PermittedRoute {
  @service('repositories/channel-notification')
  channelNotificationRepository!: Services['repositories/channel-notification'];

  @service('repositories/airbnb-official-notification')
  airbnbOfficialNotificationRepository!: Services['repositories/airbnb-official-notification'];

  @service('repositories/airbnb-official-opportunity')
  airbnbOfficialOpportunityRepository!: Services['repositories/airbnb-official-opportunity'];

  permission = 'notifications.airbnb';

  model() {
    return this.channelNotificationRepository.findRecord('me');
  }

  resetController() {
    this.channelNotificationRepository.unloadAll();
    this.airbnbOfficialNotificationRepository.unloadAll();
    this.airbnbOfficialOpportunityRepository.unloadAll();
  }
}
