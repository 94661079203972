import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import AutomatedMessageRuleModel from 'uplisting-frontend/models/automated-message-rule';
import { AutomatedMessageRuleValidation } from 'uplisting-frontend/validations';

export default class AutomatedMessageRuleRepositoryService extends BaseRepositoryService<AutomatedMessageRuleModel> {
  recordName = 'automated-message-rule';
  implementMethods = ['findAll', 'createRecord', 'buildChangeset'];

  validation = AutomatedMessageRuleValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/automated-message-rule': AutomatedMessageRuleRepositoryService;
  }
}
