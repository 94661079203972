import {
  validateNumber,
  validateInclusion,
} from 'ember-changeset-validations/validators';
import {
  PaymentRuleEvent,
  PaymentRuleChannel,
  PaymentRulePreposition,
} from 'uplisting-frontend/models/schemas';

export const PaymentRuleValidation = {
  immediateChargePercentage: validateNumber({
    gt: 0,
    lte: 100,
    allowBlank: false,
    integer: true,
  }),

  remainingBalanceChargeDays: validateNumber({
    gte: 0,
    integer: true,
    allowBlank: false,
  }),

  channel: validateInclusion({ list: Object.values(PaymentRuleChannel) }),
  event: validateInclusion({ list: Object.values(PaymentRuleEvent) }),

  preposition: validateInclusion({
    list: Object.values(PaymentRulePreposition),
  }),
};
