import {
  validateNumber,
  validatePresence,
  validateFormat,
} from 'ember-changeset-validations/validators';
import {
  validateUniqueness,
  customMessageValidator,
} from 'uplisting-frontend/validators';

export const PromotionValidation = {
  name: customMessageValidator(
    'promotion_name',
    validatePresence({
      presence: true,
      ignoreBlank: true,
    }),
  ),

  code: [
    customMessageValidator(
      'promotion_code',
      validateFormat({
        presence: true,
        regex: /^[a-z0-9-]+$/i,
        ignoreBlank: true,
      }),
    ),
    customMessageValidator('unique_promotion_code', validateUniqueness()),
  ],

  flatAmount: validateNumber({ gte: 0, allowBlank: false }),

  percentageAmount: validateNumber({ gte: 0, lte: 100, allowBlank: false }),
};
