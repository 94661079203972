import {
  FilterId,
  FilterOptionsType,
  MultiSelectFilter,
} from 'uplisting-frontend/utils/filters/abstract';
import ClientModel from 'uplisting-frontend/models/client';
import DataModel from '@ember-data/model';

export class ClientNameFilter extends MultiSelectFilter<ClientModel> {
  id = FilterId.clientName;
  optionsType = FilterOptionsType.store;
  modelName = 'client';
  modelField = 'name' as keyof DataModel;
}
