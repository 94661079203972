import BaseController from 'uplisting-frontend/pods/base/controller';
import AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';
import { cached, tracked } from '@glimmer/tracking';
import PropertyModel from 'uplisting-frontend/models/property';
import { AIRBNB_CONNECT_LINK } from 'uplisting-frontend/utils';
import { runTask } from 'ember-lifeline';

export default class ConnectAirbnbController extends BaseController {
  @cached @tracked properties!: PropertyModel[];
  @cached @tracked airbnbOfficialConnections!: AirbnbOfficialConnectionModel[];

  @cached @tracked isConnectShown = false;

  queryParams = ['problem', 'existing'];
  problem;
  existing;

  connectLink = AIRBNB_CONNECT_LINK;

  public handleCallbackResponse(): void {
    if (this.problem) {
      if (this.existing) {
        this.notifications.error(
          'connect_airbnb.already_connected',
          {},
          {
            closeAfter: 20000,
          },
        );
      } else {
        this.notifications.error();
      }
    }

    this.airbnbOfficialConnections.map((connection) => {
      this.refresh(connection);
    });
  }

  private refresh(connection): void {
    if (!connection.isOnboarding) {
      return;
    }

    const delay = 10_000;

    runTask(
      this,
      async () => {
        try {
          await connection.reload();

          this.refresh(connection);
        } catch {
          await this.router.transitionTo('connect.airbnb', {
            queryParams: { problem: true },
          });
        }
      },
      delay,
    );
  }
}
