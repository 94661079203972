export default {
  "sidebar": "_sidebar_n9q53t",
  "navbar-brand": "_navbar-brand_n9q53t",
  "item": "_item_n9q53t",
  "popover": "_popover_n9q53t",
  "link": "_link_n9q53t",
  "counter": "_counter_n9q53t",
  "active": "_active_n9q53t",
  "link-title": "_link-title_n9q53t",
  "bottom-section": "_bottom-section_n9q53t"
};
