import { validatePresence } from 'ember-changeset-validations/validators';
import { customMessageValidator } from 'uplisting-frontend/validators';

export const BookingRentalAgreementSettingValidation = {
  content: [
    customMessageValidator(
      'booking_rental_agreement_setting_content',
      validatePresence({
        presence: true,
      }),
    ),
  ],
};
