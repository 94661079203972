import {
  validatePresence,
  validateNumber,
} from 'ember-changeset-validations/validators';
import { customMessageValidator } from 'uplisting-frontend/validators';

export const AutoresponderValidation = {
  delayInMinutes: [
    validateNumber({
      gte: 0,
      lte: 60,
      integer: true,
      allowBlank: true,
    }),
  ],

  message: [
    customMessageValidator(
      'autoresponder_message',
      validatePresence({
        presence: true,
      }),
    ),
  ],
};
