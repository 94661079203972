import {
  validatePresence,
  validateNumber,
} from 'ember-changeset-validations/validators';

export const AccountConfigurationValidation = {
  displayName: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),

  defaultSecurityDepositAmount: [
    validateNumber({ gte: 0, integer: true, allowBlank: false }),
  ],
};
