import Component from '@glimmer/component';
import { OccasionPredefinedOptionsFilter } from 'uplisting-frontend/utils/occasion-filters';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';

interface IArgs {
  filter: OccasionPredefinedOptionsFilter;
}

interface ReportFiltersOptionsSignature {
  Element: HTMLUListElement;

  Args: IArgs;
}

export default class UiReportFiltersOptionsMultiSelectComponent extends Component<ReportFiltersOptionsSignature> {
  @cached
  get filter(): OccasionPredefinedOptionsFilter {
    return this.args.filter;
  }

  @cached
  get options(): string[] {
    return this.filter.optionsList;
  }

  @cached
  get translationField(): string {
    return this.filter.field.replace('booking_', '');
  }

  @action
  handleOptionSelect(option: string): void {
    this.filter.updateValues(option);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options::MultiSelect': typeof UiReportFiltersOptionsMultiSelectComponent;
  }
}
