import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { toggleValue } from 'uplisting-frontend/utils';
import { action } from '@ember/object';
import { IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import { ISectionItem } from 'uplisting-frontend/services/occasions';
import { OccasionAttribute } from 'uplisting-frontend/models/occasion';
import { modifier } from 'ember-modifier';

interface IArgs {
  selectedItems: OccasionAttribute[];
  sections: ISectionItem[];

  onApply(items: OccasionAttribute[]): void;
}

interface TableColumnSelectorSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiTableColumnSelectorComponent extends Component<TableColumnSelectorSignature> {
  @cached @tracked selectedItems!: OccasionAttribute[];
  @cached @tracked activeSection!: ISectionItem;

  @cached
  get sections(): ISectionItem[] {
    return this.args.sections;
  }

  @action
  handleColumnClick(item: OccasionAttribute): void {
    toggleValue(this, 'selectedItems', item);
  }

  @action
  handleSectionSwitch(section: ISectionItem): void {
    this.activeSection = section;
  }

  @action
  handleSubmit(dd: IDropdown): void {
    this.args.onApply(this.selectedItems);

    dd.closeDropdown();
  }

  handleMenuInsert = modifier(() => {
    this.activeSection = this.sections[0] as ISectionItem;
    this.selectedItems = this.args.selectedItems ?? [];
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Table::ColumnSelector': typeof UiTableColumnSelectorComponent;
  }
}
