import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import ClientStatementAccountDetailModel from 'uplisting-frontend/models/client-statement-account-detail';
import { type Registry as Services, inject as service } from '@ember/service';
import DS from 'ember-data';

export default class ClientsAccountDetailsController extends BaseController {
  @service('repositories/client-statement-account-detail')
  clientStatementAccountDetailRepository!: Services['repositories/client-statement-account-detail'];

  @cached
  @tracked
  accountDetails!: DS.PromiseArray<ClientStatementAccountDetailModel>;

  @cached
  get changesets() {
    return this.accountDetails.map((item) =>
      this.clientStatementAccountDetailRepository.buildChangeset(item),
    );
  }
}
