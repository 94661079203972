export default {
  "edit-fee-rule-form": "_edit-fee-rule-form_180o2p",
  "alert-item": "_alert-item_180o2p",
  "properties-selector": "_properties-selector_180o2p",
  "formula-section": "_formula-section_180o2p",
  "formula-section-label": "_formula-section-label_180o2p",
  "formula-button": "_formula-button_180o2p",
  "operators-group": "_operators-group_180o2p",
  "formula-input": "_formula-input_180o2p",
  "sample-result": "_sample-result_180o2p",
  "variable-item": "_variable-item_180o2p",
  "recalculate-option": "_recalculate-option_180o2p"
};
