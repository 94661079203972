import Transform from '@ember-data/serializer/transform';
import { A } from '@ember/array';

export default class ArrayTransform extends Transform {
  deserialize(serialized: unknown): unknown[] {
    return A(Array.isArray(serialized) ? serialized : []);
  }

  serialize(deserialized: unknown): unknown[] {
    return A(Array.isArray(deserialized) ? deserialized : []);
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    array: ArrayTransform;
  }
}
