import Model, { attr } from '@ember-data/model';
import {
  BookingRentalAgreementStatus,
  GuestIdentityVerificationStatus,
  IOccasionSchema,
  OccasionStatus,
  PaymentStatus,
  SecurityDepositStatus,
} from 'uplisting-frontend/models/schemas';
import { camelize } from '@ember/string';
import { cached } from '@glimmer/tracking';

// NOTE: giving here proper naming for the enum, because it's being later stored in the page QP's
// and we want them to be more meaningful, rather then store there `1,2,3...` and so on
export enum SectionIds {
  checkIn = 'checkIn',
  checkOut = 'checkOut',
  stays = 'Stays',

  newBookings = 'new',
  enquiries = 'enquiries',
  bookingRequests = 'requests',
  pendingBookings = 'pending',
  cancelledBookings = 'cancelled',

  bookingPayment = 'bookingPayments',
  securityDeposits = 'securityDeposits',
  bookingGuestIdentityVerifications = 'guestIdentity',
  bookingRentalAgreements = 'rentalAgreements',
}

export enum TableSectionFields {
  // occasion bookings
  checkIn = 'booking_check_in',
  checkOut = 'booking_check_out',
  stays = 'booking_overlap',

  // occasion activity
  newBookings = 'booking_creation_date',
  enquiries = 'enquiry_creation_date',
  bookingRequests = 'booking_request_creation_date',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  pendingBookings = 'booking_creation_date',
  bookingStatus = 'booking_status',
  enquiryStatus = 'enquiry_status',
  bookingRequestStatus = 'booking_request_status',
  cancelledBookings = 'booking_cancellation_date',

  // occasion actions
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  bookingPayment = 'booking_check_in',
  paid = 'booking_paid',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  securityDeposits = 'booking_check_in',
  depositStatus = 'booking_security_deposit_status',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  bookingGuestIdentityVerifications = 'booking_check_in',
  bookingGuestIdentityVerificationStatus = 'booking_guest_identity_verification_status',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  bookingRentalAgreements = 'booking_check_in',
  bookingRentalAgreementStatus = 'booking_rental_agreement_status',

  channel = 'booking_channel',
  propertyId = 'booking_property_id',
  propertyTimeZone = 'booking_property_time_zone',
  propertyTagId = 'booking_property_tag_id',
  multiUnitId = 'booking_multi_unit_id',
  duration = 'booking_duration',
}

export enum OccasionTypes {
  bookings = 'bookings',
  bookingRequests = 'bookingRequests',
  enquiries = 'enquiries',
}

export const sortFieldsMapping = {
  [OccasionTypes.bookings]: {
    propertyNickname: 'booking_property_nickname',
    multiUnitName: 'booking_multi_unit_name',
    guestName: 'booking_guest_name',
    checkIn: 'booking_check_in',
    checkOut: 'booking_check_out',
    bookingArrivalTime: 'booking_arrival_time',
    bookingDepartureTime: 'booking_departure_time',
    channel: 'booking_channel',
    status: 'booking_status',
    bookingCancellationDate: 'booking_cancellation_date',
  },

  [OccasionTypes.bookingRequests]: {
    propertyNickname: 'booking_request_property_nickname',
    multiUnitName: 'booking_request_multi_unit_name',
    guestName: 'booking_request_guest_name',
    checkIn: 'booking_request_check_in',
    checkOut: 'booking_request_check_out',
    numberOfGuests: 'booking_request_number_of_guests',
    channel: 'booking_request_channel',
    status: 'booking_request_status',
    creationDate: 'booking_request_creation_date',
  },

  [OccasionTypes.enquiries]: {
    propertyNickname: 'enquiry_property_nickname',
    guestName: 'enquiry_guest_name',
    checkIn: 'enquiry_check_in',
    checkOut: 'enquiry_check_out',
    numberOfGuests: 'enquiry_number_of_guests',
    channel: 'enquiry_channel',
    status: 'enquiry_status',
    creationDate: 'enquiry_creation_date',
  },
};

export const sectionFields = {
  [SectionIds.checkIn]: TableSectionFields.checkIn,
  [SectionIds.checkOut]: TableSectionFields.checkOut,
  [SectionIds.stays]: TableSectionFields.stays,
  [SectionIds.newBookings]: TableSectionFields.newBookings,
  [SectionIds.enquiries]: TableSectionFields.enquiries,
  [SectionIds.bookingRequests]: TableSectionFields.bookingRequests,
  [SectionIds.pendingBookings]: TableSectionFields.pendingBookings,
  [SectionIds.cancelledBookings]: TableSectionFields.cancelledBookings,
  [SectionIds.bookingPayment]: TableSectionFields.bookingPayment,
  [SectionIds.securityDeposits]: TableSectionFields.securityDeposits,
  [SectionIds.bookingGuestIdentityVerifications]:
    TableSectionFields.bookingGuestIdentityVerifications,
  [SectionIds.bookingRentalAgreements]:
    TableSectionFields.bookingRentalAgreements,
};

export const propertyToFieldMapping = {
  booking_guest_name: 'guestName',
  booking_guest_identity_verification_status: 'guestIdentityVerificationStatus',
  booking_guest_email: 'guestEmail',
  booking_guest_phone: 'guestPhone',

  id: 'id',
  booking_property_nickname: 'propertyNickname',
  booking_property_id: 'propertyId',
  booking_property_time_zone: 'propertyTimeZone',

  booking_multi_unit_name: 'multiUnitName',
  booking_check_in: 'checkIn',
  booking_check_out: 'checkOut',
  booking_arrival_time: 'arrivalTime',
  booking_departure_time: 'departureTime',
  booking_number_of_guests: 'numberOfGuests',
  booking_duration: 'duration',
  booking_channel: 'channel',
  booking_status: 'status',
  booking_remaining_nights: 'remainingNights',
  booking_total_payout: 'totalPayout',
  booking_creation_date: 'creationDate',
  booking_cancellation_date: 'cancellationDate',
  booking_currency: 'currency',
  booking_outstanding_amount: 'outstandingAmount',
  booking_source: 'source',
  booking_note: 'note',
  booking_rental_agreement_status: 'rentalAgreementStatus',
  booking_confirmation_code: 'confirmationCode',

  booking_payment_due_date: 'paymentDueDate',
  booking_payment_status: 'paymentStatus',
  booking_id: 'id',

  booking_security_deposit_amount: 'securityDepositAmount',
  booking_security_deposit_due_date: 'securityDepositDueDate',
  booking_security_deposit_status: 'securityDepositStatus',

  booking_price_accommodation_total: 'accommodationTotal',
  booking_price_cleaning_fee: 'cleaningFee',
  booking_price_extra_guest_charges: 'extraGuestCharges',
  booking_price_extra_charges: 'extraCharges',
  booking_price_discounts: 'discounts',
  booking_price_taxes: 'taxes',
  booking_price_payment_processing_fee: 'paymentProcessingFee',
  booking_price_commission: 'commission',
  booking_price_commission_tax: 'commissionTax',
  booking_price_cancellation_fee: 'cancellationFee',
  booking_price_accommodation_management_fee: 'accommodationManagementFee',
  booking_price_cleaning_management_fee: 'cleaningManagementFee',
  booking_price_total_management_fee: 'totalManagementFee',
  booking_price_gross_revenue: 'grossRevenue',
  booking_price_net_revenue: 'netRevenue',
  booking_price_balance: 'balance',
  booking_price_upsells_total: 'upsellsTotal',
};

export enum OccasionAttribute {
  propertyNickname = 'propertyNickname',
  guestName = 'guestName',
  multiUnitName = 'multiUnitName',
  checkIn = 'checkIn',
  checkOut = 'checkOut',
  channel = 'channel',
  creationDate = 'creationDate',
  cancellationDate = 'cancellationDate',
  guestIdentityVerificationStatus = 'guestIdentityVerificationStatus',
  rentalAgreementStatus = 'rentalAgreementStatus',
  duration = 'duration',
  numberOfGuests = 'numberOfGuests',
  status = 'status',
  remainingNights = 'remainingNights',
  propertyId = 'propertyId',
  propertyTimeZone = 'propertyTimeZone',
  outstandingAmount = 'outstandingAmount',
  paymentDueDate = 'paymentDueDate',
  paymentStatus = 'paymentStatus',
  totalPayout = 'totalPayout',
  currency = 'currency',
  arrivalTime = 'arrivalTime',
  departureTime = 'departureTime',
  securityDepositAmount = 'securityDepositAmount',
  securityDepositDueDate = 'securityDepositDueDate',
  securityDepositStatus = 'securityDepositStatus',
  source = 'source',
  guestEmail = 'guestEmail',
  guestPhone = 'guestPhone',
  note = 'note',
  confirmationCode = 'confirmationCode',
  accommodationTotal = 'accommodationTotal',
  cleaningFee = 'cleaningFee',
  extraGuestCharges = 'extraGuestCharges',
  extraCharges = 'extraCharges',
  discounts = 'discounts',
  taxes = 'taxes',
  paymentProcessingFee = 'paymentProcessingFee',
  commission = 'commission',
  commissionTax = 'commissionTax',
  cancellationFee = 'cancellationFee',
  accommodationManagementFee = 'accommodationManagementFee',
  cleaningManagementFee = 'cleaningManagementFee',
  totalManagementFee = 'totalManagementFee',
  grossRevenue = 'grossRevenue',
  netRevenue = 'netRevenue',
  balance = 'balance',
  id = 'id',
  upsellsTotal = 'upsellsTotal',
}

function swapFieldNameToKey(obj: object): object {
  const res = {};

  for (const key in obj) {
    if (key.startsWith('booking_request') || key.startsWith('enquiry')) {
      continue;
    }

    res[obj[key]] = key;
  }

  return res;
}

export const fieldToPropertyMapping = swapFieldNameToKey(
  propertyToFieldMapping,
);

export default class OccasionModel extends Model implements IOccasionSchema {
  // booking occasion fields
  @attr('string') bookingGuestName!: string;
  @attr('string') bookingPropertyNickname!: string;
  @attr('string') bookingMultiUnitName!: string;
  @attr('string') bookingCheckIn!: string;
  @attr('string') bookingCheckOut!: string;
  @attr('string') bookingArrivalTime!: string;
  @attr('string') bookingDepartureTime!: string;
  @attr('number') bookingNumberOfGuests!: number;
  @attr('number') bookingDuration!: number;
  @attr('string') bookingChannel!: string;
  @attr('string') bookingStatus!: OccasionStatus;
  @attr('number') bookingRemainingNights!: number;
  @attr('number') bookingTotalPayout!: number;
  @attr('date') bookingCreationDate!: Date;
  @attr('date') bookingCancellationDate!: Date;
  @attr('string') bookingCurrency!: string;

  // enquiry occasion fields
  @attr('string') enquiryPropertyNickname!: string;
  @attr('string') enquiryChannel!: string;
  @attr('string') enquiryCheckIn!: string;
  @attr('string') enquiryCheckOut!: string;
  @attr('number') enquiryDuration!: number;
  @attr('string') enquiryGuestName!: string;
  @attr('number') enquiryNumberOfGuests!: number;
  @attr('string') enquiryStatus!: OccasionStatus;
  @attr('date') enquiryCreationDate!: Date;

  // booking requests occasion fields
  @attr('string') bookingRequestPropertyNickname!: string;
  @attr('string') bookingRequestMultiUnitName!: string;
  @attr('string') bookingRequestGuestName!: string;
  @attr('string') bookingRequestCheckIn!: string;
  @attr('string') bookingRequestCheckOut!: string;
  @attr('number') bookingRequestNumberOfGuests!: number;
  @attr('number') bookingRequestDuration!: number;
  @attr('string') bookingRequestChannel!: string;
  @attr('string') bookingRequestStatus!: OccasionStatus;
  @attr('date') bookingRequestCreationDate!: Date;

  // booking payment occasion fields
  @attr('number') bookingOutstandingAmount!: number;
  @attr('date') bookingPaymentDueDate!: Date;
  @attr('string') bookingPaymentStatus!: PaymentStatus;

  // security deposits occasion fields
  @attr('number') bookingSecurityDepositAmount!: number;
  @attr('date') bookingSecurityDepositDueDate!: Date;
  @attr('string') bookingSecurityDepositStatus!: SecurityDepositStatus;

  // guest identity verification occasion fields
  @attr('string')
  bookingGuestIdentityVerificationStatus!: GuestIdentityVerificationStatus;

  // booking rental agreements occasion fields
  @attr('string') bookingRentalAgreementStatus!: BookingRentalAgreementStatus;

  @attr('string') bookingSource!: string;
  @attr('string') bookingGuestEmail!: string;
  @attr('string') bookingGuestPhone!: string;
  @attr('string') bookingNote!: string;
  @attr('string') bookingConfirmationCode!: string;
  @attr('string') bookingPropertyId!: string;
  @attr('string') bookingPropertyTimeZone!: string;

  @attr('number') bookingPriceAccommodationTotal!: number;
  @attr('number') bookingPriceCleaningFee!: number;
  @attr('string') bookingPriceExtraGuestCharges!: string;
  @attr('number') bookingPriceExtraCharges!: number;
  @attr('number') bookingPriceDiscounts!: number;
  @attr('number') bookingPriceTaxes!: number;
  @attr('number') bookingPricePaymentProcessingFee!: number;
  @attr('number') bookingPriceCommission!: number;
  @attr('number') bookingPriceCommissionTax!: number;
  @attr('string') bookingPriceCancellationFee!: string;
  @attr('string') bookingPriceAccommodationManagementFee!: string;
  @attr('string') bookingPriceCleaningManagementFee!: string;
  @attr('string') bookingPriceTotalManagementFee!: string;
  @attr('number') bookingPriceGrossRevenue!: number;
  @attr('number') bookingPriceNetRevenue!: number;
  @attr('number') bookingPriceBalance!: number;
  @attr('number') bookingPriceUpsellsTotal!: number;

  @cached
  get occasionType(): string {
    const [type] = this.id.split('-');

    return camelize(type as string);
  }

  @cached
  get occasionId(): string {
    const [, id] = this.id.split('-');

    return id as string;
  }

  @cached
  get bookingLink(): string {
    const { occasionId } = this;

    switch (this.occasionType) {
      case 'bookingRequest':
        return `/calendar/requests/${occasionId}/summary`;
      case 'enquiry':
        return `/calendar/enquiries/${occasionId}/summary`;
      default:
        return `/calendar/bookings/${occasionId}/summary`;
    }
  }

  @cached
  get propertyNickname(): string {
    return this[`${this.occasionType}PropertyNickname`];
  }

  @cached
  get guestName(): string {
    return this[`${this.occasionType}GuestName`];
  }

  @cached
  get multiUnitName(): string {
    return this[`${this.occasionType}MultiUnitName`];
  }

  @cached
  get checkIn(): Date {
    return this[`${this.occasionType}CheckIn`];
  }

  @cached
  get checkOut(): Date {
    return this[`${this.occasionType}CheckOut`];
  }

  @cached
  get channel(): string {
    return this[`${this.occasionType}Channel`];
  }

  @cached
  get creationDate(): string {
    return this[`${this.occasionType}CreationDate`];
  }

  @cached
  get cancellationDate(): string {
    return this[`${this.occasionType}CancellationDate`];
  }

  @cached
  get guestIdentityVerificationStatus(): GuestIdentityVerificationStatus {
    return this[`${this.occasionType}GuestIdentityVerificationStatus`];
  }

  @cached
  get rentalAgreementStatus(): BookingRentalAgreementStatus {
    return this[`${this.occasionType}RentalAgreementStatus`];
  }

  @cached
  get duration(): number {
    return this[`${this.occasionType}Duration`];
  }

  @cached
  get numberOfGuests(): number {
    return this[`${this.occasionType}NumberOfGuests`];
  }

  @cached
  get status(): OccasionStatus {
    return this[`${this.occasionType}Status`];
  }

  @cached
  get remainingNights(): number {
    return this[`${this.occasionType}RemainingNights`];
  }

  @cached
  get propertyId(): string {
    return this[`${this.occasionType}PropertyId`];
  }

  @cached
  get propertyTimeZone(): string {
    return this[`${this.occasionType}PropertyTimeZone`];
  }

  @cached
  get outstandingAmount(): number {
    return this[`${this.occasionType}OutstandingAmount`];
  }

  @cached
  get paymentDueDate(): Date {
    return this[`${this.occasionType}PaymentDueDate`];
  }

  @cached
  get paymentStatus(): PaymentStatus {
    return this[`${this.occasionType}PaymentStatus`];
  }

  @cached
  get totalPayout(): number {
    return this[`${this.occasionType}TotalPayout`];
  }

  @cached
  get currency(): string {
    return this[`${this.occasionType}Currency`];
  }

  @cached
  get arrivalTime(): string {
    return this[`${this.occasionType}ArrivalTime`];
  }

  @cached
  get departureTime(): string {
    return this[`${this.occasionType}DepartureTime`];
  }

  @cached
  get securityDepositAmount(): number {
    return this[`${this.occasionType}SecurityDepositAmount`];
  }

  @cached
  get securityDepositDueDate(): Date {
    return this[`${this.occasionType}SecurityDepositDueDate`];
  }

  @cached
  get securityDepositStatus(): SecurityDepositStatus {
    return this[`${this.occasionType}SecurityDepositStatus`];
  }

  @cached
  get source(): string {
    return this[`${this.occasionType}Source`];
  }

  @cached
  get guestEmail(): string {
    return this[`${this.occasionType}GuestEmail`];
  }

  @cached
  get guestPhone(): string {
    return this[`${this.occasionType}GuestPhone`];
  }

  @cached
  get note(): string {
    return this[`${this.occasionType}Note`];
  }

  @cached
  get confirmationCode(): string {
    return this[`${this.occasionType}ConfirmationCode`];
  }

  @cached
  get accommodationTotal(): number {
    return this[`${this.occasionType}PriceAccommodationTotal`];
  }

  @cached
  get cleaningFee(): number {
    return this[`${this.occasionType}PriceCleaningFee`];
  }

  @cached
  get extraGuestCharges(): string {
    return this[`${this.occasionType}PriceExtraGuestCharges`];
  }

  @cached
  get extraCharges(): number {
    return this[`${this.occasionType}PriceExtraCharges`];
  }

  @cached
  get discounts(): number {
    return this[`${this.occasionType}PriceDiscounts`];
  }

  @cached
  get taxes(): number {
    return this[`${this.occasionType}PriceTaxes`];
  }

  @cached
  get paymentProcessingFee(): number {
    return this[`${this.occasionType}PricePaymentProcessingFee`];
  }

  @cached
  get commission(): number {
    return this[`${this.occasionType}PriceCommission`];
  }

  @cached
  get commissionTax(): number {
    return this[`${this.occasionType}PriceCommissionTax`];
  }

  @cached
  get cancellationFee(): string {
    return this[`${this.occasionType}PriceCancellationFee`];
  }

  @cached
  get accommodationManagementFee(): string {
    return this[`${this.occasionType}PriceAccommodationManagementFee`];
  }

  @cached
  get cleaningManagementFee(): string {
    return this[`${this.occasionType}PriceCleaningManagementFee`];
  }

  @cached
  get totalManagementFee(): string {
    return this[`${this.occasionType}PriceTotalManagementFee`];
  }

  @cached
  get grossRevenue(): number {
    return this[`${this.occasionType}PriceGrossRevenue`];
  }

  @cached
  get netRevenue(): number {
    return this[`${this.occasionType}PriceNetRevenue`];
  }

  @cached
  get balance(): number {
    return this[`${this.occasionType}PriceBalance`];
  }

  @cached
  get upsellsTotal(): number {
    return this[`${this.occasionType}PriceUpsellsTotal`];
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    occasion: OccasionModel;
  }
}
