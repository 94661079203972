import { helper } from '@ember/component/helper';

/**
 * @description Faster implementation of the includes helper from the ember-composable-helpers
 */
export function arrayIncludes([item, array]: [unknown, unknown[]]): boolean {
  return array.includes(item);
}

export default helper(arrayIncludes);
