import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import SeamConnectionModel from 'uplisting-frontend/models/seam-connection';

export default class SeamConnectionRepositoryService extends BaseRepositoryService<SeamConnectionModel> {
  recordName = 'seam-connection';
  implementMethods = ['findAll', 'createRecord', 'unloadAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/seam-connection': SeamConnectionRepositoryService;
  }
}
