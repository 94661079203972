import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import ClientModel from 'uplisting-frontend/models/client';
import { searchOptions } from 'uplisting-frontend/utils';
import { action } from '@ember/object';
import { ClientStatus } from 'uplisting-frontend/models/schemas';
import sortBy from 'lodash-es/sortBy';

export default class ClientsIndexController extends BaseController {
  @cached @tracked inputValue = '';
  @cached @tracked showCreateClientModal = false;
  @cached @tracked showArchiveClientModal = false;
  @cached @tracked clients: ClientModel[] = [];
  @cached @tracked clientToArchive?: ClientModel;

  @cached
  get existingClients(): ClientModel[] {
    return this.clients.filter((record) => record.id);
  }

  @cached
  get activeClientsSorted(): ClientModel[] {
    return this.filterClientsBy('isActive');
  }

  @cached
  get inactiveClientsSorted(): ClientModel[] {
    return this.filterClientsBy('isArchived');
  }

  @cached
  get clientsSorted(): ClientModel[] {
    return [...this.activeClientsSorted, ...this.inactiveClientsSorted];
  }

  @cached
  get filteredItems(): ClientModel[] {
    return searchOptions<ClientModel>(
      this.clientsSorted,
      this.inputValue,
      'name',
    );
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  async handleOpenClient(client: ClientModel): Promise<void> {
    await this.router.transitionTo('clients.client.profile', client.id);
  }

  @action
  handleAddNewClient(): void {
    this.showCreateClientModal = true;
  }

  @action
  handleCloseCreateClientModal(): void {
    this.showCreateClientModal = false;
  }

  @action
  handleShowArchiveClientModal(client: ClientModel): void {
    this.showArchiveClientModal = true;

    this.clientToArchive = client;
  }

  @action
  handleCloseArchiveClientModal(): void {
    this.showArchiveClientModal = false;
  }

  @action
  async handleClientArchive(): Promise<void> {
    try {
      const client = this.clientToArchive as ClientModel;

      client.status = ClientStatus.archived;

      await client.save();

      this.clientToArchive = undefined;

      this.notifications.success('notifications.applied');
    } catch {
      this.notifications.error();
    }
  }

  private filterClientsBy(field: keyof ClientModel): ClientModel[] {
    return sortBy(
      this.existingClients.filter((record) => record[field]),
      'name',
    );
  }
}
