export enum ChannelCommissionChannel {
  bookingDotCom = 'booking_dot_com',
  homeAway = 'home_away',
  airbnbOfficial = 'airbnb_official',
  google = 'google',
  direct = 'direct',
}

export interface IChannelCommissionSchema {
  amount: number;
  channel: ChannelCommissionChannel;
}
