import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ClientStatementExpenseModel from 'uplisting-frontend/models/client-statement-expense';
import { ClientStatementExpenseValidation } from 'uplisting-frontend/validations';

export default class ClientStatementExpenseRepositoryService extends BaseRepositoryService<ClientStatementExpenseModel> {
  recordName = 'client-statement-expense';
  implementMethods = ['buildChangeset', 'createRecord'];

  validation = ClientStatementExpenseValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/client-statement-expense': ClientStatementExpenseRepositoryService;
  }
}
