import Component from '@glimmer/component';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import PropertyModel from 'uplisting-frontend/models/property';
import PropertyTagModel from 'uplisting-frontend/models/property-tag';
import { toggleValue, searchOptions } from 'uplisting-frontend/utils';
import { action } from '@ember/object';
import { IDropdown } from 'ember-bootstrap/components/bs-dropdown';

enum FilterBy {
  properties,
  tags,
}

export type FilterOption = PropertyModel | PropertyTagModel;

export interface IApplyPropertiesFilterParams {
  items: FilterOption[];
  compareToItems: FilterOption[];
  isSelectingProperties: boolean;
}

interface PropertiesFilterSignature {
  Element: HTMLDivElement;

  Args: {
    appliedItems: FilterOption[];
    appliedCompareToItems: FilterOption[];

    isComparisonDisabled: boolean;

    properties: PropertyModel[];
    propertyTags?: PropertyTagModel[];
    showPropertyTags?: false;

    onApply(obj: IApplyPropertiesFilterParams): void;
  };
}

export default class UiPropertiesFilterComponent extends Component<PropertiesFilterSignature> {
  @service screen!: Services['screen'];

  filterByOptions = FilterBy;

  @cached @tracked inputValue = '';
  @cached @tracked compareToInputValue = '';

  @cached @tracked isFilterBySelected = false;
  @cached @tracked isCompareToSelected = false;

  @cached @tracked activeFilterBy!: FilterBy;

  @cached @tracked items: FilterOption[] = [];
  @cached @tracked compareToItems: FilterOption[] = [];

  @cached
  get appliedItems(): FilterOption[] {
    return this.args.appliedItems;
  }

  @cached
  get appliedCompareToItems(): FilterOption[] {
    return this.args.appliedCompareToItems;
  }

  @cached
  get appliedOptionNames(): string {
    return this.getSelectedOptionNames('appliedItems');
  }

  @cached
  get appliedCompareToOptionNames(): string {
    return this.getSelectedOptionNames('appliedCompareToItems');
  }

  @cached
  get isSelectingProperties(): boolean {
    return this.activeFilterBy === FilterBy.properties;
  }

  @cached
  get isCompareToEnabled(): boolean {
    return this.isFilterBySelected && !!this.items.length;
  }

  @cached
  get propertyTags(): PropertyTagModel[] {
    return this.args.propertyTags ?? [];
  }

  @cached
  get showPropertyTags(): boolean {
    return this.args.showPropertyTags ?? true;
  }

  @cached
  get options(): FilterOption[] {
    if (this.isSelectingProperties) {
      return this.args.properties;
    }

    return this.propertyTags;
  }

  @cached
  get filteredOptions(): FilterOption[] {
    return searchOptions<FilterOption>(
      this.options,
      this.inputValue,
      'searchName',
    );
  }

  @cached
  get filteredCompareToOptions(): FilterOption[] {
    return searchOptions<FilterOption>(
      this.options,
      this.compareToInputValue,
      'searchName',
    );
  }

  @cached
  get selectedItems(): FilterOption[] {
    return this.getSelectedItems(this.items);
  }

  @cached
  get selectedCompareToItems(): FilterOption[] {
    return this.getSelectedItems(this.compareToItems);
  }

  @cached
  get selectedItemsPresent(): boolean {
    return !!this.selectedItems.length;
  }

  @cached
  get selectedCompareToItemsPresent(): boolean {
    return !!this.selectedCompareToItems.length;
  }

  @action
  handleInputSearch(
    field: 'inputValue' | 'compareToInputValue',
    value: string,
  ): void {
    this[field] = value;
  }

  @action
  handleChangeFilterBy(filterBy: FilterBy): void {
    this.activeFilterBy = filterBy;
    this.isFilterBySelected = this.selectedItemsPresent;
    this.isCompareToSelected =
      this.isCompareToEnabled && this.selectedCompareToItemsPresent;
  }

  @action
  handleOptionClick(
    field: 'items' | 'compareToItems',
    option: FilterOption,
  ): void {
    toggleValue(this, field, option);
  }

  @action
  handleShow(): void {
    this.items = this.args.appliedItems;
    this.compareToItems = this.args.appliedCompareToItems;
  }

  @action
  handleInsert(): void {
    this.activeFilterBy = this.isSelectingPropertyTags(this.items)
      ? FilterBy.tags
      : FilterBy.properties;

    this.isFilterBySelected = !!this.selectedItems.length;
    this.isCompareToSelected = this.selectedCompareToItemsPresent;
  }

  @action
  handleApply(dd: IDropdown): void {
    const { isFilterBySelected, isCompareToSelected } = this;

    const items = isFilterBySelected ? this.selectedItems : [];
    const compareToItems =
      isFilterBySelected && isCompareToSelected
        ? this.selectedCompareToItems
        : [];

    this.args.onApply({
      items,
      compareToItems,
      isSelectingProperties: this.isSelectingProperties,
    });

    dd.closeDropdown();
  }

  @action
  handleClose(dd?: IDropdown): void {
    this.inputValue = '';
    this.compareToInputValue = '';

    dd?.closeDropdown();
  }

  @action
  computeIsDisabledCompareToItem(property: PropertyModel): boolean {
    return (
      !this.isFilterBySelected ||
      !this.isCompareToSelected ||
      this.items.includes(property)
    );
  }

  @action
  computeIsDisabledItem(property: PropertyModel): boolean {
    return !this.isFilterBySelected || this.compareToItems.includes(property);
  }

  private getSelectedOptionNames(field: string): string {
    return this[field].map((item) => item.searchName).join(', ');
  }

  private getSelectedItems(items: FilterOption[]): FilterOption[] {
    if (this.isSelectingProperties) {
      return items.filter((item) => item instanceof PropertyModel);
    }

    return items.filter((item) => item instanceof PropertyTagModel);
  }

  private isSelectingPropertyTags(items: FilterOption[]): boolean {
    return !!items.filter((item) => item instanceof PropertyTagModel).length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Properties::Filter': typeof UiPropertiesFilterComponent;
  }
}
