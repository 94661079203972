import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import BookingWindowRuleModel from 'uplisting-frontend/models/booking-window-rule';
import { BookingWindowRuleValidation } from 'uplisting-frontend/validations';

export default class BookingWindowRuleRepositoryService extends BaseRepositoryService<BookingWindowRuleModel> {
  recordName = 'booking-window-rule';
  implementMethods = ['buildChangeset', 'findAll', 'unloadAll'];

  validation = BookingWindowRuleValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/booking-window-rule': BookingWindowRuleRepositoryService;
  }
}
