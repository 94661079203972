import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import PromotionRedemptionModel from 'uplisting-frontend/models/promotion-redemption';
import { PromotionValidation } from 'uplisting-frontend/validations';

type ICache = Record<string, PromotionRedemptionModel[]>;

export default class PromotionRedemptionRepositoryService extends BaseRepositoryService<PromotionRedemptionModel> {
  recordName = 'promotion-redemption';
  implementMethods = ['query', 'unloadAll'];

  private cache: ICache = {};

  validation = PromotionValidation;

  public getDataFromCache(id: string): PromotionRedemptionModel[] | undefined {
    if (this.cache[id]) {
      return this.cache[id];
    }
  }

  public async fetchDataByPromotionId(
    id: string,
  ): Promise<PromotionRedemptionModel[]> {
    const redemptions = await this.query({
      promotion_id: id,
    });

    const data = redemptions.slice();

    this.cache[id] = data;

    return data;
  }

  public unloadAll(): void {
    this.cache = {};

    super.unloadAll();
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/promotion-redemption': PromotionRedemptionRepositoryService;
  }
}
