import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import PropertyModel from 'uplisting-frontend/models/property';

export default class PropertyRepositoryService extends BaseRepositoryService<PropertyModel> {
  recordName = 'property';
  implementMethods = ['findAll', 'peekAll', 'unloadAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/property': PropertyRepositoryService;
  }
}
