import {
  FilterId,
  FilterOptionsType,
  SingleSelectFilter,
} from 'uplisting-frontend/utils/filters/abstract';
import { ClientStatementStatus } from 'uplisting-frontend/models/schemas';

export class ClientStatementStatusFilter extends SingleSelectFilter<void> {
  id = FilterId.clientStatementStatus;
  optionsType = FilterOptionsType.list;
  optionsList = [ClientStatementStatus.draft, ClientStatementStatus.finalised];
}
