import Component from '@glimmer/component';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import ScheduledMessageModel from 'uplisting-frontend/models/scheduled-message';
import { action } from '@ember/object';
import { OccasionEvent } from 'uplisting-frontend/models/schemas';

interface IArgs {
  booking: BookingModel;
  scheduledMessage: ScheduledMessageModel;
}

export interface BookingScheduledMessageSignature {
  Element: HTMLTableElement;

  Args: IArgs;
}

export default class UiBookingScheduledMessageComponent extends Component<BookingScheduledMessageSignature> {
  @service notifications!: Services['notifications'];

  @cached
  get scheduledMessage(): ScheduledMessageModel {
    return this.args.scheduledMessage;
  }

  @cached
  get eventTime(): Date {
    const { booking } = this.args;

    switch (this.scheduledMessage.automatedMessageRule.event) {
      case OccasionEvent.booked:
        return booking.bookedAt;
      case OccasionEvent.enquired:
        return booking.createdAt;
      case OccasionEvent.arrived:
        return booking.checkInDate;
      case OccasionEvent.departed:
        return booking.checkOutDate;
    }
  }

  @action
  async handleToggleEnabled(): Promise<void> {
    const { scheduledMessage } = this;

    const enabled = scheduledMessage.enabled;

    scheduledMessage.enabled = !enabled;

    try {
      await scheduledMessage.save();

      this.notifications.success('notifications.applied');
    } catch {
      scheduledMessage.enabled = enabled;

      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::ScheduledMessage': typeof UiBookingScheduledMessageComponent;
  }
}
