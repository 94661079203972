import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import ChangeoverModel from 'uplisting-frontend/models/changeover';
import PropertyModel from 'uplisting-frontend/models/property';

interface IArgs {
  changeover: ChangeoverModel;
}

export interface ChangeoverGuestCardSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiChangeoverGuestCardComponent extends Component<ChangeoverGuestCardSignature> {
  @cached
  get changeover(): ChangeoverModel {
    return this.args.changeover;
  }

  @cached
  get property(): PropertyModel {
    return this.changeover.property;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Changeover::Guest::Card': typeof UiChangeoverGuestCardComponent;
  }
}
