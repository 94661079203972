import Transform from '@ember-data/serializer/transform';
import { formatDateQuery } from 'uplisting-frontend/utils';

export default class DayTransform extends Transform {
  deserialize(date?: string): Date | undefined {
    if (!date) {
      return;
    }

    return new Date(date);
  }

  serialize(date?: Date): string | undefined {
    if (!date) {
      return;
    }

    return formatDateQuery(date);
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    day: DayTransform;
  }
}
