import { validateNumber } from 'ember-changeset-validations/validators';

export const SecurityDepositSettingValidation = {
  amount: [
    validateNumber({
      gte: 0,
      integer: false,
    }),
  ],
};
