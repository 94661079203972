import Service from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import config from 'ember-get-config';
import * as filestack from 'filestack-js';

export default class FileHandlerService extends Service {
  @cached @tracked client!: filestack.Client;

  constructor() {
    super();

    this.client = filestack.init(config.filestackKey);
  }
}

declare module '@ember/service' {
  interface Registry {
    'file-handler': FileHandlerService;
  }
}
