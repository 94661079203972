import { validatePresence } from 'ember-changeset-validations/validators';
import { customMessageValidator } from 'uplisting-frontend/validators';

export const SavedReplyValidation = {
  name: [
    validatePresence({
      presence: true,
    }),
  ],

  content: [
    customMessageValidator(
      'saved_reply_content',
      validatePresence({
        presence: true,
      }),
    ),
  ],
};
