import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import NotificationSettingModel from 'uplisting-frontend/models/notification-setting';
import SeamConnectionModel from 'uplisting-frontend/models/seam-connection';
import { action } from '@ember/object';

interface INotificationSetting {
  property?: keyof NotificationSettingModel;
  value?: boolean;
  isDisabled?: boolean;
}

type NotificationSettings = Record<string, INotificationSetting[]>;

export default class AccountNotificationsController extends BaseController {
  @cached @tracked setting!: NotificationSettingModel;
  @cached @tracked seamConnections!: SeamConnectionModel[];

  @cached
  get canChangeGeneralSettings(): boolean {
    return this.currentUser.isAccountOwner;
  }

  @cached
  get canChangeChangeoverSettings(): boolean {
    return (
      this.currentUser.isHostOrAccountOwner || this.currentUser.isHousekeeper
    );
  }

  @cached
  get canChangeBookingSettings(): boolean {
    return this.currentUser.isHostOrAccountOwner;
  }

  @cached
  get canChangeMessageSettings(): boolean {
    return this.currentUser.isHostOrAccountOwner;
  }

  @cached
  get canChangeSmartLockSettings(): boolean {
    return (
      this.currentUser.isHostOrAccountOwner && !!this.seamConnections.length
    );
  }

  @cached
  get canChangeUpsellSettings(): boolean {
    return this.currentUser.hasAccessToPage('upsell_orders.index');
  }

  @cached
  get bookingNotifications(): NotificationSettings {
    const { intl } = this;

    return {
      [intl.t('account_notifications.tables.bookings.new')]: [
        {
          value: false,
          isDisabled: true,
        },
        {
          property: 'bookingCreatedBookingDotCom',
        },
        {
          property: 'bookingCreatedHomeaway',
        },
        {
          property: 'bookingCreatedDirect',
        },
      ],
      [intl.t('account_notifications.tables.bookings.modified')]: [
        {
          value: false,
          isDisabled: true,
        },
        {
          property: 'bookingUpdatedBookingDotCom',
        },
        {
          value: false,
          isDisabled: true,
        },
        {
          value: false,
          isDisabled: true,
        },
      ],
      [intl.t('account_notifications.tables.bookings.cancelled')]: [
        {
          value: false,
          isDisabled: true,
        },
        {
          property: 'bookingCancelledBookingDotCom',
        },
        {
          value: false,
          isDisabled: true,
        },
        {
          value: false,
          isDisabled: true,
        },
      ],
    };
  }

  @cached
  get messageNotifications(): NotificationSettings {
    const { intl } = this;

    return {
      [intl.t('account_notifications.tables.guest_message.new')]: [
        {
          value: false,
          isDisabled: true,
        },
        {
          value: false,
          isDisabled: true,
        },
        {
          property: 'guestMessageHomeaway',
        },
        {
          property: 'guestMessageUplisting',
        },
      ],
    };
  }

  @action
  computeSettingFieldEnabled(field: string): boolean {
    return this.setting[field] ?? false;
  }

  @action
  async handleToggleSetting(field?: string): Promise<void> {
    if (!field) {
      return;
    }

    const { setting } = this;

    setting[field] = !setting[field];

    try {
      setting.save();

      this.notifications.info('account_notifications.notifications.saved');
    } catch {
      this.notifications.error();
    }
  }
}
