import BaseRoute from 'uplisting-frontend/pods/base/route';
import UserModel from 'uplisting-frontend/models/user';
import { waitForProperty } from 'ember-concurrency';
import config from 'ember-get-config';
import Transition from '@ember/routing/transition';
import { isRouteForbiddenWhenLapsed } from 'uplisting-frontend/router';

export default class PermittedRoute extends BaseRoute {
  permission!: string;

  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    if (config.IS_TEST) {
      return;
    }

    if (!this.permission) {
      throw new Error('You must define `permission` property');
    }

    await waitForProperty(
      this.session,
      'currentUser',
      (item) => item instanceof UserModel,
    );

    const { currentUser } = this.session;

    if (!currentUser.hasAccessToPage(this.permission)) {
      transition.abort();

      window.open('/', '_self');
    }

    if (
      currentUser.lapsed &&
      isRouteForbiddenWhenLapsed(transition.targetName)
    ) {
      await transition.abort();
    }
  }
}
