import Component from '@glimmer/component';
import AutomatedReviewTemplateModel from 'uplisting-frontend/models/automated-review-template';
import { cached, tracked } from '@glimmer/tracking';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IArgs {
  model: AutomatedReviewTemplateModel;
  isDisabled: boolean;
  onReloadAccountConfiguration(): Promise<void>;
}

interface AutomateAutomatedReviewTemplateRowSignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiAutomateAutomatedReviewTemplateRowComponent extends Component<AutomateAutomatedReviewTemplateRowSignature> {
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];

  @service('repositories/automated-review-template')
  automatedReviewTemplateRepository!: Services['repositories/automated-review-template'];

  @cached @tracked isEditing = false;

  @cached
  get changeset(): GenericChangeset<AutomatedReviewTemplateModel> {
    return this.automatedReviewTemplateRepository.buildChangeset(
      this.args.model,
    );
  }

  @action
  handleCloseEdit(): void {
    this.isEditing = false;

    this.changeset.rollback();
  }

  @action
  async handleToggleEnabled(): Promise<void> {
    this.changeset.enabled = !this.changeset.enabled;

    await this.handleSave();
  }

  @action
  async handleSave(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifySaved();
    } catch (error: any) {
      if (error?.isAdapterError) {
        this.notifications.error();
      } else {
        this.notifySaved();
      }
    }
  }

  private notifySaved(): void {
    this.args.onReloadAccountConfiguration();
    this.analytics.trackEvent(AnalyticsEvents.automatedReviewTemplateEdit);
    this.notifications.info('notifications.applied');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::AutomatedReviewTemplate::Row': typeof UiAutomateAutomatedReviewTemplateRowComponent;
  }
}
