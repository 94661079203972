import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  date: string;
}

interface ActionTableHeadingSignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiActionTableHeadingComponent extends Component<ActionTableHeadingSignature> {
  @cached
  get date(): string {
    return this.args.date;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Heading': typeof UiActionTableHeadingComponent;
  }
}
