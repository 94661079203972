import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { action } from '@ember/object';
import sum from 'lodash-es/sum';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import PaymentRuleModel from 'uplisting-frontend/models/payment-rule';
import CustomMessageTagModel from 'uplisting-frontend/models/custom-message-tag';
import CustomPropertyAttributeModel from 'uplisting-frontend/models/custom-property-attribute';

type Model =
  | PaymentRuleModel
  | CustomMessageTagModel
  | CustomPropertyAttributeModel;

interface IArgs<T extends Model, K> {
  model: T;
  colSizes: K;
}

interface AutomateCustomPropertyAttributeRowSignature<T extends Model, K> {
  Element: HTMLTableRowElement;

  Args: IArgs<T, K>;
}

export abstract class UiAutomateEditableRowComponent<
  T extends Model,
  K,
> extends Component<AutomateCustomPropertyAttributeRowSignature<T, K>> {
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];

  abstract repository:
    | Services['repositories/payment-rule']
    | Services['repositories/custom-message-tag']
    | Services['repositories/custom-property-attribute'];

  abstract eventOnUpdate: AnalyticsEvents;

  @cached @tracked isEditing = false;
  @cached @tracked showDeleteModal = false;

  @cached
  get model(): T {
    return this.args.model;
  }

  @cached
  get formColSize(): number {
    return sum(Object.values(this.args.colSizes as object));
  }

  @cached
  get isNewOrEditing(): boolean {
    return this.isEditing || !this.model.isPersisted;
  }

  @cached
  get changeset(): GenericChangeset<T> {
    // @ts-expect-error - don't know how to manage that properly in the abstract class
    return this.repository.buildChangeset(this.model);
  }

  @action
  handleCloseEdit(): void {
    this.isEditing = false;

    this.changeset.rollback();

    if (!this.model.isPersisted) {
      this.model.unloadRecord();
    }
  }

  @action
  async handleDelete(): Promise<void> {
    await this.model.destroyRecord();
  }

  @action
  async handleSave(): Promise<void> {
    await this.changeset.validate();

    if (this.changeset.isInvalid) {
      return;
    }

    try {
      await this.changeset.save();

      this.analytics.trackEvent(this.eventOnUpdate);
      this.notifications.info('notifications.applied');
    } catch {
      this.notifications.error();
    }
  }
}
