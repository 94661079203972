import Component from '@glimmer/component';
import UserModel from 'uplisting-frontend/models/user';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached } from '@glimmer/tracking';

interface SidebarSignature {
  Element: HTMLUListElement;
}

export default class UiSidebarComponent extends Component<SidebarSignature> {
  @service session!: Services['session'];

  @cached
  get currentUser(): UserModel {
    return this.session.currentUser;
  }

  @cached
  get showUnreadMessagesCount(): boolean {
    return this.currentUser.unreadMessages > 0;
  }

  @cached
  get unreadMessages(): number | string {
    const { unreadMessages } = this.currentUser;

    if (unreadMessages >= 500) {
      return '500+';
    }

    return unreadMessages;
  }

  @cached
  get pendingAlerts(): number | string {
    const { pendingAlerts } = this.currentUser;

    if (pendingAlerts >= 500) {
      return '500+';
    }

    return pendingAlerts;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Sidebar': typeof UiSidebarComponent;
  }
}
