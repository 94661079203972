import Model, { belongsTo } from '@ember-data/model';
import { IAdvancedReportSchema } from 'uplisting-frontend/models/schemas';
import AdvancedReportConfigurationModel from 'uplisting-frontend/models/advanced-report-configuration';

export default class AdvancedReportModel
  extends Model
  implements IAdvancedReportSchema
{
  @belongsTo('advanced-report-configuration', {
    inverse: null,
    async: false,
  })
  advancedReportConfiguration!: AdvancedReportConfigurationModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'advanced-report': AdvancedReportModel;
  }
}
