import ApplicationSerializer from 'uplisting-frontend/serializers/application';
import DS, { ModelSchema } from 'ember-data';

export default class ChangeoverSerializer extends ApplicationSerializer {
  normalize(typeClass: ModelSchema, hash: object) {
    const data = super.normalize(typeClass, hash);

    // We want to preserve the original teammate id, so that later we can enable `save` button
    // only when the user has changed the teammate
    // as well as show the original teammate name in the `/action` page table
    // until new teammate is saved
    // @ts-expect-error - we have no typings for the json api
    if (data.data.relationships?.teammate?.data) {
      // @ts-expect-error - we have no typings for the json api
      data.data.attributes.persistedTeammateId =
        // @ts-expect-error - we have no typings for the json api
        data.data.relationships.teammate.data.id;
    }

    return data;
  }

  serialize(snapshot: DS.Snapshot, options: object) {
    const data = super.serialize(snapshot, options);

    // remove FE custom attribute
    // @ts-expect-error - we have no typings for the json api
    delete data.data.attributes.persisted_teammate_id;

    return data;
  }
}

declare module 'ember-data/types/registries/serializer' {
  interface SerializerRegistry {
    changeover: ChangeoverSerializer;
  }
}
