import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { timezones } from 'uplisting-frontend/utils';

interface IArgs {
  collapsible?: boolean;
  timezone: string;
  onTimeZoneSelect(timezone: string): void;
}

interface TimeZoneSelectorSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiTimeZoneSelectorComponent extends Component<TimeZoneSelectorSignature> {
  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  timezones = timezones;

  @cached @tracked timezone!: string;

  handleSetTimezone = modifier(() => {
    this.timezone =
      this.args.timezone ??
      this.accountConfigurationRepository.currentAccountConfiguration.timezone;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::TimeZoneSelector': typeof UiTimeZoneSelectorComponent;
  }
}
