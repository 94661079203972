import { underscore } from '@ember/string';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import config from 'ember-get-config';
import ModelRegistry from 'ember-data/types/registries/model';
import { pluralize } from 'ember-inflector';
import { cached } from '@glimmer/tracking';
import {
  storage,
  StorageKey,
  ISessionData,
} from 'uplisting-frontend/utils/storage';
import { type Registry as Services, inject as service } from '@ember/service';
import DS from 'ember-data';

interface IHeaders {
  Authorization: string;
}

export type IAdapterOptions = Record<string, string | string[]>;

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service session!: Services['session'];

  host = config.API_HOST;
  namespace = 'v2';

  @cached
  get headers(): IHeaders | object {
    const data = storage.local.get<ISessionData>(StorageKey.SESSION);

    const token = (data as ISessionData)?.authenticated?.idToken;

    if (!token) {
      this.session.handleLogout();

      return {};
    }

    return {
      Authorization: `Bearer ${token}`,
    };
  }

  pathForType<K extends keyof ModelRegistry>(modelName: K): string {
    return underscore(pluralize(modelName as string));
  }

  public handleResponse(
    status: number,
    headers: object,
    payload: object,
    requestData: object,
  ): object {
    if (status === 401) {
      this.session.handleLogout();

      return {
        status: 401,
      };
    } else if (status === 403) {
      window.open('/calendar', '_self');
    }

    return super.handleResponse(status, headers, payload, requestData);
  }

  urlForFindRecord<K extends keyof ModelRegistry>(
    id: string,
    modelName: K,
    snapshot: DS.Snapshot<K>,
  ): string {
    const url = super.urlForFindRecord(id, modelName, snapshot);

    if (snapshot.adapterOptions) {
      const queryParams = this.formatQueryParams(
        snapshot.adapterOptions as IAdapterOptions,
      );

      return `${url}?${queryParams}`;
    }

    return url;
  }

  private formatQueryParams(adapterOptions: IAdapterOptions): string {
    return Object.entries(adapterOptions)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((item) => `${key}[]=${item}`).join('&');
        }

        return `${key}=${value}`;
      })
      .join('&');
  }
}

declare module 'ember-data/types/registries/adapter' {
  interface AdapterRegistry {
    application: ApplicationAdapter;
  }
}
