export enum SecurityDepositPer {
  booking = 'booking',
  property = 'property',
}

export interface IAccountConfigurationSchema {
  defaultAirbnbPriceSyncEnabled: boolean;
  defaultSecurityDepositAmount: number;
  defaultSecurityDepositEnabled: boolean;
  securityDepositsEnabledUplisting: boolean;
  securityDepositsEnabledBookingDotCom: boolean;
  securityDepositsEnabledGoogle: boolean;
  securityDepositsEnabledHomeaway: boolean;
  securityDepositsEnabledAirbnbOfficial: boolean;
  securityDepositPer: SecurityDepositPer;
  automatedReviewsEnabled: boolean;
  automatedReviewsDelay: number;
  displayName: string;
  bookingDotComPaymentsEnabled: boolean;
  lockCodeLength: number;
  clientManagementEnabled: boolean;
  timezone: string;
  smartLockCheckInBuffer: number;
  smartLockCheckOutBuffer: number;
}
