import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import RSVP from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import AutomateIdentityController from 'uplisting-frontend/pods/automate/identity/controller';
import Transition from '@ember/routing/transition';

export default class AutomateIdentityRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/guest-identity-verification-configuration')
  guestIdentityVerificationConfigurationRepository!: Services['repositories/guest-identity-verification-configuration'];

  permission = 'automate.identity';

  model() {
    return RSVP.hash({
      properties: this.propertyRepository.findAll(),
      configuration:
        this.guestIdentityVerificationConfigurationRepository.getDefaultRecord(),
    });
  }

  setupController(
    controller: AutomateIdentityController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);

    controller.setDefaultState();
  }

  resetController(): void {
    this.guestIdentityVerificationConfigurationRepository.unloadAll();
  }
}
