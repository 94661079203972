import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';

interface IArgs {
  text: string;
  textToCopy: string;
  messageOnSuccess: string;
  type?: 'light';
  disabled?: boolean;
  withIcon?: false;
}

interface CopyButtonSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiCopyButtonComponent extends Component<CopyButtonSignature> {
  @service notifications!: Services['notifications'];

  @cached
  get disabled(): boolean {
    return this.args.disabled ?? false;
  }

  @cached
  get type(): string {
    return this.args.type ?? 'secondary';
  }

  @cached
  get withIcon(): boolean {
    return this.args.withIcon ?? true;
  }

  @action
  async handleClick(): Promise<void> {
    await navigator.clipboard.writeText(this.args.textToCopy);

    this.notifications.success(this.args.messageOnSuccess);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::CopyButton': typeof UiCopyButtonComponent;
  }
}
