import Component from '@glimmer/component';
import TeammateModel from 'uplisting-frontend/models/teammate';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { UserRoles, InvitationRole } from 'uplisting-frontend/models/schemas';
import { IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import { HasManyState } from 'uplisting-frontend/models/helpers';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';

interface IArgs {
  teammate: TeammateModel;
  isDisabled?: boolean;
  onRemove(teammate: TeammateModel): void;
  onRoleSelect(role: InvitationRole, teammate: TeammateModel): void;
  onUpdate(teammate: TeammateModel): Promise<void>;
}

interface TeammateRowSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const ALL_ROLES: InvitationRole[] = [UserRoles.housekeeper, UserRoles.host];

export default class UiTeammateRowComponent extends Component<TeammateRowSignature> {
  @cached @tracked isEditing = false;

  hasManyPropertiesState!: HasManyState<TeammateModel, PropertyModel>;
  hasManyMultiUnitState!: HasManyState<TeammateModel, MultiUnitModel>;

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);

    this.setHasManyState();
  }

  @cached
  get teammate(): TeammateModel {
    return this.args.teammate;
  }

  @cached
  get isDisabled(): boolean {
    return this.args.isDisabled ?? false;
  }

  @cached
  get availableRoles(): InvitationRole[] {
    return ALL_ROLES.filter((role) => this.teammate.role !== role);
  }

  @cached
  get isCollapsed(): boolean {
    return !this.isEditing;
  }

  @action
  handleRoleSelect(role: InvitationRole, dd: IDropdown): void {
    dd.closeDropdown();

    this.args.onRoleSelect(role, this.teammate);
  }

  @action
  handleEdit(): void {
    this.isEditing = true;
  }

  @action
  handleCancel(resetChanges = true): void {
    this.isEditing = false;

    if (resetChanges) {
      this.teammate.setProperties({
        properties: this.hasManyPropertiesState.originalRecords,
        multiUnits: this.hasManyMultiUnitState.originalRecords,
      });
    }
  }

  @action
  async handleUpdate(): Promise<void> {
    await this.args.onUpdate(this.teammate);

    this.handleCancel(false);
    this.setHasManyState();
  }

  private setHasManyState(): void {
    this.hasManyPropertiesState = new HasManyState(this.teammate, 'properties');
    this.hasManyMultiUnitState = new HasManyState(this.teammate, 'multiUnits');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Teammate::Row': typeof UiTeammateRowComponent;
  }
}
