import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ChangeoverModel from 'uplisting-frontend/models/changeover';

export default class ChangeoverRepositoryService extends BaseRepositoryService<ChangeoverModel> {
  recordName = 'changeover';
  implementMethods = ['findRecord'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/changeover': ChangeoverRepositoryService;
  }
}
