import TaxModel from 'uplisting-frontend/models/tax';
import UserModel from 'uplisting-frontend/models/user';
import PermissionModel from 'uplisting-frontend/models/permission';
import ClientPropertyModel from 'uplisting-frontend/models/client-property';

export enum ClientStatus {
  active = 'active',
  archived = 'archived',
}

export interface IClientSchema {
  name: string;
  email: string;
  // Leaving those commented out for now, as they are needed in terms of the future UI stuff, but not yet implemented on the BE
  // companyName: string;
  // avatar: string;
  // phoneNumber: string;
  status: ClientStatus;
  addressStreet: string;
  addressSuite: string;
  addressCity: string;
  addressCountry: string;
  addressZipCode: string;
  taxNumber: string;

  users: UserModel[];
  permissions: PermissionModel[];
  clientProperties: ClientPropertyModel[];
  tax?: TaxModel;
}
