import Component from '@glimmer/component';

interface IArgs {
  text?: string;
}

interface EmptyComponentSignature {
  Element: null;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiEmptyComponentSignature extends Component<EmptyComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'empty-component': typeof UiEmptyComponentSignature;
  }
}
