import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { IPriceSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import ExtraPriceModel from 'uplisting-frontend/models/extra-price';
import { cached } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';

export default class PriceModel extends Model implements IPriceSchema {
  @attr('number') hostEarnings!: number;
  @attr('number') guestPrice!: number;
  @attr('number') numberOfGuests!: number;
  @attr('string') currency!: string;

  @belongsTo('booking', { async: false, inverse: 'price' })
  booking!: BookingModel;

  @hasMany('extra-price', { async: false, inverse: null })
  discounts!: ExtraPriceModel[];

  @cached
  get visible(): boolean {
    return isPresent(this.hostEarnings) || this.guestPricePresent;
  }

  @cached
  get guestPricePresent(): boolean {
    const { guestPrice } = this;

    return isPresent(guestPrice) && guestPrice > 0;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    price: PriceModel;
  }
}
