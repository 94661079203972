import Component from '@glimmer/component';

export interface INavTab<T> {
  id: T;
  name: string;
}

interface IArgs<T> {
  tabs: INavTab<T>[];
  activeTab?: T;
  onClick(item: INavTab<T>): void;
}

interface NavTabsSignature<T> {
  Element: HTMLDivElement;

  Args: IArgs<T>;
}

export default class UiNavTabsComponent<T extends string> extends Component<
  NavTabsSignature<T>
> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::NavTabs': typeof UiNavTabsComponent;
  }
}
