import PermittedActionRoute from 'uplisting-frontend/pods/base/permitted-action-route';
import ActionChangeoversHostController from 'uplisting-frontend/pods/action/changeovers/host/controller';
import Transition from '@ember/routing/-private/transition';

export default class ActionChangeoversHostRoute extends PermittedActionRoute {
  actionPermission = 'changeovers.viewHostInfo';

  model() {
    return this.modelFor('action.changeovers');
  }

  setupController(
    controller: ActionChangeoversHostController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { changeover: model });
  }

  resetController(controller: ActionChangeoversHostController): void {
    controller.changeover.rollbackAttributes();
  }
}
