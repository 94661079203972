import * as Sentry from '@sentry/ember';
import config from 'ember-get-config';

export function startSentry(): void {
  Sentry.init({
    dsn: config.IS_PROD ? config.sentry.dsn : undefined,
    integrations: [Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.001, // Capture 0.1% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/app\.staging-uplisting\.io/,
      /^https:\/\/app\.uplisting\.io/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    environment: config.environment,
    release: config.sentry.release,
    allowUrls: [
      config.assetsHost,
      /^https:\/\/app\.staging-uplisting\.io/,
      /^https:\/\/app\.uplisting\.io/,
    ],
  });
}
