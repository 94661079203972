import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  translationKey: string;
  logo?: string;
  description?: boolean;
  faqLink?: string;
}

interface ConnectHeaderSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    icons: [];
    description: [];
  };
}

export default class UiConnectHeaderComponent extends Component<ConnectHeaderSignature> {
  @cached
  get description(): boolean {
    return this.args.description ?? true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connect::Header': typeof UiConnectHeaderComponent;
  }
}
