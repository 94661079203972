import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import MessageModel from 'uplisting-frontend/models/message';
import { MessageValidation } from 'uplisting-frontend/validations';

export default class MessageRepositoryService extends BaseRepositoryService<MessageModel> {
  recordName = 'message';
  implementMethods = ['createRecord', 'buildChangeset'];

  validation = MessageValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/message': MessageRepositoryService;
  }
}
