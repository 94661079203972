import { validatePresence } from 'ember-changeset-validations/validators';
import { customMessageValidator } from 'uplisting-frontend/validators';

export const AutomatedReviewTemplateValidation = {
  content: [
    customMessageValidator(
      'automated_review_template_content',
      validatePresence({ presence: true }),
    ),
  ],
};
