import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import RSVP from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import AutomateBookingWindowsController from 'uplisting-frontend/pods/automate/booking-windows/controller';
import Transition from '@ember/routing/transition';

export default class AutomateBookingWindowsRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/booking-window-rule')
  bookingWindowRuleRepository!: Services['repositories/booking-window-rule'];

  permission = 'automate.booking_windows';

  model() {
    return RSVP.hash({
      properties: this.propertyRepository.findAll(),
      windowRules: this.bookingWindowRuleRepository.findAll(),
    });
  }

  setupController(
    controller: AutomateBookingWindowsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { windowRules: model.windowRules.slice() });
  }

  resetController(): void {
    this.bookingWindowRuleRepository.unloadAll();
  }
}
