import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';

interface IArgs {
  open: boolean;
  title: string;
  body?: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  disabled?: boolean;
  subTitle?: string;
  onSubmit?(): void;
  onCancel?(): void;
}

interface ModalSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default?: [];
  };
}

export default class UiModalComponent extends Component<ModalSignature> {
  @cached
  get title(): string {
    return this.args.title;
  }

  @cached
  get body(): string | undefined {
    return this.args.body;
  }

  @cached
  get cancelButtonText(): string | undefined {
    return this.args.cancelButtonText;
  }

  @cached
  get submitButtonText(): string | undefined {
    return this.args.submitButtonText;
  }

  @cached
  get disabled(): boolean {
    return this.args.disabled ?? false;
  }

  @action
  handleSubmit(close: () => void): void {
    this.args.onSubmit?.();

    close();
  }

  @action
  handleCancel(close?: () => void): void {
    this.args.onCancel?.();

    close?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Modal': typeof UiModalComponent;
  }
}
