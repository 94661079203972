import BaseRoute from 'uplisting-frontend/pods/base/route';
import RSVP from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import AccountNotificationsController from 'uplisting-frontend/pods/account/notifications/controller';
import Transition from '@ember/routing/transition';

export default class AccountNotificationsRoute extends BaseRoute {
  @service('repositories/seam-connection')
  seamConnectionRepositoryService!: Services['repositories/seam-connection'];

  model() {
    const hasAccessToSmartLocks = this.session.currentUser.hasAccessToPage(
      'connect.smart_locks',
    );

    return RSVP.hash({
      setting: this.store.findRecord('notification-setting', 'me'),
      seamConnections: hasAccessToSmartLocks
        ? this.seamConnectionRepositoryService.findAll()
        : [],
    });
  }

  setupController(
    controller: AccountNotificationsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }

  resetController(): void {
    this.seamConnectionRepositoryService.unloadAll();
  }
}
