import ReviewStatisticCategoryModel from 'uplisting-frontend/models/review-statistic-category';

export enum AirbnbNegativeTag {
  most_common_negative_tag = 'GUEST_REVIEW_HOST_NEGATIVE_SMELL',
}

export enum AirbnbPositiveTag {
  most_common_positive_tag = 'GUEST_REVIEW_HOST_POSITIVE_HAD_LISTED_AMENITIES_AND_SERVICES',
}

export interface IReviewStatisticSchema {
  mostCommonNegativeTag?: AirbnbNegativeTag;
  mostCommonPositiveTag?: AirbnbPositiveTag;

  categories: ReviewStatisticCategoryModel[];
}
