import { formatDateQuery } from 'uplisting-frontend/utils';
import { typeOf } from '@ember/utils';
import { formatInTimeZone } from 'date-fns-tz';
import { addMinutes } from 'date-fns/addMinutes';
import { subMinutes } from 'date-fns/subMinutes';

export function deserializeDateQuery(qp: string | Date): Date | undefined {
  if (!qp) {
    return;
  }

  if (qp instanceof Date) {
    return qp;
  }

  const date = new Date(qp);

  let dateCopy = new Date(date.getTime());

  const offset = date.getTimezoneOffset();

  if (offset >= 0) {
    dateCopy = addMinutes(date, offset);
  } else {
    dateCopy = subMinutes(date, offset);
  }

  return dateCopy;
}

export function serializeDateQuery(qp: string | Date): string {
  if (typeof qp === 'string') {
    return qp;
  }

  return formatDateQuery(qp);
}

function basicArray(array: unknown[]) {
  if (typeOf(array) !== 'array') {
    return false;
  }

  return array.every((value) => ['string', 'number'].includes(typeOf(value)));
}

export function serializeArrayQueryParam(
  value: unknown,
  _urlKey: string,
  defaultValueType: string,
) {
  if (defaultValueType === 'array' && basicArray(value as unknown[])) {
    return (value as unknown[]).slice();
  }
}

export function deserializeArrayQueryParam(
  value: unknown[],
  _urlKey: string,
  defaultValueType: string,
) {
  if (defaultValueType === 'array' && basicArray(value)) {
    return value.slice();
  }
}

export const QUERY_DATE_FORMAT = 'yyyy-MM-dd';

export function dateToQuery(date: Date | undefined): string | undefined {
  if (date instanceof Date) {
    return formatInTimeZone(date, 'Etc/UTC', QUERY_DATE_FORMAT);
  }
}
