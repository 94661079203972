import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import PromotionModel from 'uplisting-frontend/models/promotion';
import { PromotionValidation } from 'uplisting-frontend/validations';

export default class PromotionRepositoryService extends BaseRepositoryService<PromotionModel> {
  recordName = 'promotion';
  implementMethods = [
    'createRecord',
    'findAll',
    'peekAll',
    'buildChangeset',
    'unloadAll',
  ];

  validation = PromotionValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/promotion': PromotionRepositoryService;
  }
}
