import { cached, tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';

export class StateMachine {
  @cached @tracked states!: string[];
  @cached @tracked activeState!: string;

  private activeIndex = 0;

  constructor(states: string[]) {
    assert('States should be an array of strings', Array.isArray(states));

    this.states = states;
    this.activeState = states[this.activeIndex] as string;
  }

  @cached
  get isOnTheLastStep(): boolean {
    return this.activeState === this.states[this.states.length - 1];
  }

  @cached
  get isOnTheFirstStep(): boolean {
    return this.activeState === this.states[0];
  }

  public reset(): void {
    this.activeState = this.states[0] as string;
    this.activeIndex = 0;
  }

  public goToNextStep(): void {
    if (this.activeIndex + 1 >= this.states.length) {
      return;
    }

    ++this.activeIndex;

    this.activeState = this.states[this.activeIndex] as string;
  }

  public goToPrevStep(): void {
    if (this.activeIndex - 1 < 0) {
      return;
    }

    --this.activeIndex;

    this.activeState = this.states[this.activeIndex] as string;
  }

  public is(step: string): boolean {
    return this.activeState === step;
  }
}
