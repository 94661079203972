import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { IEdgestateLockableSchema } from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import EdgestateConnectionModel from 'uplisting-frontend/models/edgestate-connection';

export default class EdgestateLockableModel
  extends Model
  implements IEdgestateLockableSchema
{
  @attr('string') name!: string;
  @attr('string') edgestateResourceType!: string;

  @belongsTo('property', { async: false, inverse: null })
  property!: PropertyModel | null;

  @belongsTo('multi-unit', { async: false, inverse: null })
  multiUnit!: MultiUnitModel | null;

  @belongsTo('edgestate-connection', { async: false, inverse: null })
  edgestateConnection!: EdgestateConnectionModel;

  @cached
  get resourceType(): string {
    return this.edgestateResourceType.replace(/_/g, ' ');
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'edgestate-lockable': EdgestateLockableModel;
  }
}
