import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import PropertyModel from 'uplisting-frontend/models/property';
import DirectListingModel from 'uplisting-frontend/models/direct-listing';
import { DirectBookingTypes } from 'uplisting-frontend/models/schemas';

interface IArgs {
  listing: DirectListingModel;
}

interface ListingSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiListingComponent extends Component<ListingSignature> {
  @service store!: Services['store'];
  @service notifications!: Services['notifications'];

  @cached
  get listing(): DirectListingModel {
    return this.args.listing;
  }

  @cached
  get baseRateEmpty(): boolean {
    return !this.listing.baseRate?.id;
  }

  @action
  async toggleShowOnDirectBookingEngine(): Promise<void> {
    const property = await this.findListingProperty(this.listing.id);

    if (!property) {
      return;
    }

    property.showOnDirectBookingEngine = !property.showOnDirectBookingEngine;

    try {
      const result = await property.save();

      const { showOnDirectBookingEngine } = result;

      this.listing.showOnDirectBookingEngine = showOnDirectBookingEngine;
      this.notifications.info(
        `direct_listing.notifications.${showOnDirectBookingEngine ? 'visible_on_direct_booking' : 'hidden_on_direct_booking'}`,
      );
      // eslint-disable-next-line no-empty
    } catch {}
  }

  @action
  async toggleDirectBookingType(): Promise<void> {
    const property = await this.findListingProperty(this.listing.id);

    if (!property) {
      return;
    }

    const newDirectBookingType = property.instantBookable
      ? DirectBookingTypes.enquiry
      : DirectBookingTypes.instantBook;

    property.directBookingType = newDirectBookingType;

    try {
      const result = await property.save();

      this.listing.directBookingType = result.directBookingType;
      this.notifications.info(
        `direct_listing.notifications.${result.instantBookable ? 'instant_book' : 'request_to_book'}`,
      );
      // eslint-disable-next-line no-empty
    } catch {}
  }

  private async findListingProperty(
    id: string,
  ): Promise<PropertyModel | undefined> {
    try {
      return await this.store.findRecord('property', id);
    } catch {
      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Direct::Listing': typeof UiListingComponent;
  }
}
