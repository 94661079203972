import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import ZapierApiKeyModel from 'uplisting-frontend/models/zapier-api-key';
import PartnerApiKeyModel from 'uplisting-frontend/models/partner-api-key';
import { action } from '@ember/object';

type ApiKeyModel = ZapierApiKeyModel | PartnerApiKeyModel;

interface IArgs {
  apiKey: ApiKeyModel;
  translationKey: string;
  modelField: keyof ZapierApiKeyModel | keyof PartnerApiKeyModel;
  logo?: string;
  faqLink?: string;
}

interface ConnectApiKeySignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    icons: [];
    main: [];
  };
}

export default class UiConnectApiKeyComponent extends Component<ConnectApiKeySignature> {
  @service notifications!: Services['notifications'];

  @cached @tracked isSaving = false;

  @cached
  get translationKey(): string {
    return this.args.translationKey;
  }

  @cached
  get apiKey(): ApiKeyModel {
    return this.args.apiKey;
  }

  @cached
  get key(): string {
    return this.apiKey[this.args.modelField] as string;
  }

  @cached
  get isApiKeyGenerated(): boolean {
    return !!this.apiKey.id;
  }

  @action
  async handleSubmit(): Promise<void> {
    if (this.isApiKeyGenerated) {
      await this.copyKey();
    } else {
      await this.createKey();
    }
  }

  private async copyKey(): Promise<void> {
    await navigator.clipboard.writeText(this.key);

    this.notifications.info(`${this.translationKey}.alert.key_copied`);
  }

  private async createKey(): Promise<void> {
    this.isSaving = true;

    try {
      await this.apiKey.save();

      this.notifications.info(`${this.translationKey}.alert.key_generated`);
    } catch {
      this.notifications.error();
    } finally {
      this.isSaving = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connect::ApiKey': typeof UiConnectApiKeyComponent;
  }
}
