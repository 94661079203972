import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import GuestIdentityImageModel from 'uplisting-frontend/models/guest-identity-image';

export default class GuestIdentityImageRepositoryService extends BaseRepositoryService<GuestIdentityImageModel> {
  recordName = 'guest-identity-image';
  implementMethods = ['query'];

  public getRecords(id: string) {
    return super.query(
      {
        guest_identity_verification_id: id,
      },
      {
        adapterOptions: {
          reload: true,
        },
      },
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/guest-identity-image': GuestIdentityImageRepositoryService;
  }
}
