import Model, { attr } from '@ember-data/model';
import { IGuestIdentityVerificationConfigurationSchema } from 'uplisting-frontend/models/schemas';
import { cached } from '@glimmer/tracking';

export default class GuestIdentityVerificationConfigurationModel
  extends Model
  implements IGuestIdentityVerificationConfigurationSchema
{
  @attr('boolean', { defaultValue: false }) airbnb!: boolean;
  @attr('boolean', { defaultValue: false }) bookingDotCom!: boolean;
  @attr('boolean', { defaultValue: false }) homeAway!: boolean;
  @attr('boolean', { defaultValue: false }) uplisting!: boolean;
  @attr('boolean', { defaultValue: false }) discounted!: boolean;

  @cached
  get isEnabled(): boolean {
    return this.airbnb || this.bookingDotCom || this.homeAway || this.uplisting;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'guest-identity-verification-configuration': GuestIdentityVerificationConfigurationModel;
  }
}
