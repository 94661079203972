import Component from '@glimmer/component';
import BookingModel from 'uplisting-frontend/models/booking';
import MessageModel from 'uplisting-frontend/models/message';
import { cached } from '@glimmer/tracking';

interface IArgs {
  booking: BookingModel;
  message: MessageModel;
  saveReplyRoute: string;
}

export interface BookingMessagesMessageOutboundSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingMessagesMessageOutboundComponent extends Component<BookingMessagesMessageOutboundSignature> {
  @cached
  get message(): MessageModel {
    return this.args.message;
  }

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @cached
  get isSaveable(): boolean {
    return this.args.booking.canSendMessage && !!this.message.content;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/booking/messages/message/outbound': typeof UiBookingMessagesMessageOutboundComponent;
    'Ui::Booking::Messages::Message::Outbound': typeof UiBookingMessagesMessageOutboundComponent;
  }
}
