import Model, { attr } from '@ember-data/model';
import { IGuestIdentityImageSchema } from 'uplisting-frontend/models/schemas';
import { cached } from '@glimmer/tracking';

export default class GuestIdentityImageModel
  extends Model
  implements IGuestIdentityImageSchema
{
  @attr('string') url!: string;
  @attr('string') side!: string;

  @cached
  get guestIdentityVerificationId(): string {
    return this.id.split('-')[0] as string;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'guest-identity-image': GuestIdentityImageModel;
  }
}
