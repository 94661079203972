export default {
  "paywall": "_paywall_1xpn51",
  "multiple": "_multiple_1xpn51",
  "paywall-title": "_paywall-title_1xpn51",
  "plan-includes": "_plan-includes_1xpn51",
  "paywall-body": "_paywall-body_1xpn51",
  "paywall-body-left": "_paywall-body-left_1xpn51",
  "paywall-price": "_paywall-price_1xpn51",
  "price-amount": "_price-amount_1xpn51",
  "paywall-actions": "_paywall-actions_1xpn51",
  "paywall-description": "_paywall-description_1xpn51",
  "paywall-description-after": "_paywall-description-after_1xpn51",
  "paywall-image": "_paywall-image_1xpn51"
};
