/**
 * @description Insert text value into current cursor position
 *
 * @param position current cursor position, which can be a number(index) or null(when cursor has no position)
 * @param value new value to be inserted
 * @param content current input content
 */
export function insertAtCursor(
  position: null | number,
  value: string,
  content: string,
): string {
  if (position !== null) {
    return content.slice(0, position) + value + content.slice(position);
  }

  return value;
}
