import BaseRoute from 'uplisting-frontend/pods/base/route';
import Transition from '@ember/routing/transition';
import UpsellsNewController from 'uplisting-frontend/pods/upsells/new/controller';
import { type Registry as Services, inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class UpsellsIndexRoute extends BaseRoute {
  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  @service('repositories/upsell')
  upsellRepository!: Services['repositories/upsell'];

  model() {
    return RSVP.hash({
      upsells: this.upsellRepository.findAll(),
      properties: this.modelFor('upsells'),
      taxes: this.taxRepository.findAll(),
    });
  }

  setupController(
    controller: UpsellsNewController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, {
      properties: model.properties.slice(),
    });

    controller.buildChangeset();
  }

  resetController(controller: UpsellsNewController) {
    controller.stateMachine.reset();

    if (!controller.changeset.id) {
      controller.changeset.rollback();
    }
  }
}
