import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import PropertyModel from 'uplisting-frontend/models/property';
import BookingModel from 'uplisting-frontend/models/booking';
import CalendarModel from 'uplisting-frontend/models/calendar';
import { type Registry as Services, inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { scheduleTask } from 'ember-lifeline';

export default class CalendarController extends BaseController {
  @service('repositories/calendar')
  calendarRepository!: Services['repositories/calendar'];

  tableId = 'calendar-table';

  @cached @tracked bookings: BookingModel[] = [];
  @cached @tracked properties!: PropertyModel[];

  activeObserver!: IntersectionObserver;
  observerElements!: [Element, Element];

  handleTableInsert = modifier(() => {
    scheduleTask(this, 'render', () => {
      this.setIntersectionObserver();
    });
  });

  private setIntersectionObserver(): void {
    this.clearActiveObserver();

    const [firstListItem, lastListItem] = this.getSizeElements();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          const isOutOfScreen = entry.intersectionRatio !== 1;

          if (isOutOfScreen) {
            return;
          }

          // const isFirstItemReached = entry.target === firstListItem;
          // const isLastItemReached = entry.target === lastListItem;

          // if (isFirstItemReached) {
          //   await this.handleFirstReached();
          // } else if (isLastItemReached) {
          //   await this.handleLastReached();
          // }

          const isLastItemReached = entry.target === lastListItem;

          if (isLastItemReached) {
            await this.handleLastReached();
          }
        });
      },
      {
        threshold: 1,
      },
    );

    observer.observe(firstListItem);
    observer.observe(lastListItem);

    this.activeObserver = observer;
    this.observerElements = [firstListItem, lastListItem];
  }

  public setBookings(calendar: CalendarModel): void {
    this.bookings = [...this.bookings, ...calendar.bookings.slice().flat()];
  }

  private getSizeElements(): [Element, Element] {
    const table = document.getElementById(this.tableId) as HTMLTableElement;
    const tHead = table.tHead as HTMLTableSectionElement;
    const tr = tHead.children[0] as Element;

    const { calendarRepository } = this;

    const firstListItem = tr.children[calendarRepository.minItem] as Element;
    const lastListItem = tr.children[calendarRepository.maxItem - 1] as Element;

    return [firstListItem, lastListItem];
  }

  private clearActiveObserver(): void {
    if (this.observerElements?.length) {
      this.observerElements.forEach((element) => {
        this.activeObserver.unobserve(element);
      });
    }

    this.activeObserver?.disconnect();
  }

  // private async handleFirstReached(): Promise<void> {
  //   const data = await this.calendarRepository.loadPrev();

  //   this.setBookings(data.slice());
  //   this.setIntersectionObserver();
  // }

  private async handleLastReached(): Promise<void> {
    const data = await this.calendarRepository.loadNext();

    this.setBookings(data);
    this.setIntersectionObserver();
  }
}
