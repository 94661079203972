import messages from 'ember-changeset-validations/utils/messages';
import ApplicationInstance from '@ember/application/instance';
import { application } from 'uplisting-frontend/utils';
import IntlService from 'ember-intl/services/intl';

const intl = (application.instance as ApplicationInstance).lookup(
  'service:intl',
) as IntlService;

Object.keys(messages).forEach((key) => {
  const lookupKey = `validation_messages.${key}`;

  /**
   * translation texts include dynamic parts, that will be replaced by ember changeset
   * ie `{description} can't be blank` -> `Name can't be blank`
   * but that will be done further by ember changeset. Here we are passing all possible dynamic parts
   * with the exact same wording, so it doesn't:
   *  - throw error that dynamic parts where not provided
   *  - mutate original string, so that on the further steps `ember-changeset` will be able to correctly provide all dynamic parts
   */
  if (intl.exists(lookupKey)) {
    messages[key] = intl.t(lookupKey, {
      description: '{description}',
      after: '{after}',
      before: '{before}',
      on: '{on}',
      is: '{is}',
      gt: '{gt}',
      gte: '{gte}',
      lt: '{lt}',
      lte: '{lte}',
      onOrAfter: '{onOrAfter}',
      onOrBefore: '{onOrBefore}',
      value: '{value}',
      multipleOf: '{multipleOf}',
      min: '{min}',
      max: '{max}',
    });
  }
});

export default messages;
