import BaseRoute from 'uplisting-frontend/pods/base/route';
import Transition from '@ember/routing/transition';
import UpsellsIndexController from 'uplisting-frontend/pods/upsells/index/controller';
import { type Registry as Services, inject as service } from '@ember/service';

export default class UpsellsIndexRoute extends BaseRoute {
  @service('repositories/upsell')
  upsellRepository!: Services['repositories/upsell'];

  model() {
    return this.upsellRepository.query({});
  }

  setupController(
    controller: UpsellsIndexController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, {
      upsells: model.slice(),
    });
  }

  resetController(controller: UpsellsIndexController): void {
    controller.handleResetFilters();
    controller.orderBy = undefined;
  }
}
