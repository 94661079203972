import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached } from '@glimmer/tracking';
import { ILink } from 'uplisting-frontend/pods/components/ui/nav-links/component';

export default class DirectController extends BaseController {
  @cached
  get navLinks(): ILink[] {
    const { intl } = this;

    return [
      {
        name: intl.t('nav_links.direct.link'),
        route: 'direct.link',
        permitted: true,
      },
      {
        name: intl.t('nav_links.direct.content'),
        route: 'direct.content',
        permitted: true,
      },
      {
        name: intl.t('nav_links.direct.design'),
        route: 'direct.design',
        permitted: true,
      },
      {
        name: intl.t('nav_links.direct.listings'),
        route: 'direct.listings',
        permitted: true,
      },
      {
        name: intl.t('nav_links.direct.terms'),
        route: 'direct.terms',
        permitted: true,
      },
      {
        name: intl.t('nav_links.direct.promotions'),
        route: 'direct.promotions',
        permitted: true,
      },
    ];
  }
}
