import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import { ILink } from 'uplisting-frontend/pods/components/ui/nav-links/component';
import { inject as controller } from '@ember/controller';
import UpsellsEditController from 'uplisting-frontend/pods/upsells/edit/controller';

export enum UpsellType {
  custom = 'custom',
}

export default class UpsellsController extends BaseController {
  @controller('upsells/edit') upsellsEditController!: UpsellsEditController;

  @cached @tracked type: UpsellType = UpsellType.custom;

  @cached
  get navLinks(): ILink[] {
    const { intl } = this;

    return [
      {
        name: intl.t('upsells.nav_items.upsells'),
        route: 'upsells.index',
        activeOnRoutes: ['upsells.index', 'upsells.edit', 'upsells.new'],
        permitted: true,
        default: true,
        queryParams: [
          {
            key: 'type',
            value: UpsellType.custom,
          },
        ],
      },
      {
        name: intl.t('upsells.nav_items.orders'),
        route: 'upsells.orders',
        permitted: this.currentUser.hasAccessToPage('upsell_orders.index'),
      },
    ];
  }

  @cached
  get isUpsellsIndexPage(): boolean {
    return this.router.currentRouteName === 'upsells.index';
  }

  @cached
  get isUpsellsEditPage(): boolean {
    return this.router.currentRouteName === 'upsells.edit';
  }

  @cached
  get isUpsellsNewPage(): boolean {
    return this.router.currentRouteName === 'upsells.new';
  }

  @cached
  get isUpsellOrdersPage(): boolean {
    return this.router.currentRouteName === 'upsells.orders';
  }

  @cached
  get isUpsellsPage(): boolean {
    return (
      this.isUpsellsIndexPage || this.isUpsellsEditPage || this.isUpsellsNewPage
    );
  }

  @cached
  get title(): string {
    const { intl } = this;

    if (this.isUpsellsPage) {
      const activeItem = this.navLinks.find(
        (navLink) => navLink.queryParams?.[0]?.value === this.type,
      ) as ILink;

      const { name } = activeItem;

      if (this.isUpsellsNewPage) {
        return `${name} > ${intl.t('upsells_new.new')}`;
      } else if (this.isUpsellsEditPage) {
        return `${name} > ${this.upsellsEditController.changeset.name}`;
      }

      return name;
    }

    return intl.t('upsells.nav_items.orders');
  }

  public resetState(): void {
    this.type = UpsellType.custom;
  }
}
