import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import InvitationModel from 'uplisting-frontend/models/invitation';
import { InvitationValidation } from 'uplisting-frontend/validations';

export default class InvitationRepositoryService extends BaseRepositoryService<InvitationModel> {
  recordName = 'invitation';
  implementMethods = [
    'buildChangeset',
    'createRecord',
    'destroyRecord',
    'save',
    'findAll',
  ];

  validation = InvitationValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/invitation': InvitationRepositoryService;
  }
}
