import BaseRoute from 'uplisting-frontend/pods/base/route';
import { type Registry as Services, inject as service } from '@ember/service';
import InsightsAllController from 'uplisting-frontend/pods/insights/all/controller';
import InsightModel from 'uplisting-frontend/models/insight';
import Transition from '@ember/routing/transition';
import RSVP from 'rsvp';
import DS from 'ember-data';

interface IInsightsAllRouteModel {
  insights: DS.PromiseArray<InsightModel>;
  compareToInsights: DS.PromiseArray<InsightModel> | never[];
}

interface IParams {
  start: string;
  end: string;

  propertyIds: string[];
  compareToPropertyIds: string[];

  propertyTagIds: string[];
  compareToPropertyTagIds: string[];

  compareToStart: string;
  compareToEnd: string;
}

interface IFetchArgs {
  start_date: string;
  end_date: string;
  property_ids: string[];
}

export default class InsightsAllRoute extends BaseRoute {
  @service('repositories/insight')
  insightRepository!: Services['repositories/insight'];

  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/property-tag')
  propertyTagRepository!: Services['repositories/property-tag'];

  queryParams = {
    start: {
      refreshModel: true,
    },
    end: {
      refreshModel: true,
    },
    propertyIds: {
      refreshModel: true,
    },
    compareToStart: {
      refreshModel: true,
    },
    compareToEnd: {
      refreshModel: true,
    },
    compareToPropertyIds: {
      refreshModel: true,
    },
    propertyTagIds: {
      refreshModel: true,
    },
    compareToPropertyTagIds: {
      refreshModel: true,
    },
  };

  async model(
    params: IParams,
    transition: Transition,
  ): Promise<IInsightsAllRouteModel> {
    if (!this.isComingFromSameRoute(transition)) {
      await RSVP.hash({
        properties: this.propertyRepository.findAll(),
        propertyTags: this.propertyTagRepository.findAll(),
      });
    }

    const insights = this.insightRepository.query(
      this.getInsightsFilterParams(params),
    );

    const compareToInsights = this.shouldFetchCompareToData(params)
      ? this.insightRepository.query(
          this.getCompareToInsightsFilterParams(params),
        )
      : [];

    return RSVP.hash({
      insights,
      compareToInsights,
    });
  }

  setupController(
    controller: InsightsAllController,
    model: IInsightsAllRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, {
      ...transition.to?.queryParams,
      isLoading: false,
      record: model.insights[0],
      compareToRecord: model.compareToInsights[0],
    });
  }

  resetController(controller: InsightsAllController) {
    controller.resetState();
  }

  private shouldFetchCompareToData(params: IParams): boolean {
    return (
      !!(params.compareToStart && params.compareToEnd) ||
      !!params.compareToPropertyIds.length ||
      !!params.compareToPropertyTagIds.length
    );
  }

  private getInsightsFilterParams(params: IParams): IFetchArgs {
    const ids = params.propertyTagIds.length
      ? this.getPropertyIdsFromTags(params.propertyTagIds)
      : params.propertyIds;

    return {
      start_date: params.start,
      end_date: params.end,
      property_ids: ids,
    };
  }

  private getCompareToInsightsFilterParams(params: IParams): IFetchArgs {
    if (params.compareToStart) {
      const ids = params.propertyTagIds.length
        ? this.getPropertyIdsFromTags(params.propertyTagIds)
        : params.propertyIds;

      return {
        start_date: params.compareToStart,
        end_date: params.compareToEnd,
        property_ids: ids,
      };
    }

    const ids = params.compareToPropertyTagIds.length
      ? this.getPropertyIdsFromTags(params.compareToPropertyTagIds)
      : params.compareToPropertyIds;

    return {
      start_date: params.start,
      end_date: params.end,
      property_ids: ids,
    };
  }

  private getPropertyIdsFromTags(ids: string[]): string[] {
    const records = ids.map((id) => this.propertyTagRepository.peekRecord(id));

    return records.flatMap((record) => record.hasMany('properties').ids());
  }
}
