import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';

interface IArgs {
  value: string;
  disabled?: boolean;
  placeholder?: string;
  rounded?: boolean;
  showSearchIcon?: boolean;
  focusOnInsert?: boolean;
  onChangeInput(value: string): void;
}

interface SearchInputSignature {
  Element: HTMLLabelElement;

  Args: IArgs;
}

export default class UiSearchInputComponent extends Component<SearchInputSignature> {
  @cached @tracked isFocused = false;

  @cached
  get showSearchIcon(): boolean {
    return this.args.showSearchIcon ?? true;
  }

  @cached
  get focusOnInsert(): boolean {
    return this.args.focusOnInsert ?? true;
  }

  @cached
  get rounded(): boolean {
    return this.args.rounded ?? false;
  }

  @cached
  get disabled(): boolean {
    return this.args.disabled ?? false;
  }

  @action
  handleBlur(): void {
    this.isFocused = false;
  }

  @action
  handleFocus(): void {
    this.isFocused = true;
  }

  @action
  handleKeyPress(): void {
    console.log('handleKeyPress');
  }

  @action
  handleChangeInput(event: Event): void {
    this.args.onChangeInput((event.target as HTMLInputElement).value);
  }

  handleInsert = modifier((input: HTMLInputElement) => {
    if (this.focusOnInsert) {
      input?.focus();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::SearchInput': typeof UiSearchInputComponent;
  }
}
