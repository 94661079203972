import Transform from '@ember-data/serializer/transform';

export const isPlainObject = (obj: unknown): boolean =>
  Object.prototype.toString.call(obj) === '[object Object]';

export default class ObjectTransform extends Transform {
  deserialize(value: unknown) {
    if (isPlainObject(value)) {
      return value;
    }

    return {};
  }

  serialize(value: unknown) {
    if (isPlainObject(value)) {
      return value;
    }

    return {};
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    object: ObjectTransform;
  }
}
