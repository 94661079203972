import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import EdgestateConnectionModel from 'uplisting-frontend/models/edgestate-connection';

export default class EdgestateConnectionRepositoryService extends BaseRepositoryService<EdgestateConnectionModel> {
  recordName = 'edgestate-connection';
  implementMethods = ['findAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/edgestate-connection': EdgestateConnectionRepositoryService;
  }
}
