import Component from '@glimmer/component';

interface IArgs {
  title: string;
  description?: string;
  enabledText?: string;
  explanation?: string;
  small?: boolean;
}

interface InfoCardSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    enabledBlock?: [];
    description?: [];
  };
}

export default class UiInfoCardComponent extends Component<InfoCardSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::InfoCard': typeof UiInfoCardComponent;
  }
}
