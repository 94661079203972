import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import Litepicker from 'litepicker';
import { utcDate } from 'uplisting-frontend/utils';
import { IDropdown } from 'ember-bootstrap/components/bs-dropdown';

interface IArgs {
  date: Date | '-' | undefined;

  onDateSelect(value: Date): void;
}

interface PickerDateRangeSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiPickerDateComponent extends Component<PickerDateRangeSignature> {
  @service intl!: Services['intl'];

  @cached @tracked picker!: Litepicker;

  @cached @tracked dropdown?: IDropdown;

  @cached
  get date(): Date | '-' | undefined {
    return this.args.date;
  }

  @action
  handleSetupPicker(dd: IDropdown, element: HTMLDivElement): void {
    this.dropdown = dd;

    this.picker = new Litepicker({
      element,
      startDate: this.date,
      parentEl: element,
      lang: this.intl.primaryLocale,
      position: 'top left',
    });

    this.picker.on('selected', (date) => {
      this.args.onDateSelect(utcDate(date.dateInstance));
      this.handleWillDestroy();
    });

    this.picker.show();
  }

  @action
  handleWillDestroy(): void {
    this.picker.hide();
    this.picker.destroy();
    this.dropdown?.closeDropdown();
    this.dropdown = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Picker::Date': typeof UiPickerDateComponent;
  }
}
