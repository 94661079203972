import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';

interface IArgs<FilterType> {
  filter: FilterType;
  label: string;
  placeholder: string;
  noMatchesMessage: string;
  onFilterUpdate(): void;
}

export interface FiltersFilterSignature<FilterType> {
  Args: IArgs<FilterType>;
}

export default abstract class BaseFilterComponent<T> extends Component<
  FiltersFilterSignature<T>
> {
  @cached
  get id(): string {
    return guidFor(this);
  }

  @cached
  get filter(): T {
    return this.args.filter;
  }

  @cached
  get label(): string {
    return this.args.label;
  }

  @cached
  get placeholder(): string {
    return this.args.placeholder;
  }

  @cached
  get noMatchesMessage(): string {
    return this.args.noMatchesMessage;
  }
}
