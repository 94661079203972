import UiConnectionsIndexComponent from 'uplisting-frontend/pods/components/ui/connections/index';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import SeamConnectionModel from 'uplisting-frontend/models/seam-connection';
import SeamLockableModel from 'uplisting-frontend/models/seam-lockable';
import { SeamConnectionProvider } from 'uplisting-frontend/models/schemas';
import { restartableTask } from 'ember-concurrency';

export default class UiConnectionsSeamComponent extends UiConnectionsIndexComponent<SeamConnectionModel> {
  @cached
  get brandLogo(): string {
    const { provider } = this.connection;

    if (Object.values(SeamConnectionProvider).includes(provider)) {
      return provider;
    }

    return 'seam-lock';
  }

  @action
  async handleRemoveConnection(): Promise<void> {
    await super.handleRemoveConnection(
      'connect_seam.notifications.account_removed',
      AnalyticsEvents.seamAccountRemoved,
    );
  }

  @action
  async handleLockPropertySelect(lockable: SeamLockableModel): Promise<void> {
    try {
      await lockable.save();

      this.analytics.trackEvent(AnalyticsEvents.seamLockMapped);

      this.notifications.success('notifications.applied');
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleToggleConfigEditing(): Promise<void> {
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      await this.connection.reload();
    }
  }

  public refreshLocksTask = restartableTask(async () => {
    try {
      await this.connection.reload({ adapterOptions: { refresh: true } });

      this.notifications.info('connect_seam.notifications.lock_code_refreshed');
    } catch {
      this.notifications.error();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connections::Seam': typeof UiConnectionsSeamComponent;
  }
}
