export enum TaxRateType {
  inclusive = 'inclusive',
  exclusive = 'exclusive',
}

export interface ITaxSchema {
  type: string;
  rate: number;
  rateType: TaxRateType;
  description: string;
}
