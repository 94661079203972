import Component from '@glimmer/component';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import isEmpty from 'lodash-es/isEmpty';

interface ILinkQueryParams {
  key: string;
  value: string;
}

export interface ILink {
  id?: string;
  name: string;
  route: string;
  activeOnRoutes?: string[];
  permitted: boolean;
  external?: boolean;
  queryParams?: ILinkQueryParams[];
  default?: boolean;
}

interface IArgs {
  links: ILink[];
}

interface NavLinksSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

type IQueryParams = Record<string, string>;

export default class UiNavLinksComponent extends Component<NavLinksSignature> {
  @service router!: Services['router'];

  @action
  computeQueryParams(link: ILink): IQueryParams {
    const queryParams = link.queryParams as ILinkQueryParams[];

    return queryParams.reduce(
      (acc, query) => ({ ...acc, [query.key]: query.value }),
      {},
    );
  }

  @action
  computeIsActive(link: ILink): boolean {
    const { currentRoute } = this.router;

    if (!currentRoute) {
      return false;
    }

    const { name, queryParams } = currentRoute;

    const isSameRoute = link.activeOnRoutes
      ? link.activeOnRoutes.some((route) => name.startsWith(route))
      : name.startsWith(link.route);

    const linkQueryParams = link.queryParams as ILinkQueryParams[];

    const isSameQuery = linkQueryParams.every(
      (query) => queryParams[query.key] === query.value,
    );

    const presentQueries = linkQueryParams.reduce((acc, obj) => {
      const value = queryParams[obj.key];

      if (value) {
        return {
          ...acc,
          [obj.key]: value,
        };
      }

      return acc;
    }, {});

    if (isEmpty(presentQueries) && link.default) {
      return isSameRoute;
    }

    return isSameRoute && isSameQuery;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::NavLinks': typeof UiNavLinksComponent;
  }
}
