import Component from '@glimmer/component';

interface IArgs {
  inPlace?: boolean;
}

interface LoadingStateSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiLoadingStateComponent extends Component<LoadingStateSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::LoadingState': typeof UiLoadingStateComponent;
  }
}
