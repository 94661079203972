import Component from '@glimmer/component';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface CreateTaxSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiCreateTaxComponent extends Component<CreateTaxSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/create-tax': typeof UiCreateTaxComponent;
  }
}
