import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import { type Registry as Services, inject as service } from '@ember/service';
import DashboardIndexController from 'uplisting-frontend/pods/dashboard/index/controller';
import { SectionIds } from 'uplisting-frontend/models/occasion';
import { DashboardDateOptions } from 'uplisting-frontend/utils/interfaces';

interface IQueryParams {
  activeActionTab: SectionIds;
  activeActivityTab: SectionIds;
  activeBookingsTab: SectionIds;
  activeDayTab: DashboardDateOptions;
}

export default class DashboardIndexRoute extends PermittedRoute {
  @service features!: Services['features'];

  @service('repositories/occasion')
  occasionRepositoryService!: Services['repositories/occasion'];

  permission = 'dashboard.index';

  /**
   * @description make initial request to get occasion data, based on the default selected date range and active(default) table sections
   *
   * @returns {Promise<void>}
   */
  async model(qp: IQueryParams): Promise<void> {
    if (!this.features.isDashboardEnabled) {
      return;
    }

    const controller = this.controllerFor(
      'dashboard/index',
    ) as DashboardIndexController;

    Object.assign(controller, qp);

    return controller.fetchOccasions(true);
  }

  /**
   * @description unload all occasion records and reset all states(input)
   *
   * @param {DashboardIndexController} controller
   */
  resetController(controller: DashboardIndexController): void {
    this.occasionRepositoryService.unloadAll();

    controller.resetSearch();
    controller.resetQueryParams();
  }
}
