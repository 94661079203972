import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import CustomerModel from 'uplisting-frontend/models/customer';

interface IArgs {
  title: string;
  description: string;
  buttonText: string;
}

interface UpgradeSubscriptionSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiUpgradeSubscriptionComponent extends Component<UpgradeSubscriptionSignature> {
  @service pricing!: Services['pricing'];

  @cached
  get isUpgradeNeeded(): boolean {
    return this.customer?.isLegacyPlan || !!this.customer?.isSaving;
  }

  @cached
  get customer(): CustomerModel | undefined {
    return this.pricing.customer;
  }

  @cached
  get newPlanAmount(): number {
    return this.pricing.subscriptionAmount(0);
  }

  @cached
  get subscriptionAmount(): number {
    return this.pricing.subscriptionAmount();
  }

  @cached
  get newPlanCost(): number {
    return this.annualCost(this.newPlanAmount);
  }

  @cached
  get subscriptionCost(): number {
    return this.annualCost(this.subscriptionAmount);
  }

  @cached
  get planDuration(): string {
    return this.customer?.annual ? 'year' : 'month';
  }

  private annualCost(cost: number): number {
    const discounted = this.pricing.annualAmount;
    const multiplier = this.customer?.annual ? discounted : 1;

    return cost * multiplier;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::UpgradeSubscription': typeof UiUpgradeSubscriptionComponent;
  }
}
