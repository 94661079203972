import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import {
  IClientStatementSchema,
  ClientStatementStatus,
} from 'uplisting-frontend/models/schemas';
import ClientModel from 'uplisting-frontend/models/client';
import PropertyModel from 'uplisting-frontend/models/property';
import ClientStatementExpenseModel from 'uplisting-frontend/models/client-statement-expense';
import ClientStatementReportModel from 'uplisting-frontend/models/client-statement-report';

export default class ClientStatementModel
  extends Model
  implements IClientStatementSchema
{
  @attr('string') status!: ClientStatementStatus;
  @attr('string') htmlVersion!: string;
  @attr('string') fileLink!: string;
  @attr('string') startDate!: string;
  @attr('string') endDate!: string;
  @attr('string') currency!: string;
  @attr('date') invoiceDate!: Date;
  @attr('string') invoiceNumber!: string;

  @hasMany('client-statement-expense', {
    inverse: 'clientStatement',
    async: false,
  })
  expenses!: ClientStatementExpenseModel[];

  @belongsTo('client', { inverse: null, async: false })
  client!: ClientModel;

  @belongsTo('property', { inverse: null, async: false })
  property!: PropertyModel;

  @belongsTo('client-statement-report', { inverse: null, async: false })
  report!: ClientStatementReportModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'client-statement': ClientStatementModel;
  }
}
