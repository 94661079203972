import UiConnectionsIndexComponent, {
  LockOptions,
  LockOption,
} from 'uplisting-frontend/pods/components/ui/connections/index';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import EdgestateConnectionModel from 'uplisting-frontend/models/edgestate-connection';
import EdgestateLockableModel from 'uplisting-frontend/models/edgestate-lockable';

export default class UiConnectionsEdgestateComponent extends UiConnectionsIndexComponent<EdgestateConnectionModel> {
  @service intl!: Services['intl'];

  @cached
  get lockOptions(): LockOptions {
    return [
      {
        id: null,
        forLockable: this.intl.t(
          'connect_edgestate.lock_options.not_connected',
        ),
      },
      ...super.lockOptions,
    ];
  }

  @action
  async handleRefreshLocks(): Promise<void> {
    try {
      await this.connection.reload();

      this.notifications.info(
        'connect_edgestate.notifications.lock_code_refreshed',
      );
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleRemoveConnection(): Promise<void> {
    await super.handleRemoveConnection(
      'connect_edgestate.notifications.account_removed',
      AnalyticsEvents.edgestateAccountRemoved,
    );
  }

  @action
  async handleLockPropertySelect(
    lockable: EdgestateLockableModel,
    option: LockOption,
  ): Promise<void> {
    const { property, multiUnit } = lockable;

    let newProperty: PropertyModel | null = null;
    let newMultiUnit: MultiUnitModel | null = null;

    if (option instanceof MultiUnitModel) {
      newProperty = option.property;
      newMultiUnit = option;
    } else if (option instanceof PropertyModel) {
      newProperty = option;
      newMultiUnit = null;
    }

    lockable.property = newProperty;
    lockable.multiUnit = newMultiUnit;

    try {
      await lockable.save();

      this.analytics.trackEvent(AnalyticsEvents.edgestateLockMapped);

      this.notifications.success('notifications.applied');
    } catch {
      lockable.property = property;
      lockable.multiUnit = multiUnit;

      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connections::Edgestate': typeof UiConnectionsEdgestateComponent;
  }
}
