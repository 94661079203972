import FormElement from 'ember-bootstrap/components/bs-form/element';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import DataModel from '@ember-data/model';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { ValidationErr } from 'validated-changeset/dist/types';

export default class UiFormNumberComponent<
  T extends DataModel,
> extends FormElement<T> {
  hasValidator = true;
  showValidation = false;

  @cached
  get property(): string {
    return this.args.property as string;
  }

  @cached
  get model(): GenericChangeset<T> {
    return this.args.model as GenericChangeset<T>;
  }

  @cached
  get errors(): ValidationErr | ValidationErr[] | undefined {
    return this.model.error[this.property]?.validation;
  }

  @action
  doChange(value: string): void {
    this.model.set(this.property, value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Form::Number': typeof UiFormNumberComponent;
  }
}
