import Model, { attr, belongsTo } from '@ember-data/model';
import { ISecurityDepositSettingSchema } from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';

export default class SecurityDepositSettingModel
  extends Model
  implements ISecurityDepositSettingSchema
{
  @attr('number') amount!: number;
  @attr('boolean') enabled!: boolean;

  @belongsTo('property', { inverse: null, async: false })
  property!: PropertyModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'security-deposit-setting': SecurityDepositSettingModel;
  }
}
