import UiConnectionsIndexComponent from 'uplisting-frontend/pods/components/ui/connections/index';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import StripeConnectionModel from 'uplisting-frontend/models/stripe-connection';
import PropertyModel from 'uplisting-frontend/models/property';
import { toggleHasManyValue } from 'uplisting-frontend/utils';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';

export default class UiConnectionsStripeComponent extends UiConnectionsIndexComponent<StripeConnectionModel> {
  @service intl!: Services['intl'];

  @cached @tracked isSubmitting = false;

  @cached
  get connectionName(): string {
    return (
      this.connection.name ||
      this.intl.t('connect_stripe.connections.default_name')
    );
  }

  @cached
  get availableProperties(): PropertyModel[] {
    return this.properties.filter(
      (property) => !this.computeIsDisabledProperty(property),
    );
  }

  @cached
  get isAllSelected(): boolean {
    return this.availableProperties.every((property) =>
      this.connection.properties.includes(property),
    );
  }

  @action
  computeDisabledText(): string {
    return this.intl.t('connect_stripe.connections.already_connected');
  }

  @action
  computeIsDisabledProperty(property: PropertyModel): boolean {
    if (property.stripeConnection) {
      return property.stripeConnection !== this.connection;
    }

    return false;
  }

  @action
  handelSelectAllClick(): void {
    this.connection.properties = this.isAllSelected
      ? []
      : this.availableProperties;
  }

  @action
  handleToggleProperty(property: PropertyModel): void {
    toggleHasManyValue(this.connection, 'properties', property);
  }

  @action
  async handleCancel(): Promise<void> {
    this.isEditing = false;

    await this.connection.reload();
  }

  @action
  async handleApplyChanges(): Promise<void> {
    this.isSubmitting = true;

    try {
      await this.connection.save();

      this.notifications.info('connect_stripe.notifications.success');
      this.analytics.trackEvent(AnalyticsEvents.connectStripe);

      await this.args.onSave?.();
    } catch {
      this.notifications.error('connect_stripe.notifications.error');
    } finally {
      this.isSubmitting = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connections::Stripe': typeof UiConnectionsStripeComponent;
  }
}
