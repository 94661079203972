import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { searchOptions, toggleValue } from 'uplisting-frontend/utils';
import AirbnbOfficialOpportunityModel from 'uplisting-frontend/models/airbnb-official-opportunity';
import AirbnbOfficialListingModel from 'uplisting-frontend/models/airbnb-official-listing';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';

interface IArgs {
  open: boolean;
  opportunity: AirbnbOfficialOpportunityModel;
  onSubmit(): void;
  onCancel(): void;
}

interface ModalApplyOpportunitiesSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiIModalApplyOpportunitiesComponent extends Component<ModalApplyOpportunitiesSignature> {
  @service analytics!: Services['analytics'];

  @cached @tracked inputValue = '';
  @cached @tracked isSubmitting = false;
  @cached @tracked selectedListingIds: string[] = [];

  @cached
  get opportunity(): AirbnbOfficialOpportunityModel {
    return this.args.opportunity;
  }

  @cached
  get isSelectOpportunitiesValid(): boolean {
    return this.selectedListingIds.length >= 1;
  }

  @cached
  get isInvalid(): boolean {
    return !this.isSelectOpportunitiesValid;
  }

  @cached
  get listings(): AirbnbOfficialListingModel[] {
    return this.opportunity.airbnbOfficialListings.slice();
  }

  @cached
  get filteredProperties(): AirbnbOfficialListingModel[] {
    return searchOptions<AirbnbOfficialListingModel>(
      this.listings,
      this.inputValue,
      'name',
    );
  }

  @cached
  get filteredIds(): string[] {
    return this.filteredProperties.map((property) => property.externalId);
  }

  @cached
  get isAllPropertiesSelected(): boolean {
    const { selectedListingIds } = this;

    return this.filteredIds.every((property) =>
      selectedListingIds.includes(property),
    );
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  handleToggleProperty(id: string): void {
    toggleValue(this, 'selectedListingIds', id);
  }

  @action
  handleToggleAllProperties(): void {
    if (this.isAllPropertiesSelected) {
      this.filteredIds.forEach((property) => {
        this.handleToggleProperty(property);
      });
    } else {
      this.selectedListingIds = [...this.filteredIds];
    }
  }

  @action
  handleCancel(): void {
    this.resetState();

    this.args.onCancel();
  }

  @action
  async handleSubmit(): Promise<void> {
    this.isSubmitting = true;

    const unique = new Set(this.selectedListingIds);

    this.opportunity.applicableListingIds = Array.from(unique);

    if (this.isInvalid) {
      return;
    }

    await this.opportunity.save();

    this.analytics.trackEvent(AnalyticsEvents.airbnbOpportunityApplied);

    this.resetState();
    this.args.onSubmit();
  }

  private resetState(): void {
    this.isSubmitting = false;
    this.selectedListingIds = [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Modal::ApplyOpportunity': typeof UiIModalApplyOpportunitiesComponent;
  }
}
