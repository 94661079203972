import ApplicationAdapter from 'uplisting-frontend/adapters/application';

export default class ApplicationV1Adapter extends ApplicationAdapter {
  namespace = '';
}

declare module 'ember-data/types/registries/adapter' {
  interface AdapterRegistry {
    'application-v1': ApplicationV1Adapter;
  }
}
