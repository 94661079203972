import Service from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import ApplicationInstance from '@ember/application/instance';

export default class ScreenService extends Service {
  @cached @tracked recalculateMediaQuery = false;

  constructor(appInstance: ApplicationInstance) {
    super(appInstance);

    window.addEventListener('resize', this.onWindowResize.bind(this), true);
  }

  private onWindowResize(): void {
    this.recalculateMediaQuery = !this.recalculateMediaQuery;
  }

  @cached
  get isLargeOrBelow() {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateMediaQuery;

    return window.matchMedia('(max-width: 1279px)').matches;
  }

  @cached
  get dropdownMenuComponent(): 'empty-component' | 'bs-modal' {
    if (this.isLargeOrBelow) {
      return 'bs-modal';
    }

    return 'empty-component';
  }
}

declare module '@ember/service' {
  interface Registry {
    screen: ScreenService;
  }
}
