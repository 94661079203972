import Model, { attr } from '@ember-data/model';
import {
  IReviewStatisticCategorySchema,
  ReviewStatisticCategory,
} from 'uplisting-frontend/models/schemas';

export default class ReviewStatisticCategoryModel
  extends Model
  implements IReviewStatisticCategorySchema
{
  @attr('string') category!: ReviewStatisticCategory;
  @attr('string') month!: string;
  @attr('number') rating!: number;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'review-statistic-category': ReviewStatisticCategoryModel;
  }
}
