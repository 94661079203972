import ApplicationInstance from '@ember/application/instance';
/**
 * Export an Object with the app instance set to null. Later initilized by
 * `app/instance-initializers/application` during application boot.
 *
 * Exports {Object} containing an `instance` member (null by default).
 */
interface IApplication {
  instance: ApplicationInstance | null;
}

export const application: IApplication = {
  instance: null,
};
