import Component from '@glimmer/component';
import InvitationModel from 'uplisting-frontend/models/invitation';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';

interface IArgs {
  invitation: InvitationModel;
  isDisabled?: boolean;
  onRevoke(invitation: InvitationModel): void;
}

interface InvitationRowSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiInvitationRowComponent extends Component<InvitationRowSignature> {
  @cached
  get invitation(): InvitationModel {
    return this.args.invitation;
  }

  @cached
  get isDisabled(): boolean {
    return this.args.isDisabled ?? false;
  }

  @action
  handleRevoke(): void {
    this.args.onRevoke(this.invitation);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Invitation::Row': typeof UiInvitationRowComponent;
  }
}
