import BaseRoute from 'uplisting-frontend/pods/base/route';
import ActionBookingsDetailsController from 'uplisting-frontend/pods/action/bookings/details/controller';
import Transition from '@ember/routing/-private/transition';

export default class ActionBookingsDetailsRoute extends BaseRoute {
  model() {
    return this.modelFor('action.bookings');
  }

  setupController(
    controller: ActionBookingsDetailsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });
  }
}
