import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import AirbnbOfficialNotificationModel from 'uplisting-frontend/models/airbnb-official-notification';

export default class AirbnbOfficialNotificationRepositoryService extends BaseRepositoryService<AirbnbOfficialNotificationModel> {
  recordName = 'airbnb-official-notification';
  implementMethods = ['unloadAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/airbnb-official-notification': AirbnbOfficialNotificationRepositoryService;
  }
}
