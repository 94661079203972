import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  title: string;
  icon?: string;
  iconClass?: string;
}

interface ConnectHowToEnableSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiConnectHowToEnableComponent extends Component<ConnectHowToEnableSignature> {
  @cached
  get icon(): string {
    return this.args.icon ?? 'circle-question';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connect::HowToEnable': typeof UiConnectHowToEnableComponent;
  }
}
