import Model, { attr, belongsTo } from '@ember-data/model';
import PropertyModel from 'uplisting-frontend/models/property';
import { IPropertyManagementFeeSettingSchema } from 'uplisting-frontend/models/schemas';

export default class PropertyManagementFeeSettingModel
  extends Model
  implements IPropertyManagementFeeSettingSchema
{
  @attr('number') cleaningFeePercentage!: number;
  @attr('number') accommodationPercentage!: number;

  @belongsTo('property', {
    async: false,
    inverse: 'propertyManagementFeeSetting',
  })
  property!: PropertyModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'property-management-fee-setting': PropertyManagementFeeSettingModel;
  }
}
