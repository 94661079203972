import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { ActionDataItem } from 'uplisting-frontend/pods/action/controller';
import ChangeoverModel from 'uplisting-frontend/models/changeover';

interface IArgs {
  item: ActionDataItem;
  date: string;
}

interface ActionTableRowSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

type RowComponent =
  | 'ui/action/table/row/booking'
  | 'ui/action/table/row/changeover';

export default class UiActionTableRowComponent extends Component<ActionTableRowSignature> {
  @cached
  get item(): ActionDataItem {
    return this.args.item;
  }

  @cached
  get rowComponent(): RowComponent {
    if (this.item instanceof ChangeoverModel) {
      return 'ui/action/table/row/changeover';
    }

    return 'ui/action/table/row/booking';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Row': typeof UiActionTableRowComponent;
  }
}
