import SeamLockableModel from 'uplisting-frontend/models/seam-lockable';

export enum SeamConnectionProvider {
  august = 'august',
  nuki = 'nuki',
  kwikset = 'kwikset',
  schlage = 'schlage',
  yale = 'yale',
  igloohome = 'igloohome',
  ttlock = 'ttlock',
  smartthings = 'smartthings',
  dormakabaOracode = 'dormakaba_oracode',
  salto = 'salto',
  tedee = 'tedee',
}

export interface ISeamConnectionSchema {
  connectWebviewUrl: string;
  provider: SeamConnectionProvider;

  seamLockables: SeamLockableModel[];
}
