import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { SectionIds } from 'uplisting-frontend/models/occasion';
import { type Registry as Services, inject as service } from '@ember/service';
import { DashboardDateOptions } from 'uplisting-frontend/utils/interfaces';

interface IArgs {
  isFetching: boolean;
  inputValue: string;
  activeDayTab: DashboardDateOptions;
  activeTab: SectionIds;
  handleResetSearch(): void;
}

interface NoDataPresentSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

/**
 * @description when no occasion data matches filter criteria, including input value
 */
export default class UiDashboardNoDataPresentComponent extends Component<NoDataPresentSignature> {
  @service intl!: Services['intl'];

  @cached
  get inputValue(): string {
    return this.args.inputValue;
  }

  @cached
  get noDataWording(): string {
    const { intl } = this;

    if (this.inputValue) {
      return intl.t('dashboard_index.no_data.filtered');
    }

    return `${this.base} ${this.occasionType} ${this.timePeriod}`;
  }

  @cached
  get base(): string {
    const { intl } = this;

    switch (this.args.activeTab) {
      case SectionIds.checkOut:
      case SectionIds.checkIn:
      case SectionIds.stays:
        return intl.t('dashboard_index.no_data.no_guests_text');
      default:
        return intl.t('dashboard_index.no_data.no_text');
    }
  }

  @cached
  get occasionType(): string {
    const { intl } = this;

    switch (this.args.activeTab) {
      case SectionIds.checkOut:
        return intl.t('dashboard_index.no_data.occasion.checking_out');
      case SectionIds.checkIn:
        return intl.t('dashboard_index.no_data.occasion.checking_in');
      case SectionIds.stays:
        return intl.t('dashboard_index.no_data.occasion.staying');
      case SectionIds.newBookings:
        return intl.t('dashboard_index.no_data.occasion.new_bookings');
      case SectionIds.enquiries:
        return intl.t('dashboard_index.no_data.occasion.enquiries');
      case SectionIds.bookingRequests:
        return intl.t('dashboard_index.no_data.occasion.requests');
      case SectionIds.pendingBookings:
        return intl.t('dashboard_index.no_data.occasion.pending_bookings');
      case SectionIds.cancelledBookings:
        return intl.t('dashboard_index.no_data.occasion.cancelled_bookings');
      case SectionIds.bookingPayment:
        return intl.t('dashboard_index.no_data.occasion.booking_payments');
      case SectionIds.securityDeposits:
        return intl.t('dashboard_index.no_data.occasion.security_deposits');
      case SectionIds.bookingGuestIdentityVerifications:
        return intl.t(
          'dashboard_index.no_data.occasion.booking_guest_identity_verifications',
        );
      case SectionIds.bookingRentalAgreements:
        return intl.t(
          'dashboard_index.no_data.occasion.booking_rental_agreements',
        );
    }
  }

  @cached
  get timePeriod(): string {
    const { intl } = this;

    switch (this.args.activeDayTab) {
      case DashboardDateOptions.yesterday:
        return intl.t('dashboard_index.no_data.time_period.yesterday');
      case DashboardDateOptions.tomorrow:
        return intl.t('dashboard_index.no_data.time_period.tomorrow');
      case DashboardDateOptions.next_7_days:
        return intl.t('dashboard_index.no_data.time_period.next_7_days');
      case DashboardDateOptions.today:
        return intl.t('dashboard_index.no_data.time_period.today');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Dashboard::NoDataPresent': typeof UiDashboardNoDataPresentComponent;
  }
}
