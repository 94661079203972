import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';

interface IArgs {
  title: string;
  description: string;
  imagePath?: string;
  perProperty?: boolean;
  learnMorePath: string;
  isDeactivate?: boolean;
  price?: number;
  type?: 'multiple';
  planIncludes?: string;

  modalTitle: string;
  modalBody: string;
  modalCancelButton: string;
  modalSubmitButton: string;
  onModalSubmit(): void;
}

interface PayWallSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiPayWallComponent extends Component<PayWallSignature> {
  @service pricing!: Services['pricing'];

  @cached @tracked showModal = false;

  @cached
  get isMultipleType(): boolean {
    return this.args.type === 'multiple';
  }

  @action
  handleLearnMore(): void {
    window.open(this.args.learnMorePath, '_blank');
  }

  @action
  handleGetFeature(): void {
    this.showModal = true;
  }

  @action
  handleModalSubmit(): void {
    this.args.onModalSubmit();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::PayWall': typeof UiPayWallComponent;
  }
}
