import Model, { attr, belongsTo } from '@ember-data/model';
import { ICustomerSchema } from 'uplisting-frontend/models/schemas';
import { cached } from '@glimmer/tracking';
import UserModel from 'uplisting-frontend/models/user';

export default class CustomerModel extends Model implements ICustomerSchema {
  @attr('number') planVersion!: number;
  @attr('string') currency!: string;
  @attr('string') externalReference!: string;
  @attr('string') hmac!: string;
  @attr('boolean') annual!: boolean;
  @attr('boolean') subscribed!: boolean;
  @attr('string') checkoutUrl!: string;
  @attr('string') referral!: string;

  @belongsTo('user', {
    inverse: 'customer',
    async: false,
  })
  user!: UserModel;

  @cached
  get isLegacyPlan(): boolean {
    return this.planVersion === 1;
  }

  @cached
  get canUpgrade(): boolean {
    return this.isLegacyPlan && this.subscribed;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    customer: CustomerModel;
  }
}
