export const ONE_MB = 1024 * 1024;

const byteUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function bytesToReadable(fileSizeInBytes: number): string {
  let i = -1;

  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes >= 1024);

  return `${Math.max(fileSizeInBytes, 0.1).toFixed(0)} ${byteUnits[i]}`;
}
