import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import OccasionModel, {
  OccasionAttribute,
} from 'uplisting-frontend/models/occasion';
import {
  BookingRentalAgreementStatus,
  GuestIdentityVerificationStatus,
  OccasionStatus,
  PaymentStatus,
  SecurityDepositStatus,
} from 'uplisting-frontend/models/schemas';
import { isNone } from '@ember/utils';

interface IArgs {
  field: OccasionAttribute;
  occasion: OccasionModel;
  showYear?: boolean;
}

interface DashboardTableCellSignature {
  Element: HTMLTableCellElement;

  Args: IArgs;
}
const badgeFields = [
  'status',
  'paymentStatus',
  'guestIdentityVerificationStatus',
  'rentalAgreementStatus',
  'securityDepositStatus',
];

const linkFields = ['guestName'];

const dateFields = [
  'checkIn',
  'checkOut',
  'creationDate',
  'cancellationDate',
  'paymentDueDate',
  'securityDepositDueDate',
];

const timeFields = ['arrivalTime', 'departureTime'];

const currencyFields = [
  'totalPayout',
  'outstandingAmount',
  'securityDepositAmount',
  'accommodationTotal',
  'cleaningFee',
  'extraGuestCharges',
  'extraCharges',
  'discounts',
  'taxes',
  'paymentProcessingFee',
  'commission',
  'commissionTax',
  'cancellationFee',
  'accommodationManagementFee',
  'cleaningManagementFee',
  'totalManagementFee',
  'grossRevenue',
  'netRevenue',
  'balance',
  'upsellsTotal',
];

/**
 * @description table cell component. does formatting for the date, time, badge and link fields
 */
export default class UiDashboardTableCellComponent extends Component<DashboardTableCellSignature> {
  @cached
  get field(): string {
    return this.args.field;
  }

  @cached
  get value(): unknown {
    return this.occasion[this.field];
  }

  @cached
  get occasion(): OccasionModel {
    return this.args.occasion;
  }

  @cached
  get statusBadge(): string {
    switch (this.value) {
      case OccasionStatus.needsCheckOut:
      case OccasionStatus.needsCheckIn:
      case OccasionStatus.cancelled:
      case OccasionStatus.pending:
      case OccasionStatus.timedout:
      case OccasionStatus.externallyRemoved:
      case OccasionStatus.expired:
      case OccasionStatus.notPossible:
      case OccasionStatus.declined:
      case OccasionStatus.active:
      case PaymentStatus.pending:
      case PaymentStatus.failing:
      case SecurityDepositStatus.failing:
      case SecurityDepositStatus.needsPaymentInformation:
      case GuestIdentityVerificationStatus.processing:
      case BookingRentalAgreementStatus.pending:
      case BookingRentalAgreementStatus.processing:
        return 'badge-warning';

      case OccasionStatus.denied:
      case OccasionStatus.rejected:
      case PaymentStatus.notPaid:
      case PaymentStatus.failed:
      case SecurityDepositStatus.failed:
      case GuestIdentityVerificationStatus.requiresAction:
      case GuestIdentityVerificationStatus.requiresInput:
        return 'badge-error';
      default:
        return 'badge-success';
    }
  }

  @cached
  get idFormatted(): string {
    return (this.value as string).replace('booking-', '');
  }

  @cached
  get isValuePresent(): boolean {
    return !isNone(this.value);
  }

  @cached
  get isBadgeField(): boolean {
    return badgeFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get isLinkField(): boolean {
    return linkFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get isDateField(): boolean {
    return dateFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get isTimeField(): boolean {
    return timeFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get isCurrencyField(): boolean {
    return currencyFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get dateFormat(): string | undefined {
    if (!this.args.showYear) {
      return;
    }

    return 'EEE, MMM do yyyy';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Dashboard::TableCell': typeof UiDashboardTableCellComponent;
  }
}
