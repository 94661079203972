import Model, { attr, belongsTo } from '@ember-data/model';
import {
  IAirbnbOfficialLinkSchema,
  AirbnbOfficialLinkStatus,
} from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';
import { cached } from '@glimmer/tracking';

export default class AirbnbOfficialLinkModel
  extends Model
  implements IAirbnbOfficialLinkSchema
{
  @attr('string') status?: AirbnbOfficialLinkStatus;

  @belongsTo('property', { inverse: 'airbnbOfficialLink', async: false })
  property!: PropertyModel;

  @cached
  get readOnly(): boolean {
    return this.status === AirbnbOfficialLinkStatus.readOnly;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'airbnb-official-link': AirbnbOfficialLinkModel;
  }
}
