import {
  FilterId,
  FilterOptionsType,
  SingleSelectFilter,
} from 'uplisting-frontend/utils/filters/abstract';
import { UpsellOrderStatus } from 'uplisting-frontend/models/schemas';

export class UpsellOrderStatusFilter extends SingleSelectFilter<void> {
  id = FilterId.upsellOrderStatus;
  optionsType = FilterOptionsType.list;
  optionsList = [
    UpsellOrderStatus.pending,
    UpsellOrderStatus.failed,
    UpsellOrderStatus.paid,
    UpsellOrderStatus.requested,
    UpsellOrderStatus.rejected,
    UpsellOrderStatus.fulfilled,
    UpsellOrderStatus.refunded,
  ];
}
