export default {
  "navigation-search": "_navigation-search_xq3yeg",
  "action-button": "_action-button_xq3yeg",
  "clients-list": "_clients-list_xq3yeg",
  "table-body": "_table-body_xq3yeg",
  "table-row": "_table-row_xq3yeg",
  "client-initials": "_client-initials_xq3yeg",
  "client-status": "_client-status_xq3yeg",
  "status-dot": "_status-dot_xq3yeg"
};
