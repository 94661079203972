import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import ClientModel from 'uplisting-frontend/models/client';
import { IClientPropertySchema } from 'uplisting-frontend/models/schemas';

export default class ClientPropertyModel
  extends Model
  implements IClientPropertySchema
{
  @attr('string') name!: string;

  @belongsTo('client', { inverse: 'clientProperties', async: false })
  client!: ClientModel;

  @cached
  get hasOwner(): boolean {
    return !!this.client?.id;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'client-property': ClientPropertyModel;
  }
}
