import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { utcDate } from 'uplisting-frontend/utils';

interface IArgs {
  value: Date | undefined;
  allowFuture?: boolean;
  onDateSelect(newDate: Date): void;
}

interface PickerMonthSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export interface IItemConfig {
  year: number;
  date: Date;
  allowFuture: boolean;
  onClick(month: number): void;
}

export default class UiPickerMonthComponent extends Component<PickerMonthSignature> {
  @cached @tracked selectedDate = this.args.value ?? new Date();
  @cached @tracked activeYear = this.selectedDate.getUTCFullYear();

  @cached
  get allowFuture(): boolean {
    return this.args.allowFuture ?? false;
  }

  @cached
  get itemConfig(): IItemConfig {
    return {
      year: this.activeYear,
      date: this.selectedDate,
      allowFuture: this.allowFuture,
      onClick: this.handleMonthClick.bind(this),
    };
  }

  @action
  handleDecreaseYear(e: MouseEvent): void {
    e.preventDefault();

    this.activeYear--;
  }

  @action
  handleIncreaseYear(e: MouseEvent): void {
    e.preventDefault();

    this.activeYear++;
  }

  @action
  handleMonthClick(month: number): void {
    this.selectedDate = utcDate(new Date(this.activeYear, month));

    this.args.onDateSelect(this.selectedDate);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Picker::Month': typeof UiPickerMonthComponent;
  }
}
