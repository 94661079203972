import Model, { attr, belongsTo } from '@ember-data/model';
import { IAddressSchema } from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';

export default class AddressModel extends Model implements IAddressSchema {
  @attr('string') fullAddress!: string;
  @attr('string') street!: string;
  @attr('string') suite!: string;
  @attr('string') city!: string;
  @attr('string') state!: string;
  @attr('string') zipCode!: string;
  @attr('string') country!: string;
  @attr('number') latitude!: number;
  @attr('number') longitude!: number;

  @belongsTo('property', {
    inverse: null,
    async: false,
  })
  property!: PropertyModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    address: AddressModel;
  }
}
