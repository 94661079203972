import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';

interface IArgs {
  label: string;
  placeholder: string;
  value: string;
  sidePlaceholder?: string;
  onChange?(value: string): void;
}

interface InputGroupSignature {
  Element: HTMLInputElement;

  Args: IArgs;
}

export default class UiInputGroupComponent extends Component<InputGroupSignature> {
  @cached
  get label(): string {
    return this.args.label;
  }

  @cached
  get placeholder(): string {
    return this.args.placeholder ?? '';
  }

  @cached
  get sidePlaceholderPresent(): boolean {
    return !!this.args.sidePlaceholder;
  }

  @action
  handleInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    this.args.onChange?.(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::InputGroup': typeof UiInputGroupComponent;
  }
}
