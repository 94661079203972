import {
  validatePresence,
  validateLength,
  validateNumber,
  validateInclusion,
} from 'ember-changeset-validations/validators';
import {
  OccasionEvent,
  OccasionPreposition,
  OccasionTermUnit,
} from 'uplisting-frontend/models/schemas';
import {
  validateUniqueness,
  customMessageValidator,
} from 'uplisting-frontend/validators';

const availabilityFilterSchema = {
  term: validateNumber({ gte: 0 }),
  term_unit: validateInclusion({ list: Object.keys(OccasionTermUnit) }),
  preposition: validateInclusion({ list: Object.keys(OccasionPreposition) }),
  event: validateInclusion({ list: Object.keys(OccasionEvent) }),
};

export const UpsellValidation = {
  name: [validatePresence(true), validateLength({ min: 1, max: 500 })],
  nickname: [
    validatePresence(true),
    validateLength({ min: 1, max: 500 }),
    customMessageValidator('unique_upsell_nickname', validateUniqueness()),
  ],
  description: [validatePresence(true), validateLength({ min: 1, max: 1500 })],
  currency: validatePresence(true),
  price: validateNumber({ gte: 0 }),
  imageUrl: customMessageValidator('image_present', validatePresence(true)),
  from: availabilityFilterSchema,
  to: availabilityFilterSchema,
};
