import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ReviewStatisticModel from 'uplisting-frontend/models/review-statistic';

export default class ReviewStatisticRepositoryService extends BaseRepositoryService<ReviewStatisticModel> {
  recordName = 'review-statistic';
  implementMethods = ['query'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/review-statistic': ReviewStatisticRepositoryService;
  }
}
