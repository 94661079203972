import Model, { attr, belongsTo } from '@ember-data/model';
import {
  IDirectListingSchema,
  DirectBookingTypes,
} from 'uplisting-frontend/models/schemas';
import PhotoModel from 'uplisting-frontend/models/photo';
import BaseRateModel from 'uplisting-frontend/models/base-rate';
import { cached } from '@glimmer/tracking';

export default class DirectListingModel
  extends Model
  implements IDirectListingSchema
{
  @attr('string') nickname!: string;
  @attr('string') directBookingUrl!: string;
  @attr('string') directBookingType!: DirectBookingTypes;
  @attr('boolean', { defaultValue: true }) showOnDirectBookingEngine!: boolean;
  @belongsTo('photo', {
    inverse: null,
    async: false,
  })
  photo?: PhotoModel;

  @belongsTo('base-rate', {
    inverse: null,
    async: false,
  })
  baseRate?: BaseRateModel;

  @cached
  get instantBookable(): boolean {
    return this.directBookingType === DirectBookingTypes.instantBook;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'direct-listing': DirectListingModel;
  }
}
