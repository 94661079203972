import BaseController from 'uplisting-frontend/pods/base/controller';
import BookingModel from 'uplisting-frontend/models/booking';
import { cached, tracked } from '@glimmer/tracking';
import ContextualReplyModel from 'uplisting-frontend/models/contextual-reply';

export default class ActionBookingsMessagesController extends BaseController {
  queryParams = [
    {
      replyId: 'reply',
    },
  ];

  @cached @tracked replyId?: string;

  @cached @tracked booking!: BookingModel;
  @cached @tracked reply?: ContextualReplyModel;

  transitionForSavedReplies = 'action.bookings.replies';
  transitionForSaveReply = 'action.bookings.replies.new';
  transitionForEdit = 'action.bookings.details';
}
