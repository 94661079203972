import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { Filter } from 'uplisting-frontend/utils/filters/types';

interface IArgs {
  filters: Filter[];
  onFilterUpdate(filter?: Filter): void;
}

interface FiltersSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    filtersList: [];
    filtersActions: [];
  };
}

export default class UiFiltersComponent extends Component<FiltersSignature> {
  @cached
  get filters(): Filter[] {
    return this.args.filters;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Filters': typeof UiFiltersComponent;
  }
}
