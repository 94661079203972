import Component from '@glimmer/component';
import BookingModel from 'uplisting-frontend/models/booking';
import MessageModel from 'uplisting-frontend/models/message';
import { cached } from '@glimmer/tracking';

interface IArgs {
  booking: BookingModel;
  message: MessageModel;
  saveReplyRoute: string;
}

export interface BookingMessagesMessageSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

type MessageComponent =
  | 'ui/booking/messages/message/inbound'
  | 'ui/booking/messages/message/outbound';

export default class UiBookingMessagesMessageComponent extends Component<BookingMessagesMessageSignature> {
  @cached
  get messageComponent(): MessageComponent {
    if (this.args.message.inbound) {
      return 'ui/booking/messages/message/inbound';
    }

    return 'ui/booking/messages/message/outbound';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Messages::Message': typeof UiBookingMessagesMessageComponent;
  }
}
