import UpsellModel from 'uplisting-frontend/models/upsell';
import BookingModel from 'uplisting-frontend/models/booking';

export enum UpsellOrderStatus {
  pending = 'pending',
  failed = 'failed',
  paid = 'paid',
  requested = 'requested',
  rejected = 'rejected',
  fulfilled = 'fulfilled',
  refunded = 'refunded',
}

export interface IUpsellOrderSchema {
  createdAt: Date;
  status: UpsellOrderStatus;
  upsell: UpsellModel;
  booking: BookingModel;
}
