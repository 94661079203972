import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ManagementFeeRuleModel from 'uplisting-frontend/models/management-fee-rule';
import { ManagementFeeRuleValidation } from 'uplisting-frontend/validations';
import { IErrorResult } from 'uplisting-frontend/services/http-client';
import { type Registry as Services, inject as service } from '@ember/service';

interface ICalculateSample {
  formula: string;
}

export interface ICalculateSampleResult {
  formula: string;
  amount: number;
  variables: {
    accommodation_total: number;
    total_payout: number;
  };
}

interface ICalculateSampleResponse {
  data: {
    attributes: ICalculateSampleResult;
  };
}

export default class ManagementFeeRuleRepositoryService extends BaseRepositoryService<ManagementFeeRuleModel> {
  @service httpClient!: Services['http-client'];

  recordName = 'management-fee-rule';
  implementMethods = [
    'findAll',
    'createRecord',
    'buildChangeset',
    'findRecord',
    'rollback',
    'destroyRecord',
    'updateRecord',
  ];

  validation = ManagementFeeRuleValidation;

  public async calculateSample(
    data: ICalculateSample,
  ): Promise<ICalculateSampleResult | IErrorResult> {
    const response =
      await this.httpClient.postJSONApi<ICalculateSampleResponse>(
        'management_fee_rule_samples',
        data,
      );

    if ((response as IErrorResult).isError) {
      return response as IErrorResult;
    }

    return (response as ICalculateSampleResponse).data.attributes;
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/management-fee-rule': ManagementFeeRuleRepositoryService;
  }
}
