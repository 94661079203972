import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import { FilterId } from 'uplisting-frontend/utils/filters/abstract';
import { Filter } from 'uplisting-frontend/utils/filters/types';
import { action } from '@ember/object';
import AutomatedMessageRuleModel from 'uplisting-frontend/models/automated-message-rule';
import OutboundMessagesController from 'uplisting-frontend/pods/outbound/messages/controller';

export default class OutboundMessagesIndexController extends BaseController {
  @service('filters') filtersService!: Services['filters'];

  @controller('outbound/messages')
  outboundMessagesController!: OutboundMessagesController;

  queryParams = ['propertyIds'];

  @cached @tracked propertyIds: string[] = [];

  @cached @tracked rules!: AutomatedMessageRuleModel[];

  @cached
  get filters(): Filter[] {
    return [
      this.filtersService.createFilterFor(FilterId.property, this.propertyIds),
    ];
  }

  @cached
  get rulesOrdered(): AutomatedMessageRuleModel[] {
    return this.rules.slice().sort((x, y) => x.order - y.order);
  }

  @cached
  get rulesFiltered(): AutomatedMessageRuleModel[] {
    const { rulesOrdered, propertyIds } = this;
    const {
      outboundMessagesController: { event, preposition },
    } = this;

    if (!propertyIds.length && !event && !preposition) {
      return rulesOrdered;
    }

    return rulesOrdered.filter((rule) => {
      const isSameEvent = event ? rule.event === event : true;
      const isSamePreposition = preposition
        ? rule.preposition === preposition
        : true;

      const isSameProperty = propertyIds.length
        ? rule.properties.some((property) => propertyIds.includes(property.id))
        : true;

      return isSameProperty && isSameEvent && isSamePreposition;
    });
  }

  @action
  handleResetFilters(): void {
    this.filters.forEach((filter) => {
      filter.reset();

      this.handleFilterUpdate(filter);
    });
  }

  @action
  handleFilterUpdate(filter: Filter): void {
    this.propertyIds = filter.toQuery();
  }
}
