import Component from '@glimmer/component';
import CustomPropertyAttributeModel from 'uplisting-frontend/models/custom-property-attribute';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';

interface IArgs {
  changeset: GenericChangeset<CustomPropertyAttributeModel>;
  onSave(): Promise<void>;
  onClose(): void;
  onDelete(): void;
}

interface AutomateCustomPropertyAttributeFormSignature {
  Element: HTMLFormElement;

  Args: IArgs;
}

export default class UiAutomateCustomPropertyAttributeFormComponent extends Component<AutomateCustomPropertyAttributeFormSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::CustomPropertyAttribute::Form': typeof UiAutomateCustomPropertyAttributeFormComponent;
  }
}
