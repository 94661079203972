import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import UserModel from 'uplisting-frontend/models/user';

interface IArgs {
  currentUser: UserModel;
}

interface PopoverSettingsMenuSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiPopoverSettingsMenu extends Component<PopoverSettingsMenuSignature> {
  @cached
  get currentUser(): UserModel {
    return this.args.currentUser;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Popovers::SettingsMenu': typeof UiPopoverSettingsMenu;
  }
}
