import {
  validatePresence,
  validateNumber,
  validateInclusion,
} from 'ember-changeset-validations/validators';
import {
  OccasionEvent,
  OccasionPreposition,
  OccasionTermUnit,
} from 'uplisting-frontend/models/schemas';

export const AutomatedMessageRuleValidation = {
  name: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),
  content: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),
  term: validateNumber({ gte: 0, integer: true, allowBlank: false }),
  termUnit: validateInclusion({ list: Object.keys(OccasionTermUnit) }),
  preposition: validateInclusion({ list: Object.keys(OccasionPreposition) }),
  event: validateInclusion({ list: Object.keys(OccasionEvent) }),
};
