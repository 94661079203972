import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';

interface IArgs<T> {
  items: T[];
  labelProperty: keyof T;
  selected: T[];
  enableSelectAll?: boolean;
  isAllSelected?: boolean;
  onClick(item: T): void;
  onAllSelect?(): void;
  computeIsDisabled?(item: T): boolean;
  computeDisabledText?(item: T): string;
}

interface MultiselectSignature<T> {
  Element: HTMLDivElement;

  Args: IArgs<T>;
}

export default class UiMultiselectComponent<T> extends Component<
  MultiselectSignature<T>
> {
  @service intl!: Services['intl'];

  @cached
  get items(): T[] {
    return this.args.items;
  }

  @cached
  get selectAllItem(): object {
    const textField = this.isAllSelected
      ? 'multiselect.unselect_all'
      : 'multiselect.select_all';

    return {
      [this.labelProperty]: this.intl.t(textField),
    };
  }

  @cached
  get enableSelectAll(): boolean {
    return this.args.enableSelectAll ?? false;
  }

  @cached
  get isAllSelected(): boolean {
    return this.args.isAllSelected ?? false;
  }

  @cached
  get labelProperty(): keyof T {
    return this.args.labelProperty;
  }

  @cached
  get selected(): T[] {
    return this.args.selected;
  }

  @action
  handleItemClick(value: T): void {
    if (this.computeIsDisabled(value)) {
      return;
    }

    this.args.onClick(value);
  }

  @action
  computeIsDisabled(item: T): boolean {
    return this.args.computeIsDisabled?.(item) ?? false;
  }

  @action
  computeSelected(item: T): boolean {
    return this.selected.includes(item);
  }

  @action
  computeIsAllSelected(): boolean {
    return this.isAllSelected;
  }

  @action
  handleSelectAllClick(): void {
    this.args.onAllSelect?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Multiselect': typeof UiMultiselectComponent;
  }
}
