import BaseRoute from 'uplisting-frontend/pods/base/route';
import ConnectCallbackEdgestateController from 'uplisting-frontend/pods/connect/callback/edgestate/controller';
import Transition from '@ember/routing/transition';

export default class ConnectCallbackEdgestateRoute extends BaseRoute {
  async setupController(
    controller: ConnectCallbackEdgestateController,
    model,
    transition: Transition,
  ): Promise<void> {
    super.setupController(controller, model, transition);

    await controller.handleCallbackResponse();
  }
}
