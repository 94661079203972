import Model, { attr, hasMany } from '@ember-data/model';
import PropertyModel from 'uplisting-frontend/models/property';
import { IManagementFeeRuleSchema } from 'uplisting-frontend/models/schemas';
import { cached, tracked } from '@glimmer/tracking';

export interface IFeeRuleVariable {
  id: string;
  name: string;
}

export const feeRules = [
  'accommodation_total',
  'booking_site_commission_vat',
  'booking_site_commission',
  'booking_taxes',
  'cancellation_fee',
  'cleaning_fee',
  'discounts',
  'extra_charges',
  'extra_guest_charge',
  'payment_processing_fee',
  'upsells',
  'subtotal',
  'total_payout',
];
export const FEE_RULE_VARIABLES: IFeeRuleVariable[] = [];

export default class ManagementFeeRuleModel
  extends Model
  implements IManagementFeeRuleSchema
{
  @attr('string') name!: string;
  @attr('string', { defaultValue: '' }) formula!: string;
  @attr('day') recalculationDate?: Date;

  @hasMany('property', { inverse: 'managementFeeRule', async: false })
  properties!: PropertyModel[];

  @cached @tracked _prettifiedValue!: string;

  @cached
  get prettifiedValue(): string {
    return this._prettifiedValue ?? this.toPrettified();
  }

  set prettifiedValue(value: string) {
    this._prettifiedValue = value;
  }

  public toPrettified(): string {
    let result = this.formula;

    for (const variable of FEE_RULE_VARIABLES) {
      result = result.replaceAll(variable.id, `{{${variable.name}}}`);
    }

    return result;
  }

  public fromPrettified(): string {
    let result = this.formula;

    for (const variable of FEE_RULE_VARIABLES) {
      result = result.replaceAll(`{{${variable.name}}}`, variable.id);
    }

    return result;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'management-fee-rule': ManagementFeeRuleModel;
  }
}
