import PropertyModel from 'uplisting-frontend/models/property';

export enum PaymentRuleChannel {
  uplisting = 'uplisting',
  homeAway = 'home_away',
}

export enum PaymentRulePreposition {
  before = 'before',
  after = 'after',
}

export enum PaymentRuleEvent {
  checkIn = 'check_in',
  checkOut = 'check_out',
}

export interface IPaymentRuleSchema {
  channel: PaymentRuleChannel;
  default: boolean;
  event: PaymentRuleEvent;
  immediateChargePercentage: number;
  preposition: PaymentRulePreposition;
  remainingBalanceChargeDays: number;

  properties: PropertyModel[];
}
