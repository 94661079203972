import ApplicationSerializer from 'uplisting-frontend/serializers/application';

export default class ManagementFeeRuleSerializer extends ApplicationSerializer {
  // we need to include `properties` records when updating/creating fee rules
  shouldSerializeHasMany() {
    return true;
  }
}

declare module 'ember-data/types/registries/serializer' {
  interface SerializerRegistry {
    'management-fee-rule': ManagementFeeRuleSerializer;
  }
}
