import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import { isSameDay } from 'date-fns/isSameDay';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';

interface IArgs {
  item: BookingModel;
  date: string;
}

interface ActionTableRowBookingSignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiActionTableRowBookingComponent extends Component<ActionTableRowBookingSignature> {
  @service router!: Services['router'];

  @cached
  get item(): BookingModel {
    return this.args.item;
  }

  @cached
  get date(): string {
    return this.args.date;
  }

  @cached
  get isCheckIn(): boolean {
    return this.isOfType('checkIn');
  }

  @cached
  get isCheckOut(): boolean {
    return this.isOfType('checkOut');
  }

  @action
  async handleItemClick(): Promise<void> {
    await this.router.transitionTo('action.bookings.summary', this.item.id);
  }

  private isOfType(field: 'checkIn' | 'checkOut'): boolean {
    return isSameDay(this.date, new Date(this.item[field]));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Row::Booking': typeof UiActionTableRowBookingComponent;
    'ui/action/table/row/booking': typeof UiActionTableRowBookingComponent;
  }
}
