import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { ActionDataFormat } from 'uplisting-frontend/pods/action/controller';

interface IArgs {
  data: ActionDataFormat;
}

interface ActionTableSignature {
  Element: HTMLTableElement;

  Args: IArgs;
}

export default class UiActionTableComponent extends Component<ActionTableSignature> {
  @cached
  get data(): ActionDataFormat {
    return this.args.data;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table': typeof UiActionTableComponent;
  }
}
