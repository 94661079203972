import Model, { attr } from '@ember-data/model';
import { ICustomerCancellationSchema } from 'uplisting-frontend/models/schemas';

export default class CustomerCancellationModel
  extends Model
  implements ICustomerCancellationSchema
{
  @attr('string') customerId!: string;
  @attr('string') hmac!: string;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'customer-cancellation': CustomerCancellationModel;
  }
}
