import BaseRoute from 'uplisting-frontend/pods/base/route';
import { type Registry as Services, inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class ClientsManagementFeeRulesIndexRoute extends BaseRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/management-fee-rule')
  managementFeeRuleRepository!: Services['repositories/management-fee-rule'];

  model() {
    return RSVP.hash({
      managementFeeRules: this.managementFeeRuleRepository.findAll(),
      properties: this.propertyRepository.findAll(),
    });
  }
}
