import Component from '@glimmer/component';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import InvitationModel from 'uplisting-frontend/models/invitation';
import { InvitationRole } from 'uplisting-frontend/models/schemas';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { capitalize } from '@ember/string';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IArgs {
  role: InvitationRole;
  showMultiUnits?: boolean;
}

interface InvitationFormSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiInvitationFormComponent extends Component<InvitationFormSignature> {
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];
  @service('repositories/invitation')
  invitationRepository!: Services['repositories/invitation'];

  @cached @tracked isEditing = false;
  @cached @tracked changeset!: GenericChangeset<InvitationModel>;

  @cached
  get role(): InvitationRole {
    return this.args.role;
  }

  @cached
  get isCollapsed(): boolean {
    return !this.isEditing;
  }

  @action
  handleEdit(): void {
    this.isEditing = true;

    const record = this.invitationRepository.createRecord({
      role: this.role,
    });

    this.changeset = this.invitationRepository.buildChangeset(record);
  }

  @action
  handleCancel(): void {
    this.isEditing = false;

    this.unloadNewRecord();
  }

  @action
  async handleFormSubmit(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      this.isEditing = false;
      this.analytics.trackEvent(
        AnalyticsEvents['invitationSent' + capitalize(this.role)],
      );
      this.notifications.info('account_team.notifications.invitation_sent');
    } catch {
      this.isEditing = true;
      this.notifications.error();
    }
  }

  private unloadNewRecord(): void {
    if (this.changeset && !this.changeset.id) {
      this.changeset.data.unloadRecord();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Invitation::Form': typeof UiInvitationFormComponent;
  }
}
