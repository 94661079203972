import Component from '@glimmer/component';

interface IArgs {
  title: string;
  description: string | number;
}

interface CardSectionSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiCardSectionComponent extends Component<CardSectionSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::CardSection': typeof UiCardSectionComponent;
  }
}
