import Model, { attr } from '@ember-data/model';
import { IDirectBookingConfigurationSchema } from 'uplisting-frontend/models/schemas';
import { cached } from '@glimmer/tracking';
import { parse, ParsedDomain } from 'psl';

export enum CustomSubdomainOptions {
  book = 'book.',
  stay = 'stay.',
  reserve = 'reserve.',
  reservations = 'reservations.',
  properties = 'properties.',
  www = 'www.',
  custom = 'Custom',
}

export const CUSTOM_SUBDOMAIN_OPTIONS: CustomSubdomainOptions[] = Object.values(
  CustomSubdomainOptions,
) as CustomSubdomainOptions[];

export default class DirectBookingConfigurationModel
  extends Model
  implements IDirectBookingConfigurationSchema
{
  @attr('string') heroHeading!: string;
  @attr('string-or-empty') heroTagline!: string;
  @attr('string-or-empty') descriptionHeading!: string;
  @attr('string-or-empty') aboutContent!: string;
  @attr('string') emailAddress!: string;
  @attr('string') phoneNumber!: string;
  @attr('string') googleAnalyticsKey!: string;
  @attr('string') facebookPixelKey!: string;
  @attr('string') intercomKey!: string;
  @attr('string') googleConversionId!: string;
  @attr('string') googleConversionLabel!: string;
  @attr('string') termsAndConditions!: string;
  @attr('string') customDomain!: string | null;
  @attr('boolean') customDomainVerified!: boolean;
  @attr('string-or-empty') subdomain!: string | null;
  @attr('string') logoUrl!: string;
  @attr('string') logoHref!: string;
  @attr('string') heroImageUrl!: string;
  @attr('string') dnsTarget!: string | null;
  @attr('string') customSubdomainOption!: CustomSubdomainOptions | null;

  @cached
  get hasSubdomain(): boolean {
    return !!this.subdomain;
  }

  @cached
  get hasCustomDomain(): boolean {
    return !!this.customDomain;
  }

  @cached
  get parsedDomain(): ParsedDomain | undefined {
    const { customDomain } = this;

    if (!customDomain) {
      return;
    }

    const url =
      this.customSubdomainOption === CustomSubdomainOptions.custom
        ? customDomain
        : this.customSubdomainOption + customDomain;

    const parsed = parse(url);

    if (parsed.error) {
      return;
    }

    return parsed;
  }

  @cached
  get customDomainCname(): string | null | undefined {
    return this.parsedDomain?.subdomain;
  }

  @cached
  get customDomainLink(): string | null | undefined {
    const { parsedDomain } = this;

    if (!parsedDomain) {
      return;
    }

    return `https://${parsedDomain?.subdomain}.${parsedDomain?.domain}`;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'direct-booking-configuration': DirectBookingConfigurationModel;
  }
}
