import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import PaymentRuleModel from 'uplisting-frontend/models/payment-rule';
import { PaymentRuleValidation } from 'uplisting-frontend/validations';

export default class PaymentRuleRepositoryService extends BaseRepositoryService<PaymentRuleModel> {
  recordName = 'payment-rule';
  implementMethods = [
    'findAll',
    'buildChangeset',
    'createRecord',
    'unloadAll',
    'peekAll',
  ];

  validation = PaymentRuleValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/payment-rule': PaymentRuleRepositoryService;
  }
}
