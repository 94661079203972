import Component from '@glimmer/component';
import { type Registry as Services, inject as service } from '@ember/service';
import ChannelCommissionModel from 'uplisting-frontend/models/channel-commission';
import { ChannelCommissionChannel } from 'uplisting-frontend/models/schemas';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isSaveDisabled } from 'uplisting-frontend/utils';
import numeral from 'numeral';
import { ref } from 'ember-ref-bucket';

interface IArgs {
  changeset: GenericChangeset<ChannelCommissionModel>;
}

interface AutomateChannelCommissionSignature {
  Element: HTMLFormElement;

  Args: IArgs;

  Blocks: {
    alertDescription?: [];
  };
}

const formatRate = (rate: number, format = '0'): string =>
  numeral(rate).format(format);

export default class UiAutomateChannelCommissionComponent extends Component<AutomateChannelCommissionSignature> {
  @service notifications!: Services['notifications'];

  @ref('rateInput') rateInput!: HTMLInputElement;

  defaultRate = 100;

  @cached @tracked channelRate!: number;

  @cached
  get changeset(): GenericChangeset<ChannelCommissionModel> {
    return this.args.changeset;
  }

  @cached
  get channelLogo(): string {
    switch (this.changeset.channel) {
      case ChannelCommissionChannel.bookingDotCom:
        return 'booking-dot-com';
      case ChannelCommissionChannel.airbnbOfficial:
        return 'airbnb-partner';
      case ChannelCommissionChannel.homeAway:
        return 'home-away';
      case ChannelCommissionChannel.google:
        return 'google';
      case ChannelCommissionChannel.direct:
        return 'direct';
    }
  }

  @cached
  get channelRateFormatted(): string {
    return formatRate(this.channelRate, '0.00');
  }

  @cached
  get guestEstimateRateFormatted(): string {
    const { channelRate } = this;

    switch (this.changeset.channel) {
      case ChannelCommissionChannel.airbnbOfficial:
        return `${formatRate(channelRate)} - ${formatRate(channelRate * 1.2)}`;
      case ChannelCommissionChannel.homeAway:
        return `${formatRate(channelRate * 1.04)} - ${formatRate(channelRate * 1.09)}`;
      default:
        return formatRate(channelRate);
    }
  }

  @action
  handleCalculateRates(): void {
    const rate = Number(this.rateInput?.value) || this.defaultRate;

    this.channelRate = rate * (1 + this.changeset.amount / 100);
  }

  @action
  async handleFormSubmit(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info('automate_markup.notifications.success');
    } catch {
      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::ChannelCommission': typeof UiAutomateChannelCommissionComponent;
  }
}
