import BaseController from 'uplisting-frontend/pods/base/controller';
import { type Registry as Services, inject as service } from '@ember/service';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import BookingModel from 'uplisting-frontend/models/booking';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { OccasionStatus } from 'uplisting-frontend/models/schemas';

export default class ActionBookingsDetailsController extends BaseController {
  @service('repositories/booking')
  bookingRepository!: Services['repositories/booking'];

  @cached @tracked booking!: BookingModel;

  @cached @tracked showCancelModal = false;
  @cached @tracked showDeleteModal = false;

  @cached
  get changeset(): GenericChangeset<BookingModel> {
    return this.bookingRepository.buildChangeset(this.booking);
  }

  @cached
  get canCancel(): boolean {
    return !this.booking.imported;
  }

  @cached
  get canDelete(): boolean {
    if (this.booking.deletable === false) {
      return false;
    }

    return this.canCancel;
  }

  @action
  async handleSaveBooking(): Promise<void> {
    try {
      await this.changeset.save();

      this.notifications.info('notifications.applied');
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleCancelBooking(): Promise<void> {
    this.changeset.status = OccasionStatus.cancelled;

    await this.changeset.save();

    await this.redirectAfterAction();
  }

  @action
  async handleDeleteBooking(): Promise<void> {
    await this.booking.destroyRecord();

    await this.redirectAfterAction();
  }

  private async redirectAfterAction(): Promise<void> {
    await this.router.transitionTo('action.index');
  }
}
