import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import PermissionModel from 'uplisting-frontend/models/permission';
import { cached } from '@glimmer/tracking';
import { UserRoles, IUserSchema } from 'uplisting-frontend/models/schemas';
import ClientModel from 'uplisting-frontend/models/client';
import CustomerModel from 'uplisting-frontend/models/customer';

function findPermissionForRoute(
  permissions: PermissionModel[],
  route: string,
): PermissionModel | undefined {
  return permissions.find(
    (item) => item.type === 'route' && item.permission === route,
  );
}

export function findPermissionForAction(
  permissions: PermissionModel[],
  action: string,
): PermissionModel | undefined {
  return permissions.find(
    (item) => item.type === 'action' && item.permission === action,
  );
}

function hasPageAccess(permissions: PermissionModel[], route: string): boolean {
  const permission = findPermissionForRoute(permissions, route);

  if (!permission) {
    return false;
  }

  return permission.permitted;
}

function hasActionAccess(
  permissions: PermissionModel[],
  action: string,
): boolean {
  const permission = findPermissionForAction(permissions, action);

  if (!permission) {
    return false;
  }

  return permission.permitted;
}

export interface IUserJson {
  role: UserRoles;
  userHash: string;
}

export default class UserModel extends Model implements IUserSchema {
  @attr('string') name!: string;
  @attr('string') email!: string;
  @attr('string') role!: UserRoles;
  @attr('string') userHash!: string;
  @attr('boolean') trialist!: boolean;
  @attr('number') unreadMessages!: number;
  @attr('boolean') lapsed!: boolean;
  @attr('number') pendingAlerts!: number;

  @belongsTo('client', { inverse: 'users', async: false })
  client!: ClientModel;

  @belongsTo('customer', { inverse: 'user', async: false })
  customer?: CustomerModel;

  @hasMany('permission', { inverse: 'user', async: false })
  permissions!: PermissionModel[];

  @cached
  get isAccountOwner(): boolean {
    return this.role === UserRoles.accountOwner;
  }

  @cached
  get isHousekeeper(): boolean {
    return this.role === UserRoles.housekeeper;
  }

  @cached
  get isHost(): boolean {
    return this.role === UserRoles.host;
  }

  @cached
  get isOwner(): boolean {
    return this.role === UserRoles.owner;
  }

  @cached
  get isHostOrAccountOwner(): boolean {
    return this.isHost || this.isAccountOwner;
  }

  @cached
  get isOwnerOrHousekeeper(): boolean {
    return this.isOwner || this.isHousekeeper;
  }

  @cached
  get canSortProperties(): boolean {
    return this.isAccountOwner;
  }

  public hasAccessToPage(page: string): boolean {
    return hasPageAccess(this.permissions, page);
  }

  public hasAccessToAction(action: string): boolean {
    return hasActionAccess(this.permissions, action);
  }

  public toObject(): IUserJson {
    return {
      role: this.role,
      userHash: this.userHash,
    };
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    user: UserModel;
  }
}
