import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import Model from '@ember-data/model';

interface IArgs<T> {
  connection: T;
  properties?: PropertyModel[];
  onConnectionRemove?(connection: T): void;
  onSave?(): Promise<void>;
}

interface ConnectionsIndexSignature<T> {
  Element: HTMLDivElement;

  Args: IArgs<T>;
}

export interface ILockOptionCustom {
  id: null;
  forLockable: string;
}

export type LockOption = MultiUnitModel | PropertyModel;
export type LockOptions = (ILockOptionCustom | LockOption)[];

export default abstract class UiConnectionsIndexComponent<
  T extends Model,
> extends Component<ConnectionsIndexSignature<T>> {
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];

  @cached @tracked isEditing = false;
  @cached @tracked isRemovingConnection = false;
  @cached @tracked showRemoveConnectionModal = false;

  @cached
  get connection(): T {
    return this.args.connection;
  }

  @cached
  get properties(): PropertyModel[] {
    const properties = this.args.properties || [];

    return properties.slice();
  }

  @cached
  get lockOptions(): LockOptions {
    return this.properties
      .map((property) => [property, ...property.multiUnits.slice()])
      .flat();
  }

  @action
  async handleRemoveConnection(
    infoKey: string,
    event: AnalyticsEvents,
  ): Promise<void> {
    this.isRemovingConnection = true;

    try {
      await this.connection.destroyRecord();

      this.notifications.info(infoKey);
      this.analytics.trackEvent(event);

      this.args.onConnectionRemove?.(this.connection);
    } catch {
      this.notifications.error();
    } finally {
      this.isRemovingConnection = false;
    }
  }
}
