import { cached, tracked } from '@glimmer/tracking';
import StoreService from '@ember-data/store';
import ModelRegistry from 'ember-data/types/registries/model';
import { isPresent } from '@ember/utils';
import DataModel from '@ember-data/model';

export enum FilterComponent {
  multiSelect = 'multi-select',
  singleSelect = 'single-select',
  month = 'month',
}

export enum FilterOptionsType {
  store = 'store',
  list = 'list',
}

export enum FilterId {
  clientName = 'client_name',
  property = 'property',
  period = 'period',
  clientStatementStatus = 'client_statement_status',
  upsellOrderStatus = 'upsell_order_status',
}

/**
 * @description Filter params structure:
 * - params have value(can be string(for a single select filter), array of strings(for a multi select filter) and so on)
 * - params have `updateValue` method, the method correctly updates the `value` field
 */
export interface IBaseFilterParams<Value, UpdateValue> {
  value?: Value;

  // eslint-disable-next-line no-use-before-define
  filter: IBaseFilter<Value, UpdateValue>;
  selectedRecords?: unknown[];
  updateValue(value: UpdateValue): void;
  toQuery(): unknown;
  reset(): void;
}

/**
 * @description Filter structure:
 * - filter has component(all options are listed in the `FilterComponent` enum)
 * - filter has params(defined above)
 */
interface IBaseFilter<Value, UpdateValue> {
  component: FilterComponent;
  params: IBaseFilterParams<Value, UpdateValue>;
  updateValue(value: UpdateValue): void;
  toQuery(): unknown;
  reset(): void;
}

/**
 * @description Value - is the type of params value(can be string, array of strings, and so on)
 * UpdateValue - is the type of the `updateValue` argument(can be string, boolean, and so on)
 * Model - is the potential model name, for the filters that are taking their options list from the ember data store
 */
export abstract class BaseAbstractFilter<Value, UpdateValue>
  implements IBaseFilter<Value, UpdateValue>
{
  store!: StoreService;

  abstract id: FilterId;
  abstract component: FilterComponent;
  abstract optionsType: FilterOptionsType;

  modelName?: keyof ModelRegistry;
  modelField?: keyof DataModel;
  optionsList?: UpdateValue[];

  constructor(params: IBaseFilterParams<Value, UpdateValue>) {
    this.params = params;
    this.params.filter = this;
  }

  @cached @tracked params!: IBaseFilterParams<Value, UpdateValue>;

  @cached
  get models(): UpdateValue[] {
    return this.store.peekAll(this.modelName as keyof ModelRegistry).slice();
  }

  @cached
  get options(): UpdateValue[] {
    switch (this.optionsType) {
      case FilterOptionsType.list:
        return this.optionsList as UpdateValue[];
      case FilterOptionsType.store:
        return this.models;
      default:
        throw new Error(
          `unknown filter options type, expected to be 'FilterOptionsType', got ${this.optionsType}`,
        );
    }
  }

  public updateValue(value: UpdateValue): void {
    this.params.updateValue(value);
  }

  public toQuery<T>(): T {
    return this.params.toQuery() as T;
  }

  public reset(): void {
    this.params.reset();
  }
}

/**
 * @description Creating a base params class, so that other params classes would extend from the base one
 * defines that a instance of a params class, will have a parent reference to the filter
 */
export abstract class BaseAbstractFilterParams<Value> {
  filter;

  @cached @tracked value?: Value;

  constructor(value?: Value) {
    if (isPresent(value)) {
      this.value = value as Value;
    }
  }
}
