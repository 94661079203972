import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import PropertyManagementFeeSettingModel from 'uplisting-frontend/models/property-management-fee-setting';
import { PropertyManagementFeeSettingValidation } from 'uplisting-frontend/validations';

export default class PropertyManagementFeeSettingRepositoryService extends BaseRepositoryService<PropertyManagementFeeSettingModel> {
  recordName = 'property-management-fee-setting';
  implementMethods = ['buildChangeset', 'findAll', 'unloadAll'];

  validation = PropertyManagementFeeSettingValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/property-management-fee-setting': PropertyManagementFeeSettingRepositoryService;
  }
}
