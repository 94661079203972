import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import AutomatedReviewTemplateModel from 'uplisting-frontend/models/automated-review-template';
import { AutomatedReviewTemplateValidation } from 'uplisting-frontend/validations';

export default class AutomatedMessageRuleRepositoryService extends BaseRepositoryService<AutomatedReviewTemplateModel> {
  recordName = 'automated-review-template';
  implementMethods = ['findAll', 'createRecord', 'buildChangeset', 'unloadAll'];

  validation = AutomatedReviewTemplateValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/automated-review-template': AutomatedMessageRuleRepositoryService;
  }
}
