import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import UpsellsController from 'uplisting-frontend/pods/upsells/controller';
import UpsellsOrdersController from 'uplisting-frontend/pods/upsells/orders/controller';
import { UpsellOrderStatus } from 'uplisting-frontend/models/schemas';
import Transition from '@ember/routing/transition';

interface IParams {
  nickname: string;
  guestName: string;
  status?: UpsellOrderStatus;
  propertyIds: string[];
}

export default class UpsellsIndexRoute extends PermittedRoute {
  permission = 'upsell_orders.index';

  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    const controller = this.controllerFor('upsells') as UpsellsController;

    controller.resetState();
  }

  model(params: IParams) {
    const controller = this.controllerFor(
      'upsells/orders',
    ) as UpsellsOrdersController;

    Object.assign(controller, params);

    return controller.handleFetchData();
  }

  resetController(controller: UpsellsOrdersController): void {
    controller.resetState();
  }
}
