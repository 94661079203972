import BaseController from 'uplisting-frontend/pods/base/controller';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';

export default class ConnectCallbackOfficialController extends BaseController {
  @service analytics!: Services['analytics'];

  queryParams = ['code'];
  code;

  public async handleCallbackResponse(): Promise<void> {
    const { code } = this;

    if (!code) {
      return;
    }

    const connection = this.store.createRecord('airbnb-official-upgrade', {
      code,
      accessLevel: 'full',
    });

    try {
      await connection.save();

      this.analytics.trackEvent(AnalyticsEvents.airbnbConnectionUpgraded);

      await this.openConnectPage();
    } catch {
      await this.openConnectPage({ problem: true });
    }
  }

  private async openConnectPage(queryParams: object = {}): Promise<void> {
    await this.router.transitionTo('connect.airbnb', {
      queryParams,
    });
  }
}
