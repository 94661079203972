export default {
  "header": "_header_xh99nz",
  "header-wrapper": "_header-wrapper_xh99nz",
  "client-avatar": "_client-avatar_xh99nz",
  "client-name": "_client-name_xh99nz",
  "client-company": "_client-company_xh99nz",
  "client-email": "_client-email_xh99nz",
  "header-navigation": "_header-navigation_xh99nz",
  "navigation-left": "_navigation-left_xh99nz",
  "navigation-right": "_navigation-right_xh99nz",
  "navigation-item": "_navigation-item_xh99nz",
  "client-body-wrapper": "_client-body-wrapper_xh99nz"
};
