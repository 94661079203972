import { application } from 'uplisting-frontend/utils';
import ApplicationInstance from '@ember/application/instance';

/**
 * initialize runs upon app instance initialization. It simply set the instance.
 * `application.instance` is used by `app/validations/messages` to lookup the
 * `intl` service so it can translate error messages.
 */
export function initialize(appInstance: ApplicationInstance) {
  application.instance = appInstance;
}

export default {
  initialize,
};
