import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import SecurityDepositSettingModel from 'uplisting-frontend/models/security-deposit-setting';
import { SecurityDepositSettingValidation } from 'uplisting-frontend/validations';

export default class SecurityDepositSettingRepositoryService extends BaseRepositoryService<SecurityDepositSettingModel> {
  recordName = 'security-deposit-setting';
  implementMethods = ['findAll', 'unloadAll', 'peekAll', 'buildChangeset'];

  validation = SecurityDepositSettingValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/security-deposit-setting': SecurityDepositSettingRepositoryService;
  }
}
