export enum ReviewStatisticCategory {
  overallRating = 'overall_rating',
  accuracy = 'accuracy',
  value = 'value',
  cleanliness = 'cleanliness',
  checkIn = 'checkin',
  communication = 'communication',
  location = 'location',
}

export interface IReviewStatisticCategorySchema {
  category: ReviewStatisticCategory;
  month: string;
  rating: number;
}
