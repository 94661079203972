import Model, { attr } from '@ember-data/model';
import { INotificationSettingSchema } from 'uplisting-frontend/models/schemas';

export default class NotificationSettingModel
  extends Model
  implements INotificationSettingSchema
{
  @attr('boolean') dailySummary!: boolean;
  @attr('boolean') seamSyncFailed!: boolean;
  @attr('boolean') upsellPurchase!: boolean;
  @attr('boolean') upsellRequest!: boolean;

  @attr('boolean') changeoverCreated!: boolean;
  @attr('boolean') changeoverUpdated!: boolean;
  @attr('boolean') changeoverDestroyed!: boolean;

  @attr('boolean') bookingCreatedBookingDotCom!: boolean;
  @attr('boolean') bookingCreatedHomeaway!: boolean;
  @attr('boolean') bookingCreatedDirect!: boolean;
  @attr('boolean') bookingUpdatedBookingDotCom!: boolean;
  @attr('boolean') bookingUpdatedHomeaway!: boolean;
  @attr('boolean') bookingCancelledBookingDotCom!: boolean;
  @attr('boolean') bookingCancelledHomeaway!: boolean;

  @attr('boolean') guestMessageHomeaway!: boolean;
  @attr('boolean') guestMessageUplisting!: boolean;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'notification-setting': NotificationSettingModel;
  }
}
