import Model, { attr, hasMany } from '@ember-data/model';
import { IBulkChangeoverSettingSchema } from 'uplisting-frontend/models/schemas';
import ChangeoverSettingModel from 'uplisting-frontend/models/changeover-setting';

export default class BulkChangeoverSettingModel
  extends Model
  implements IBulkChangeoverSettingSchema
{
  @attr('boolean') enabled!: boolean;

  @hasMany('changeover-setting', { inverse: null, async: false })
  changeoverSettings!: ChangeoverSettingModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'bulk-changeover-setting': BulkChangeoverSettingModel;
  }
}
