import { ClientNameFilter } from 'uplisting-frontend/utils/filters/types/client';
import { PropertyFilter } from 'uplisting-frontend/utils/filters/types/property';
import { MonthFilter } from 'uplisting-frontend/utils/filters/types/month';
import { ClientStatementStatusFilter } from 'uplisting-frontend/utils/filters/types/client-statement';
import { UpsellOrderStatusFilter } from 'uplisting-frontend/utils/filters/types/upsell-order';

export type ClientStatementsFilter =
  | ClientNameFilter
  | PropertyFilter
  | ClientStatementStatusFilter
  | MonthFilter;

export type UpsellFilter = PropertyFilter;
export type UpsellOrdersFilter = PropertyFilter | UpsellOrderStatusFilter;

export type Filter =
  | ClientNameFilter
  | PropertyFilter
  | ClientStatementStatusFilter
  | MonthFilter
  | UpsellOrderStatusFilter;

export type MultiSelectFilter = ClientNameFilter | PropertyFilter;
export type SingleSelectFilter =
  | ClientStatementStatusFilter
  | UpsellOrderStatusFilter;
export type DateFilter = MonthFilter;

export * from 'uplisting-frontend/utils/filters/types/client';
export * from 'uplisting-frontend/utils/filters/types/helpers';
export * from 'uplisting-frontend/utils/filters/types/property';
export * from 'uplisting-frontend/utils/filters/types/month';
export * from 'uplisting-frontend/utils/filters/types/client-statement';
export * from 'uplisting-frontend/utils/filters/types/upsell-order';
