import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ContextualReplyModel from 'uplisting-frontend/models/contextual-reply';

export default class ContextualReplyRepositoryService extends BaseRepositoryService<ContextualReplyModel> {
  recordName = 'contextual-reply';
  implementMethods = ['findRecord'];

  public async find(id: string, bookingId: string) {
    return super.findRecord(id, {
      adapterOptions: {
        reference_id: bookingId,
        reference_type: 'booking',
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/contextual-reply': ContextualReplyRepositoryService;
  }
}
