import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import StayModel from 'uplisting-frontend/models/stay';

export default class StayRepositoryService extends BaseRepositoryService<StayModel> {
  recordName = 'stay';
  implementMethods = ['query'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/stay': StayRepositoryService;
  }
}
