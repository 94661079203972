import {
  validatePresence,
  validateFormat,
  validateLength,
} from 'ember-changeset-validations/validators';

export const ClientValidation = {
  name: [validatePresence(true), validateLength({ min: 1, max: 100 })],
  email: validateFormat({ allowBlank: true, type: 'email' }),
  // we are also validating client properties length(at least 1), but that happens on the client creation level
};
