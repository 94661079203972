import BaseRoute from 'uplisting-frontend/pods/base/route';
import ActionBookingsIdentityController from 'uplisting-frontend/pods/action/bookings/identity/controller';
import Transition from '@ember/routing/-private/transition';
import BookingModel from 'uplisting-frontend/models/booking';
import { type Registry as Services, inject as service } from '@ember/service';

export default class ActionBookingsDetailsRoute extends BaseRoute {
  @service('repositories/guest-identity-image')
  guestIdentityImageRepository!: Services['repositories/guest-identity-image'];

  model() {
    const booking = this.modelFor('action.bookings') as BookingModel;

    return this.guestIdentityImageRepository.getRecords(
      booking.guestIdentityVerification.id,
    );
  }

  setupController(
    controller: ActionBookingsIdentityController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { guestIdentityImages: model.slice() });
  }
}
