import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import AccountTaxesController from 'uplisting-frontend/pods/account/taxes/controller';
import Transition from '@ember/routing/transition';

export default class AccountTaxesRoute extends PermittedRoute {
  permission = 'account.taxes';

  model() {
    return this.store.findAll('tax');
  }

  setupController(
    controller: AccountTaxesController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { taxes: model });
  }
}
