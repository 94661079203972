import { validateFormat } from 'ember-changeset-validations/validators';
import { customMessageValidator } from 'uplisting-frontend/validators';

export const BookingValidation = {
  guestEmail: [
    customMessageValidator(
      'booking_guest_email',
      validateFormat({
        allowBlank: true,
        type: 'email',
      }),
    ),
  ],
};
