import Model, { attr, belongsTo } from '@ember-data/model';
import { IAirbnbOfficialNotificationSchema } from 'uplisting-frontend/models/schemas';
import NotificationModel from 'uplisting-frontend/models/channel-notification';
import AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';

export default class AirbnbOfficialNotificationModel
  extends Model
  implements IAirbnbOfficialNotificationSchema
{
  @attr('string') type!: string;
  @attr('string') title!: string;
  @attr('string') subtitle!: string;
  @attr('string') description!: string;
  @attr('string') ctaUrl!: string;

  @belongsTo('channel-notification', {
    inverse: 'airbnbOfficialNotifications',
    async: false,
  })
  channelNotification!: NotificationModel;

  @belongsTo('airbnb-official-connection', { inverse: null, async: false })
  airbnbOfficialConnection!: AirbnbOfficialConnectionModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'airbnb-official-notification': AirbnbOfficialNotificationModel;
  }
}
