import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import {
  IBookingSchema,
  OccasionStatus,
} from 'uplisting-frontend/models/schemas';
import { cached } from '@glimmer/tracking';
import CalendarModel from 'uplisting-frontend/models/calendar';
import OccasionModel from 'uplisting-frontend/models/occasion';
import PropertyModel from 'uplisting-frontend/models/property';
import PriceModel from 'uplisting-frontend/models/price';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import MessageModel from 'uplisting-frontend/models/message';
import ChannelUserModel from 'uplisting-frontend/models/channel-user';
import ScheduledMessageModel from 'uplisting-frontend/models/scheduled-message';
import GuestIdentityVerificationModel from 'uplisting-frontend/models/guest-identity-verification';
import { type Registry as Services, inject as service } from '@ember/service';
import { differenceInDays } from 'date-fns/differenceInDays';
import { isBefore } from 'date-fns/isBefore';

export default class BookingModel extends Model implements IBookingSchema {
  @service translations!: Services['translations'];

  @attr('string') arrivalTime!: string;
  @attr('string') channel!: string;
  @attr('string') checkIn!: string;
  @attr('string') checkOut!: string;
  @attr('string') departureTime!: string;
  @attr('string') guestName!: string;
  @attr('string') guestPhone!: string;
  @attr('string') status!: OccasionStatus;
  @attr('string') guestEmail!: string;
  @attr('string') channelName!: string;
  @attr('string') preferredGuestName!: string;
  @attr('boolean') imported!: boolean;
  @attr('boolean') deletable!: boolean;
  @attr('date') createdAt!: Date;
  @attr('date') bookedAt!: Date;
  @attr('string') note!: string;
  @attr('string') externalReservationId!: string;
  @attr('string') externalReservationLink!: string;
  @attr('boolean') viaApi!: boolean;

  @cached
  get displayChannel(): string {
    if (this.channel) {
      return this.translations.getChannelNameFor(this.channel);
    }

    return this.channelName;
  }

  @cached
  get displayGuestName(): string {
    return this.preferredGuestName ?? this.guestName;
  }

  @cached
  get displayName(): string {
    const {
      multiUnit,
      property: { nickname },
    } = this;

    if (multiUnit) {
      return `${multiUnit.name} - ${nickname}`;
    }

    return nickname;
  }

  @cached
  get isGoogle(): boolean {
    return this.channel === 'google';
  }

  @cached
  get isUplisting(): boolean {
    return this.channel === 'uplisting';
  }

  @cached
  get isAirbnbOfficial(): boolean {
    return this.channel === 'airbnb_official';
  }

  @cached
  get isIcal(): boolean {
    if (this.viaApi) {
      return false;
    }

    return !(this.isUplisting || this.isGoogle);
  }

  @cached
  get duration(): number {
    return differenceInDays(new Date(this.checkOut), new Date(this.checkIn));
  }

  @cached
  get currency(): string {
    return this.price.currency ?? this.property.currency;
  }

  @cached
  get canSendMessage(): boolean {
    switch (this.channel) {
      case 'booking_dot_com':
      case 'airbnb_official':
        return true;
      case 'home_away':
        return this.viaApi;
      case 'google':
      case 'uplisting':
        return !!this.guestEmail;
      default:
        return false;
    }
  }

  @cached
  get messagesSorted(): MessageModel[] {
    return this.messages.slice().sort((x, y) => {
      if (isBefore(x.createdAt, y.createdAt)) {
        return -1;
      } else if (isBefore(y.createdAt, x.createdAt)) {
        return 1;
      }

      return 0;
    });
  }

  @cached
  get checkInDate(): Date {
    return new Date(`${this.checkIn}:${this.arrivalTime}`);
  }

  @cached
  get checkOutDate(): Date {
    return new Date(`${this.checkOut}:${this.departureTime}`);
  }

  @belongsTo('occasion', { inverse: null, async: false })
  occasion!: OccasionModel;

  @belongsTo('property', { inverse: null, async: false })
  property!: PropertyModel;

  @belongsTo('calendar', { inverse: 'bookings', async: false })
  calendar!: CalendarModel;

  @belongsTo('price', { inverse: 'booking', async: false })
  price!: PriceModel;

  @belongsTo('multi-unit', { inverse: null, async: false })
  multiUnit?: MultiUnitModel;

  @belongsTo('guest-identity-verification', {
    inverse: 'booking',
    async: false,
  })
  guestIdentityVerification!: GuestIdentityVerificationModel;

  @belongsTo('channel-user', { async: false, inverse: null })
  channelUser!: ChannelUserModel;

  @hasMany('message', { async: false, inverse: 'booking' })
  messages!: MessageModel[];

  @hasMany('scheduled-message', { inverse: 'booking', async: false })
  scheduledMessages!: ScheduledMessageModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    booking: BookingModel;
  }
}
