import Model, { attr } from '@ember-data/model';
import { IChannelUserSchema } from 'uplisting-frontend/models/schemas';

export default class ChannelUserModel
  extends Model
  implements IChannelUserSchema
{
  @attr('string') guestProfileLink!: string;
  @attr('string') guestProfilePicture!: string;
  @attr('boolean') verified!: boolean;
  @attr('number') reviews!: number;
  @attr('number') rating!: number;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'channel-user': ChannelUserModel;
  }
}
