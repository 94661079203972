import { ValidatorFunction } from 'ember-changeset-validations';
import ApplicationInstance from '@ember/application/instance';
import { application } from 'uplisting-frontend/utils';
import IntlService from 'ember-intl/services/intl';
import config from 'ember-get-config';

let intl = {
  t(key: string): string {
    return key;
  },
};

if (!config.IS_TEST) {
  intl = (application.instance as ApplicationInstance).lookup(
    'service:intl',
  ) as IntlService;
}

export function customMessageValidator(
  validationMessageKey: string,
  validator: ValidatorFunction,
) {
  return (
    key: string,
    newValue: string,
    oldValue: string,
    changes: Record<string, unknown>,
    content: Record<string, unknown>,
  ): string | undefined => {
    const isInvalid =
      validator(key, newValue, oldValue, changes, content) !== true;

    if (isInvalid) {
      return intl.t(`validation_messages.custom.${validationMessageKey}`);
    }
  };
}
