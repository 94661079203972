import UiConnectionsIndexComponent from 'uplisting-frontend/pods/components/ui/connections/index';
import { cached, tracked } from '@glimmer/tracking';
import AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import {
  AIRBNB_CONNECT_LINK,
  AIRBNB_UPGRADE_LINK,
} from 'uplisting-frontend/utils';
import { runTask } from 'ember-lifeline';

export default class UiConnectionsAirbnbComponent extends UiConnectionsIndexComponent<AirbnbOfficialConnectionModel> {
  @service router!: Services['router'];

  upgradeLink = AIRBNB_UPGRADE_LINK;

  @cached @tracked showUpgradeConnectionModal = false;

  @cached
  get profileLink(): string {
    return `https://www.airbnb.com/users/show/${this.connection.airbnbUserId}`;
  }

  @cached
  get reconnectLink(): string {
    return this.connection.readOnly ? AIRBNB_CONNECT_LINK : AIRBNB_UPGRADE_LINK;
  }

  @cached
  get isConnected() {
    if (!this.connection.isConnected) {
      return false;
    }

    return this.connection.propertiesCountPresent;
  }

  @action
  async handleRefresh(): Promise<void> {
    const { connection } = this;

    await connection.reload({
      adapterOptions: { refresh: true, include: 'all_listings' },
    });

    connection.status = 'connecting';
    connection.selectedListingIds = connection.allListings.map(
      (listing) => listing.id,
    );

    await connection.save();

    this.refresh();
  }

  @action
  async handleRemoveConnection(): Promise<void> {
    await super.handleRemoveConnection(
      'connect_airbnb.notifications.success',
      AnalyticsEvents.airbnbConnectionRemoved,
    );
  }

  private refresh(): void {
    if (!this.connection.isConnected) {
      return;
    }

    const delay = 1_000;

    runTask(
      this,
      async () => {
        try {
          await this.connection.reload();

          this.refresh();
        } catch {
          await this.router.transitionTo('connect.airbnb', {
            queryParams: { problem: true },
          });
        }
      },
      delay,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connections::Airbnb': typeof UiConnectionsAirbnbComponent;
  }
}
