import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import { type Registry as Services, inject as service } from '@ember/service';
import RSVP from 'rsvp';
import Transition from '@ember/routing/transition';

export default class AutomateMessageTagsRoute extends PermittedRoute {
  @service('repositories/custom-message-tag')
  customMessageTagRepository!: Services['repositories/custom-message-tag'];

  @service('repositories/custom-property-attribute')
  customPropertyAttributeRepository!: Services['repositories/custom-property-attribute'];

  permission = 'automate.message_tags';

  model() {
    return RSVP.all([
      this.customMessageTagRepository.findAll(),
      this.customPropertyAttributeRepository.findAll(),
    ]);
  }

  // don't unload `custom-property-attribute` records as they are used on the following routes:
  // - `automate.property-attributes`
  // - `automate.rental-agreements`
  resetController(_controller, _isExiting, transition: Transition): void {
    const newRouteName = transition.to?.name;

    this.customMessageTagRepository.unloadAll();

    if (
      newRouteName !== 'automate.property-attributes' &&
      newRouteName !== 'automate.rental-agreements'
    ) {
      this.customPropertyAttributeRepository.unloadAll();
    }
  }
}
