import ApplicationV1Adapter from 'uplisting-frontend/adapters/application-v1';
import DS from 'ember-data';
import ModelRegistry from 'ember-data/types/registries/model';

export default class CustomerAdapter extends ApplicationV1Adapter {
  urlForUpdateRecord<K extends keyof ModelRegistry>(
    _id: string,
    modelName: K,
    snapshot: DS.Snapshot<K>,
  ): string {
    return super.urlForUpdateRecord('me', modelName, snapshot);
  }
}

declare module 'ember-data/types/registries/adapter' {
  interface AdapterRegistry {
    customer: CustomerAdapter;
  }
}
