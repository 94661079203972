import DirectBaseController from 'uplisting-frontend/pods/direct/base-controller';
import { action } from '@ember/object';
import { isAnyInvalid } from 'uplisting-frontend/validations/helpers';

export default class DirectContentController extends DirectBaseController {
  @action
  async handleFormSubmit(): Promise<void> {
    const validations = await this.changeset.validate(
      'heroHeading',
      'heroTagline',
      'descriptionHeading',
      'aboutContent',
    );

    if (isAnyInvalid(validations)) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info('direct_content.notifications.saved');
    } catch {
      this.notifications.error();
    }
  }
}
