import {
  validatePresence,
  validateLength,
} from 'ember-changeset-validations/validators';
import { customMessageValidator } from 'uplisting-frontend/validators';

export const ManagementFeeRuleValidation = {
  name: [validatePresence(true), validateLength({ min: 1, max: 100 })],

  // as we are showing the prettified version of the text - we need to validate it
  prettifiedValue: customMessageValidator(
    'management_fee_rule_formula',
    validatePresence({ presence: true }),
  ),
};
