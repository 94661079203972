import Model, { attr, hasMany } from '@ember-data/model';
import PropertyModel from 'uplisting-frontend/models/property';
import { cached } from '@glimmer/tracking';
import {
  OccasionEvent,
  OccasionPreposition,
  OccasionTermUnit,
  IAutomatedMessageRuleSchema,
} from 'uplisting-frontend/models/schemas';
import { type Registry as Services, inject as service } from '@ember/service';

export default class AutomatedMessageRuleModel
  extends Model
  implements IAutomatedMessageRuleSchema
{
  @service intl!: Services['intl'];

  @attr('string') name!: string;
  @attr('string', { defaultValue: '' }) content!: string;
  @attr('string') description!: string;
  @attr('string') time!: string;
  @attr('number') term!: number;
  @attr('boolean') enabled!: boolean;
  @attr('boolean') lastMinute!: boolean;
  @attr('string', { defaultValue: OccasionEvent.arrived })
  event!: OccasionEvent;

  @attr('string', { defaultValue: OccasionPreposition.before })
  preposition!: OccasionPreposition;

  @attr('string', { defaultValue: OccasionTermUnit.hours })
  termUnit!: OccasionTermUnit;

  @hasMany('property', { inverse: null, async: false })
  properties!: PropertyModel[];

  @cached
  get order(): number {
    if (!this.id) {
      return 1;
    }

    return this.primaryOrder + this.secondaryOrder;
  }

  @cached
  get primaryOrder(): number {
    switch (this.event) {
      case OccasionEvent.enquired:
        return 2;
      case OccasionEvent.booked:
        return 3;
      case OccasionEvent.arrived:
        if (this.preposition === OccasionPreposition.before) {
          return 4;
        }

        return 5;
      case OccasionEvent.departed:
        if (this.preposition === OccasionPreposition.before) {
          return 6;
        }

        return 7;
    }
  }

  @cached
  get secondaryOrder(): number {
    const order = 1 / this.termInMinutes;

    if (this.preposition === OccasionPreposition.before) {
      return order;
    }

    return 1 - order;
  }

  @cached
  get termInMinutes(): number {
    // term could be 0:
    // adding 1 to avoid division by zero
    // adding 1 more to avoid 1 as secondaryOrder
    const adjustedTerm = this.term + 2;

    switch (this.termUnit) {
      case OccasionTermUnit.minutes:
        return adjustedTerm;
      case OccasionTermUnit.hours:
        return adjustedTerm * 60;
      case OccasionTermUnit.days:
        return adjustedTerm * 3600;
    }
  }

  @cached
  get rulePrettified(): string {
    const { intl } = this;

    const term = intl.t(`time_formatted.${this.termUnit}`, {
      count: this.term,
    });
    const preposition = intl.t(`preposition.${this.preposition}`);
    const event = intl.t(`event.${this.event}`);

    return `${term} ${preposition} ${event}`;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'automated-message-rule': AutomatedMessageRuleModel;
  }
}
