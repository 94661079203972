import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class ConnectApiRoute extends BaseRoute {
  async model() {
    try {
      return await this.store.findRecord('partner-api-key', 'me');
    } catch {
      return this.store.createRecord('partner-api-key');
    }
  }
}
