import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import AirbnbOfficialOpportunityModel from 'uplisting-frontend/models/airbnb-official-opportunity';

export default class AirbnbOfficialOpportunityRepositoryService extends BaseRepositoryService<AirbnbOfficialOpportunityModel> {
  recordName = 'airbnb-official-opportunity';
  implementMethods = ['unloadAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/airbnb-official-opportunity': AirbnbOfficialOpportunityRepositoryService;
  }
}
