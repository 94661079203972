import Component from '@glimmer/component';

interface IArgs {
  isReadOnlyAirbnb: boolean;
}

export interface BookingReadOnlyMessageHeaderSignature {
  Element?: HTMLDivElement;

  Args: IArgs;
}

export default class UiReadOnlyMessageHeaderComponent extends Component<BookingReadOnlyMessageHeaderSignature> {}
