import DirectBaseController from 'uplisting-frontend/pods/direct/base-controller';
import { action } from '@ember/object';

export default class DirectTermsController extends DirectBaseController {
  @action
  handleInputChange(event: Event): void {
    this.changeset.termsAndConditions = (
      event.target as HTMLTextAreaElement
    ).value;
  }

  @action
  async handleSave(): Promise<void> {
    try {
      await this.changeset.save();

      this.notifications.info('direct_terms.notifications.saved');
    } catch {
      this.notifications.error();
    }
  }
}
