import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import InsightModel from 'uplisting-frontend/models/insight';

export default class InsightRepositoryService extends BaseRepositoryService<InsightModel> {
  recordName = 'insight';
  implementMethods = ['query'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/insight': InsightRepositoryService;
  }
}
