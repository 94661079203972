import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import BookingRentalAgreementSettingModel from 'uplisting-frontend/models/booking-rental-agreement-setting';
import { BookingRentalAgreementSettingValidation } from 'uplisting-frontend/validations';
import DS from 'ember-data';

export default class BookingRentalAgreementSettingRepositoryService extends BaseRepositoryService<BookingRentalAgreementSettingModel> {
  recordName = 'booking-rental-agreement-setting';
  implementMethods = [
    'createRecord',
    'buildChangeset',
    'findRecord',
    'unloadAll',
  ];

  validation = BookingRentalAgreementSettingValidation;

  public async getDefaultRecord(): Promise<
    DS.PromiseObject<BookingRentalAgreementSettingModel>
  > {
    let record;

    try {
      record = await this.findRecord('me');
    } catch {
      record = this.createRecord();
    }

    return record;
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/booking-rental-agreement-setting': BookingRentalAgreementSettingRepositoryService;
  }
}
