import { helper } from '@ember/component/helper';
import { toDate } from 'date-fns-tz';
import { format as formatDateWithoutTimezone } from 'date-fns/format';

export function formatDate(value: Date | string, format: string): string {
  try {
    const date = value instanceof Date ? value : toDate(value);

    return formatDateWithoutTimezone(date, format);
  } catch {
    // in the test environment ember-intl mocks text(all of our rendered text goes through the ember-intl)
    // and instead of dates, ember-intl translation keys are passed
    // which throws an error, as text can't be formatted as dates
    return value as unknown as string;
  }
}

export function dateFormat([value, format]: [Date | string, string]): string {
  return formatDate(value, format || 'EEE, MMM do');
}

export default helper(dateFormat);
