import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import SeamLockableModel from 'uplisting-frontend/models/seam-lockable';

export default class SeamLockableRepositoryService extends BaseRepositoryService<SeamLockableModel> {
  recordName = 'seam-lockable';
  implementMethods = ['unloadAll', 'createRecord'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/seam-lockable': SeamLockableRepositoryService;
  }
}
