export enum CustomMessageTagChannel {
  airbnb = 'airbnb',
  bookingDotCom = 'booking_dot_com',
  google = 'google',
  homeAway = 'home_away',
  uplisting = 'uplisting',
}

export interface ICustomMessageTagSchema {
  name: string;
  description: string;
  content: string;
  channels: CustomMessageTagChannel[];
}
