import Component from '@glimmer/component';
import AutomatedReviewTemplateModel from 'uplisting-frontend/models/automated-review-template';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';

interface IArgs {
  changeset: GenericChangeset<AutomatedReviewTemplateModel>;
  onSave(): Promise<void>;
  onClose(): void;
}

interface AutomateCustomPropertyAttributeRowSignature {
  Element: HTMLFormElement;

  Args: IArgs;
}

export default class UiAutomateAutomatedReviewTemplateFormComponent extends Component<AutomateCustomPropertyAttributeRowSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::AutomatedReviewTemplate::Form': typeof UiAutomateAutomatedReviewTemplateFormComponent;
  }
}
