import BookingModel from 'uplisting-frontend/models/booking';
import AutomatedMessageRuleModel from 'uplisting-frontend/models/automated-message-rule';

export enum ScheduledMessageStatus {
  sent = 'sent',
  tooLate = 'too_late',
  failed = 'failed',
  pending = 'pending',
}

export interface IScheduledMessageSchema {
  enabled: boolean;
  lastError: string;
  scheduledFor: Date;
  sentAt: Date;
  status: ScheduledMessageStatus;

  booking: BookingModel;
  automatedMessageRule: AutomatedMessageRuleModel;
}
