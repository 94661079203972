import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import PromotionModel from 'uplisting-frontend/models/promotion';
import sortBy from 'lodash-es/sortBy';
import { type Registry as Services, inject as service } from '@ember/service';

export default class DirectPromotionsController extends BaseController {
  @service('repositories/promotion')
  promotionRepository!: Services['repositories/promotion'];

  @cached
  get promotions(): PromotionModel[] {
    return this.promotionRepository.peekAll();
  }

  @cached
  get promotionsOrdered(): PromotionModel[] {
    return sortBy(this.promotions, 'order');
  }

  @cached
  get createNewDisabled(): boolean {
    return this.promotions.some((promotion) => !promotion.isPersisted);
  }

  @action
  handleCreatePromotion() {
    this.promotionRepository.createRecord();
  }
}
