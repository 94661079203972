import Component from '@glimmer/component';

interface IArgs {
  text: string;
}

interface PowerSelectTriggerEmptySignature {
  Element: null;

  Args: IArgs;
}

export default class UiPowerSelectTriggerEmptyComponent extends Component<PowerSelectTriggerEmptySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/power-select/trigger/empty': typeof UiPowerSelectTriggerEmptyComponent;
    'Ui::PowerSelect::Trigger::Empty': typeof UiPowerSelectTriggerEmptyComponent;
  }
}
