/**
 * @description toggle presence of value in array
 *
 * @param {any} context - Current object context
 * @param {String} field - field name of the current object
 * @param {any} value - item to add/remove
 *
 * @return {void}
 */
export function toggleValue(context: any, field: string, value: any): void {
  const data = context[field];

  if (data.includes(value)) {
    data.splice(data.indexOf(value), 1);

    context[field] = [...data];
  } else {
    context[field] = [...data, value];
  }
}

export function toggleHasManyValue(
  context: any,
  field: string,
  value: any,
): void {
  const relationship = context[field];

  if (relationship.includes(value)) {
    relationship.splice(relationship.indexOf(value), 1);
  } else {
    relationship.push(value);
  }
}
