import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import ChangeoverModel from 'uplisting-frontend/models/changeover';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';

interface IArgs {
  item: ChangeoverModel;
  date: string;
}

interface ActionTableRowChangeoverSignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiActionTableRowChangeoverComponent extends Component<ActionTableRowChangeoverSignature> {
  @service router!: Services['router'];

  @cached
  get item(): ChangeoverModel {
    return this.args.item;
  }

  @action
  async handleItemClick(): Promise<void> {
    await this.router.transitionTo('action.changeovers.summary', this.item.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Row::Changeover': typeof UiActionTableRowChangeoverComponent;
    'ui/action/table/row/changeover': typeof UiActionTableRowChangeoverComponent;
  }
}
