import Component from '@glimmer/component';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface OccasionSearchBarPlaceholderComponentSignature {
  Element: null;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiOccasionSearchBarPlaceholderComponent extends Component<OccasionSearchBarPlaceholderComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::OccasionSearchBarPlaceholder': typeof UiOccasionSearchBarPlaceholderComponent;
    'ui/occasion-search-bar-placeholder': typeof UiOccasionSearchBarPlaceholderComponent;
  }
}
