import BaseController from 'uplisting-frontend/pods/base/controller';
import config from 'ember-get-config';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { type ModelRegistry } from 'ember-data/types/registries/model';

export default class AccountBillingController extends BaseController {
  @service pricing!: Services['pricing'];
  @service features!: Services['features'];

  @cached @tracked customer!: ModelRegistry['customer'];
  @cached @tracked seamConnections!: ModelRegistry['seam-connection'][];
  @cached @tracked changeoverSettings!: ModelRegistry['changeover-setting'][];

  @cached
  @tracked
  securityDepositSettings!: ModelRegistry['security-deposit-setting'][];

  @cached
  @tracked
  guestIdentityVerificationConfiguration!: ModelRegistry['guest-identity-verification-configuration'];

  @cached @tracked isCheckingOut = false;
  @cached @tracked isCancelling = false;

  @cached
  get smartLocksActive(): boolean {
    return (
      this.seamConnections.filter(
        (connection) => connection.seamLockables.length,
      ).length !== 0
    );
  }

  @cached
  get securityDepositActive(): boolean {
    return this.securityDepositSettings.some((setting) => setting.enabled);
  }

  @cached
  get guestIdentityActive(): boolean {
    return this.guestIdentityVerificationConfiguration.isEnabled;
  }

  @cached
  get cleaningActive(): boolean {
    return this.changeoverSettings.some((setting) => setting.enabled);
  }

  @cached
  get currencyClass(): string {
    return `${this.pricing.currency} bold`;
  }

  @action
  handleSelectCurrency(currency: string): void {
    this.customer.currency = currency;
  }

  @action
  handlePlanTypeChanged(annual: boolean): void {
    this.customer.annual = annual;
  }

  @action
  async handleCheckout(): Promise<void> {
    if (this.isCheckingOut) {
      return;
    }

    this.isCheckingOut = true;

    if (window.Rewardful?.referral) {
      this.customer.referral = window.Rewardful.referral;
    }

    try {
      await this.customer.save();

      window.location.href = this.customer.checkoutUrl;
    } catch {
      this.notifications.error();
    } finally {
      this.isCheckingOut = false;
    }
  }

  @action
  async handleCancel(): Promise<void> {
    if (this.isCancelling) {
      return;
    }

    this.isCancelling = true;

    try {
      const cancellation = this.store.createRecord('customer-cancellation');

      await cancellation.save();

      window.churnkey.init('show', {
        customerId: cancellation.customerId,
        authHash: cancellation.hmac,
        appId: config.churnkey.appId,
        mode: config.churnkey.mode,
        provider: 'stripe',
        record: true,
      });
    } catch {
      this.notifications.error();
    } finally {
      this.isCancelling = false;
    }
  }
}
