import ClientStatementExpenseModel from 'uplisting-frontend/models/client-statement-expense';
import ClientStatementReportModel from 'uplisting-frontend/models/client-statement-report';
import ClientModel from 'uplisting-frontend/models/client';
import PropertyModel from 'uplisting-frontend/models/property';

export enum ClientStatementStatus {
  draft = 'draft',
  finalised = 'finalised',
  processing = 'processing',
}

export interface IClientStatementSchema {
  status: ClientStatementStatus;
  htmlVersion: string;
  fileLink: string;
  startDate: string;
  endDate: string;
  currency: string;
  invoiceDate: Date;
  invoiceNumber: string;
  expenses: ClientStatementExpenseModel[];
  client: ClientModel;
  property: PropertyModel;
  report: ClientStatementReportModel;
}
