import { type Registry as Services } from '@ember/service';

export enum CookieKey {
  ACTION_PERIOD = 'actionPeriod',
  ACTION_FILTERS = 'filteredEventTypes',
  ACTION_PROPERTIES = 'propertySearchCriteria',
}

export function readWithDefault<T extends string>(
  service: Services['cookies'],
  key: CookieKey,
  value: T,
): T {
  if (!service.exists(key)) {
    service.write(key, value);
  }

  return service.read<T>(key) as T;
}
