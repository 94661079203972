import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import AutoresponderModel from 'uplisting-frontend/models/autoresponder';
import { AutoresponderValidation } from 'uplisting-frontend/validations';

export default class AutoresponderRepositoryService extends BaseRepositoryService<AutoresponderModel> {
  recordName = 'autoresponder';
  implementMethods = ['buildChangeset', 'findRecord', 'unloadAll'];

  validation = AutoresponderValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/autoresponder': AutoresponderRepositoryService;
  }
}
