import Model, { attr } from '@ember-data/model';
import { IExtraPriceSchema } from 'uplisting-frontend/models/schemas';

export default class ExtraPriceModel
  extends Model
  implements IExtraPriceSchema
{
  @attr('string') type!: string;
  @attr('number') amount!: number;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'extra-price': ExtraPriceModel;
  }
}
