import {
  validateLength,
  validatePresence,
  validateFormat,
  validateInclusion,
} from 'ember-changeset-validations/validators';
import { customMessageValidator } from 'uplisting-frontend/validators';
import { CUSTOM_SUBDOMAIN_OPTIONS } from 'uplisting-frontend/models/direct-booking-configuration';

export const DirectBookingConfigurationValidation = {
  googleAnalyticsKey: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),

  facebookPixelKey: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),

  intercomKey: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),

  googleConversionId: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),

  googleConversionLabel: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),

  heroHeading: validateLength({
    max: 30,
  }),

  heroTagline: validateLength({
    max: 110,
  }),

  descriptionHeading: validateLength({
    max: 30,
  }),

  aboutContent: validateLength({
    max: 800,
  }),

  subdomain: [
    validateLength({ min: 3, max: 63 }),
    validateFormat({ regex: /^[a-z0-9]{1}[a-z0-9-]+$/i, allowBlank: true }),
  ],

  customDomain: [
    validateLength({ min: 3, max: 63 }),
    validateFormat({
      regex: /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i,
      allowBlank: true,
    }),
  ],

  logoHref: customMessageValidator(
    'direct_booking_custom_logo_href',
    validateFormat({
      regex:
        /https?:\/\/(www\.)?[a-zA-Z0-9][a-zA-Z-0-9]*(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-{}]*[\w@?^=%&amp;/~+#-{}])??/,
      allowBlank: true,
    }),
  ),

  customSubdomainOption: validateInclusion({
    list: CUSTOM_SUBDOMAIN_OPTIONS,
  }),
};
