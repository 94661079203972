import Model, { attr } from '@ember-data/model';
import { IAutomatedReviewTemplateSchema } from 'uplisting-frontend/models/schemas';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached } from '@glimmer/tracking';

export default class AutomatedReviewTemplateModel
  extends Model
  implements IAutomatedReviewTemplateSchema
{
  @service intl!: Services['intl'];

  @attr('number') sequence!: number;
  @attr('boolean') enabled!: boolean;
  @attr('string') content!: string;

  @cached
  get templateName(): string {
    const { sequence } = this;

    const templateName = this.intl.t('automate_reviews.table.template_name');

    if (sequence === 0) {
      return templateName;
    }

    return `${templateName} ${sequence}`;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'automated-review-template': AutomatedReviewTemplateModel;
  }
}
