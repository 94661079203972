import BaseController from 'uplisting-frontend/pods/base/controller';

export default class ConnectCallbackStripeController extends BaseController {
  queryParams = ['code', 'error'];
  code;
  error;

  public async handleCallbackResponse(): Promise<unknown> {
    const { error } = this;

    if (error) {
      return this.openConnectPage({ problem: true });
    }

    const { code } = this;

    if (!code) {
      return;
    }

    const connection = this.store.createRecord('stripe-connection', {
      code,
      status: 'connecting',
    });

    try {
      await connection.save();

      await this.openConnectPage({ refresh: true });
    } catch {
      await this.openConnectPage({ problem: true });
    }
  }

  private async openConnectPage(queryParams): Promise<unknown> {
    return await this.router.transitionTo('connect.stripe', { queryParams });
  }
}
