import Model, { attr, belongsTo } from '@ember-data/model';
import { ITaxSchema, TaxRateType } from 'uplisting-frontend/models/schemas';
import ClientModel from 'uplisting-frontend/models/client';

export default class TaxModel extends Model implements ITaxSchema {
  @attr('string') type!: string;
  @attr('number') rate!: number;
  @attr('string') rateType!: TaxRateType;
  @attr('string') description!: string;

  @belongsTo('client', { inverse: 'tax', async: false })
  client!: ClientModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    tax: TaxModel;
  }
}
