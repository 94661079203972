import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import UpsellOrderModel from 'uplisting-frontend/models/upsell-order';

export default class UpsellOrderRepositoryService extends BaseRepositoryService<UpsellOrderModel> {
  recordName = 'upsell-order';
  implementMethods = ['query'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/upsell-order': UpsellOrderRepositoryService;
  }
}
