import {
  validatePresence,
  validateLength,
} from 'ember-changeset-validations/validators';
import {
  validateUniqueness,
  customMessageValidator,
} from 'uplisting-frontend/validators';

export const CustomMessageTagValidation = {
  name: [
    validatePresence({ presence: true }),
    customMessageValidator('unique_custom_message_tag', validateUniqueness()),
  ],

  description: [validatePresence({ presence: true })],

  content: [
    customMessageValidator(
      'custom_message_tag_content',
      validatePresence({
        presence: true,
      }),
    ),
  ],

  channels: [validateLength({ min: 1, allowNone: false })],
};
