import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { UploadFile } from 'ember-file-upload';
import { ONE_MB, bytesToReadable } from 'uplisting-frontend/utils';
import { ExternalError, ExternalErrors } from 'uplisting-frontend/utils/errors';
import { guidFor } from '@ember/object/internals';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import * as Sentry from '@sentry/ember';
import type EmberFileUploadRegistry from 'ember-file-upload/template-registry';

interface IArgs {
  imageKey: string;
  changeset: GenericChangeset<unknown>;
  label?: string;
  imageSizeLimit?: number;
  recommendRatioAndSize?: boolean;

  // eslint-disable-next-line no-use-before-define
  onInit?(component: UiImageUploadComponent): void;
  onUpload?(): Promise<void>;
  validateFileDimensions?(image: HTMLImageElement): string | undefined;
}

interface ImageUploadSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const allowedTypes = ['image/jpeg', 'image/png'];

export default class UiImageUploadComponent extends Component<ImageUploadSignature> {
  @service intl!: Services['intl'];
  @service fileHandler!: Services['file-handler'];

  supportedFileTypes = 'PNG, JPG';
  acceptImageTypes = allowedTypes.join(', ');

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);

    this.args.onInit?.(this);
  }

  @cached @tracked isUploadingImage = false;
  @cached @tracked isUploadingError = false;
  @cached @tracked isUploadingWarning = false;

  @cached @tracked warningMessage?: string;

  @cached
  get imageKey(): string {
    return this.args.imageKey;
  }

  @cached
  get changeset(): GenericChangeset<unknown> {
    return this.args.changeset;
  }

  @cached
  get imageSizeLimit(): number {
    return this.args.imageSizeLimit ?? ONE_MB;
  }

  @cached
  get recommendRatioAndSize(): boolean {
    return this.args.recommendRatioAndSize ?? true;
  }

  @cached
  get imageInputId(): string {
    return guidFor(this);
  }

  @cached
  get imageSizeLimitReadable(): string {
    return bytesToReadable(this.imageSizeLimit);
  }

  @cached
  get imageUrl(): string | undefined {
    return this.changeset[this.imageKey] as string;
  }

  @cached
  get hasError(): boolean {
    return !!this.changeset.error[this.imageKey]?.validation;
  }

  @action
  handleResetImage(e?: MouseEvent): void {
    e?.preventDefault();

    this.changeset[this.imageKey] = undefined;
  }

  @action
  handleTryAgain(e: MouseEvent): void {
    e.preventDefault();

    this.isUploadingError = false;
    this.isUploadingWarning = false;
    this.warningMessage = undefined;
  }

  @action
  async handleImageUpload(file: UploadFile): Promise<string | undefined> {
    if (!this.isFileValid(file.file)) {
      this.isUploadingWarning = true;

      this.warningMessage = this.intl.t('image_upload.invalid_image_size', {
        size: this.imageSizeLimitReadable,
      });

      return;
    }

    const isImage = await this.isUploadedFileImage(file);

    if (!isImage) {
      this.isUploadingError = true;

      return;
    }

    if (this.args.validateFileDimensions) {
      const message = await this.checkDimensions(
        file,
        this.args.validateFileDimensions,
      );

      if (message) {
        this.isUploadingWarning = true;

        this.warningMessage = message;

        return;
      }
    }

    this.isUploadingImage = true;
    this.isUploadingError = false;

    try {
      const result = await this.fileHandler.client.upload(file.file);

      this.changeset[this.imageKey] = result.url;

      await this.args.onUpload?.();
    } catch (err) {
      this.handleResetImage();

      const error = new ExternalError({
        name: ExternalErrors.LOGO_UPLOAD,
        message: 'logo upload failed',
        cause: err,
      });

      Sentry.captureException(error);

      this.isUploadingError = true;
    } finally {
      this.isUploadingImage = false;
    }
  }

  private isFileValid(file: File): boolean {
    return allowedTypes.includes(file.type) && file.size <= this.imageSizeLimit;
  }

  private async checkDimensions(
    file: UploadFile,
    callback: (target: HTMLImageElement) => string | undefined,
  ): Promise<string | undefined> {
    return this.readImage<string | undefined>(file, (image, resolve) => {
      image.onload = (event) => {
        return resolve(callback(event.target as HTMLImageElement));
      };
    });
  }

  private async isUploadedFileImage(file: UploadFile): Promise<boolean> {
    return this.readImage<boolean>(file, (image, resolve) => {
      image.onload = () => {
        return resolve(true);
      };

      image.onerror = () => {
        return resolve(false);
      };
    });
  }

  private async readImage<T>(
    file: UploadFile,
    callback: (image: HTMLImageElement, resolve) => void,
  ): Promise<T> {
    const dataUrl = await file.readAsDataURL();

    const image = new Image();
    image.src = dataUrl as string;

    return new Promise((resolve) => {
      callback(image, resolve);
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ImageUpload': typeof UiImageUploadComponent;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  export default interface Registry extends EmberFileUploadRegistry {}
}
