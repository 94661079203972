import Component from '@glimmer/component';
import { Select } from 'ember-power-select/components/power-select';
import { action } from '@ember/object';

interface IArgs {
  select: Select;
  placeholder: string;
  onInput(event: Event): void;
}

interface FiltersFilterMultiSelectBeforeOptionsSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class FiltersFilterMultiSelectBeforeOptionsComponent extends Component<FiltersFilterMultiSelectBeforeOptionsSignature> {
  @action
  handleResetInput(): void {
    this.args.select.actions.search('');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Filters::Filter::MultiSelect::BeforeOptions': typeof FiltersFilterMultiSelectBeforeOptionsComponent;
    'ui/filters/filter/multi-select/before-options': typeof FiltersFilterMultiSelectBeforeOptionsComponent;
  }
}
