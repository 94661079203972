import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import {
  IUpsellSchema,
  IUpsellFilter,
  OccasionFilters,
} from 'uplisting-frontend/models/schemas';
import TaxModel from 'uplisting-frontend/models/tax';
import PropertyModel from 'uplisting-frontend/models/property';

export default class UpsellModel extends Model implements IUpsellSchema {
  @attr('string') name!: string;
  @attr('string') nickname!: string;
  @attr('string') description!: string;
  @attr('string') currency!: string;
  @attr('string-or-empty') imageUrl!: null | string;
  @attr('boolean') requiresApproval!: boolean;
  @attr('array') occasionFilters!: OccasionFilters;
  @attr('object') from!: IUpsellFilter;
  @attr('object') to!: IUpsellFilter;
  @attr('number') price!: number;

  @hasMany('property', { inverse: 'upsells', async: false })
  properties!: PropertyModel[];

  @belongsTo('tax', { inverse: null, async: false })
  tax!: TaxModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    upsell: UpsellModel;
  }
}
