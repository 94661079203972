import Component from '@glimmer/component';
import { OccasionFilter } from 'uplisting-frontend/utils/occasion-filters';
import {
  IOccasionFilterSectionItem,
  IOccasionFilterSectionSubItem,
} from 'uplisting-frontend/services/occasions';
import { TableSectionFields } from 'uplisting-frontend/models/occasion';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';

interface IArgs {
  editableFilter?: OccasionFilter;
  sections?: IOccasionFilterSectionItem[];
  onDropdownClose(): void;
  onAddFilter(filter: OccasionFilter): void;
}

interface ReportFiltersSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiReportFiltersMenuComponent extends Component<ReportFiltersSignature> {
  @service('occasions') occasionsService!: Services['occasions'];

  @cached @tracked activeSection!: IOccasionFilterSectionItem;
  @cached @tracked activeSubSection!: TableSectionFields;

  @cached
  get editableFilter(): OccasionFilter | undefined {
    return this.args.editableFilter;
  }

  @cached
  get newFilter(): OccasionFilter | undefined {
    if (!this.activeSubSection) {
      return;
    }

    return this.occasionsService.createFilterFor(this.activeSubSection);
  }

  @cached
  get filter(): OccasionFilter {
    if (this.activeSubSection === this.editableFilter?.field) {
      return this.editableFilter;
    }

    return this.newFilter as OccasionFilter;
  }

  @cached
  get sections(): IOccasionFilterSectionItem[] {
    return this.args.sections || this.occasionsService.occasionFilters;
  }

  @action
  handleSubmit(): void {
    this.args.onAddFilter(this.filter);
    this.args.onDropdownClose();
  }

  @action
  handleSectionSwitch(section: IOccasionFilterSectionItem): void {
    this.activeSection = section;

    this.handleSubSectionSwitch(
      (section.items[0] as IOccasionFilterSectionSubItem).id,
    );
  }

  @action
  handleSubSectionSwitch(id: TableSectionFields): void {
    this.activeSubSection = id;
  }

  handleInsert = modifier(() => {
    const { editableFilter } = this;

    if (editableFilter) {
      const item = this.sections
        .flatMap((section) => section.items)
        .find((item) => item.id === editableFilter.field);

      if (!item) {
        return;
      }

      const section = this.sections.find((section) =>
        section.items.includes(item),
      );

      if (!section) {
        return;
      }

      this.activeSection = section;
      this.activeSubSection = item.id;
    } else {
      const section = this.sections[0] as IOccasionFilterSectionItem;

      this.activeSection = section;
      this.activeSubSection = (
        section.items[0] as IOccasionFilterSectionSubItem
      ).id;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Menu': typeof UiReportFiltersMenuComponent;
  }
}
