import Model, { attr, hasMany } from '@ember-data/model';
import PropertyModel from 'uplisting-frontend/models/property';
import { IReportSchema } from 'uplisting-frontend/models/schemas';

export default class ReportModel extends Model implements IReportSchema {
  @attr('date') from!: Date;
  @attr('date') to!: Date;
  @attr('boolean') includeCancelled!: boolean;
  @attr('array') types!: string[];

  @hasMany('property', { inverse: null, async: false })
  properties!: PropertyModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    report: ReportModel;
  }
}
