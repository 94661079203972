import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import AirbnbOfficialNotificationModel from 'uplisting-frontend/models/airbnb-official-notification';
import AirbnbOfficialOpportunityModel from 'uplisting-frontend/models/airbnb-official-opportunity';
import { action } from '@ember/object';

enum NavTab {
  opportunities,
  urgentNotifications,
}

export default class NotificationsAirbnbController extends BaseController {
  @cached @tracked activeNavTab = NavTab.urgentNotifications;
  @cached @tracked showApplyOpportunityModal = false;
  @cached @tracked selectedOpportunity:
    | AirbnbOfficialOpportunityModel
    | undefined;

  tabs = NavTab;

  @cached
  get isUrgentNotifications(): boolean {
    return this.activeNavTab === NavTab.urgentNotifications;
  }

  @cached
  get isOpportunities(): boolean {
    return this.activeNavTab === NavTab.opportunities;
  }

  @cached
  get airbnbNotifications(): AirbnbOfficialNotificationModel[] {
    return this.store.peekAll('airbnb-official-notification').slice();
  }

  @cached
  get airbnbOpportunities(): AirbnbOfficialOpportunityModel[] {
    return this.store.peekAll('airbnb-official-opportunity').slice();
  }

  @action
  handleActionClick(link: string): void {
    window.open(link, '_blank');
  }

  @action
  handleNavTabChange(tab: NavTab): void {
    this.activeNavTab = tab;
  }

  @action
  handleOpportunityApply(opportunity: AirbnbOfficialOpportunityModel): void {
    this.selectedOpportunity = opportunity;

    this.showApplyOpportunityModal = true;
  }

  @action
  handleCloseApplyOpportunityModal(): void {
    this.showApplyOpportunityModal = false;
  }
}
