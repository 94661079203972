import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import PermissionModel from 'uplisting-frontend/models/permission';
import groupBy from 'lodash-es/groupBy';

interface IArgs {
  level?: number;
  isDisabled?: boolean;
  item: PermissionModel;
  onUpdate(item: PermissionModel): void;
}

interface ClientsPermissionSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

type IChildrenBySubCategory = Record<string, PermissionModel[]>;

export default class UiClientsPermissionComponent extends Component<ClientsPermissionSignature> {
  @cached
  get item(): PermissionModel {
    return this.args.item;
  }

  @cached
  get level(): number {
    return this.args.level ?? 0;
  }

  @cached
  get isChildDisabled(): boolean {
    return this.args.isDisabled || !this.item.permitted;
  }

  @cached
  get showSubCategory(): boolean {
    return this.level === 0;
  }

  @cached
  get childrenBySubCategory(): IChildrenBySubCategory {
    return groupBy(
      (this.item.children as PermissionModel[]).slice(),
      'subCategory',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Clients::Permission': typeof UiClientsPermissionComponent;
  }
}
