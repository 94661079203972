import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

export interface IActionCard {
  title: string;
  description: string;
  featureText: string;
  featureLink: string;
  documentationLink: string;
  documentationText: string;
}

interface IArgs {
  actionCard: IActionCard;
}

interface ActionCardSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiActionCardComponent extends Component<ActionCardSignature> {
  @cached
  get card(): IActionCard {
    return this.args.actionCard;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ActionCard': typeof UiActionCardComponent;
  }
}
