import Helper from '@ember/component/helper';
import UserModel from 'uplisting-frontend/models/user';

export function hasAccessToActionHelper([user, route]: [
  UserModel,
  string,
]): boolean {
  return user?.hasAccessToAction(route);
}

export default Helper.helper(hasAccessToActionHelper);
