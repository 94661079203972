import BaseController from 'uplisting-frontend/pods/base/controller';
import { cached, tracked } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import SavedReplyModel from 'uplisting-frontend/models/saved-reply';
import { action } from '@ember/object';
import { searchOptions } from 'uplisting-frontend/utils';
import { type Registry as Services, inject as service } from '@ember/service';

export default class ActionBookingsRepliesIndexController extends BaseController {
  @service store!: Services['store'];

  @cached @tracked booking!: BookingModel;
  @cached @tracked savedReplies!: SavedReplyModel[];

  @cached @tracked inputValue = '';
  @cached @tracked showDeleteReplyModal = false;
  @cached @tracked replyToDelete?: SavedReplyModel;

  @cached
  get existingReplies(): SavedReplyModel[] {
    return this.savedReplies.filter((record) => record.id);
  }

  @cached
  get filteredReplies(): SavedReplyModel[] {
    return searchOptions<SavedReplyModel>(
      this.existingReplies,
      this.inputValue,
      ['name', 'content'],
    );
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  async handleInsertReply(reply: SavedReplyModel): Promise<void> {
    await this.router.transitionTo('action.bookings.messages', {
      queryParams: { reply: reply.id },
    });
  }

  @action
  handleShowDeleteReplyModel(reply: SavedReplyModel): void {
    this.showDeleteReplyModal = true;
    this.replyToDelete = reply;
  }

  @action
  handleCloseDeleteReplyModel(): void {
    this.showDeleteReplyModal = false;
    this.replyToDelete = undefined;
  }

  @action
  async handleDeleteReply(): Promise<void> {
    (this.replyToDelete as SavedReplyModel).destroyRecord();
  }
}
