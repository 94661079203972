import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { IAirbnbOfficialOpportunitySchema } from 'uplisting-frontend/models/schemas';
import ChannelNotificationModel from 'uplisting-frontend/models/channel-notification';
import AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';
import AirbnbOfficialOpportunityInputFieldModel from 'uplisting-frontend/models/airbnb-official-opportunity-input-field';
import AirbnbOfficialListingModel from 'uplisting-frontend/models/airbnb-official-listing';

export default class AirbnbOfficialOpportunityModel
  extends Model
  implements IAirbnbOfficialOpportunitySchema
{
  @attr('array') applicableListingIds!: string[];
  @attr('string') description!: string;
  @attr('number') hostCompletionPercentage!: number;
  @attr('string') title!: string;
  @attr('string') type!: string;

  @belongsTo('channel-notification', {
    inverse: 'airbnbOfficialOpportunities',
    async: false,
  })
  channelNotification!: ChannelNotificationModel;

  @belongsTo('airbnb-official-connection', {
    inverse: null,
    async: false,
  })
  airbnbOfficialConnection!: AirbnbOfficialConnectionModel;

  @hasMany('airbnb-official-opportunity-input-field', {
    inverse: null,
    async: false,
  })
  airbnbOfficialOpportunityInputFields!: AirbnbOfficialOpportunityInputFieldModel[];

  @hasMany('airbnb-official-listing', {
    inverse: null,
    async: false,
  })
  airbnbOfficialListings!: AirbnbOfficialListingModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'airbnb-official-opportunity': AirbnbOfficialOpportunityModel;
  }
}
