import {
  validatePresence,
  validateNumber,
} from 'ember-changeset-validations/validators';
import {
  validateUniqueness,
  customMessageValidator,
} from 'uplisting-frontend/validators';

export const TaxValidation = {
  rate: validateNumber({ gt: 0, lt: 100 }),
  type: [
    customMessageValidator('tax_name_present', validatePresence(true)),
    customMessageValidator('unique_tax_name', validateUniqueness()),
  ],
  rateType: validatePresence(true),
};
