import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from 'uplisting-frontend/serializers/application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  isEmbeddedRecordsMixinCompatible: true,

  attrs: {
    securityDepositSettings: {
      embedded: 'always',
    },
  },
});
