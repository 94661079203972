import BaseRoute from 'uplisting-frontend/pods/base/route';
import { type Registry as Services, inject as service } from '@ember/service';
import Transition from '@ember/routing/transition';

export default class ClientsDeactivateRoute extends BaseRoute {
  @service features!: Services['features'];

  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    if (!this.features.isClientManagementEnabledOnAccount) {
      await this.router.transitionTo('clients.index');
    }
  }
}
