import BaseRoute from 'uplisting-frontend/pods/base/route';
import ConnectAirbnbController from 'uplisting-frontend/pods/connect/airbnb/controller';
import Transition from '@ember/routing/transition';
import { type Registry as Services, inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class ConnectCallbackAirbnbRoute extends BaseRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  model() {
    return RSVP.hash({
      properties: this.propertyRepository.findAll(),
      airbnbOfficialConnections: this.store.findAll(
        'airbnb-official-connection',
      ),
    });
  }

  setupController(
    controller: ConnectAirbnbController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);

    controller.handleCallbackResponse();
  }
}
