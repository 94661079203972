import IntlService from 'ember-intl/services/intl';
import { dayNames, countriesList } from 'uplisting-frontend/utils';
import {
  feeRules,
  FEE_RULE_VARIABLES,
} from 'uplisting-frontend/models/management-fee-rule';

function setDaysTranslations(intl: IntlService): void {
  dayNames[0] = intl.t('dates.days.short.0');
  dayNames[1] = intl.t('dates.days.short.1');
  dayNames[2] = intl.t('dates.days.short.2');
  dayNames[3] = intl.t('dates.days.short.3');
  dayNames[4] = intl.t('dates.days.short.4');
  dayNames[5] = intl.t('dates.days.short.5');
  dayNames[6] = intl.t('dates.days.short.6');
}

function setCountriesTranslations(intl: IntlService): void {
  // @ts-expect-error - accessing private method
  Object.keys(intl.getIntl(intl.primaryLocale).messages)
    .filter((key) => key.startsWith('countries_list'))
    .forEach((country) => {
      countriesList.push(intl.t(country));
    });
}

function setFeeRuleTranslations(intl: IntlService): void {
  FEE_RULE_VARIABLES.splice(0, FEE_RULE_VARIABLES.length);

  feeRules.forEach((feeRule) => {
    FEE_RULE_VARIABLES.push({
      id: feeRule,
      name: intl.t(`fee_rules.${feeRule}`),
    });
  });
}

/**
 * @description for optimization reasons to save time for execution pollute global stored commonly used translations to the
 * global variables to reduce time to lookup
 */
export function setGlobalTranslations(intl: IntlService): void {
  setDaysTranslations(intl);
  setCountriesTranslations(intl);
  setFeeRuleTranslations(intl);
}
