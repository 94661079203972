import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import CustomMessageTagModel from 'uplisting-frontend/models/custom-message-tag';
import { CustomMessageTagValidation } from 'uplisting-frontend/validations';

export default class CustomMessageTagRepositoryService extends BaseRepositoryService<CustomMessageTagModel> {
  recordName = 'custom-message-tag';
  implementMethods = [
    'findAll',
    'buildChangeset',
    'createRecord',
    'unloadAll',
    'peekAll',
  ];

  validation = CustomMessageTagValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/custom-message-tag': CustomMessageTagRepositoryService;
  }
}
