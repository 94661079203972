import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { countriesList } from 'uplisting-frontend/utils';
import { BsFormSignature } from 'ember-bootstrap/components/bs-form';
import ClientModel from 'uplisting-frontend/models/client';

interface IArgs {
  label: string;
  property: keyof ClientModel;
  selected: string | undefined;
  form: BsFormSignature<ClientModel>;
}

interface CountrySelectorSignature {
  Element: HTMLSelectElement;

  Args: IArgs;
}

export default class UiCountrySelectorComponent extends Component<CountrySelectorSignature> {
  @cached
  get countriesList(): string[] {
    return ['', ...countriesList];
  }

  @action
  handleCountrySelect(
    setValue: (country?: string) => void,
    event: Event,
  ): void {
    const { value } = event.target as HTMLInputElement;

    // set value from the input or unset to undefined when empty option was selected
    setValue(value || undefined);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::CountrySelector': typeof UiCountrySelectorComponent;
  }
}
