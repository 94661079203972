import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import BookingModel from 'uplisting-frontend/models/booking';
import { BookingValidation } from 'uplisting-frontend/validations';

export default class BookingRepositoryService extends BaseRepositoryService<BookingModel> {
  recordName = 'booking';
  implementMethods = ['buildChangeset', 'findRecord'];

  validation = BookingValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/booking': BookingRepositoryService;
  }
}
