import { underscore } from '@ember/string';
import JSONAPISerializer from '@ember-data/serializer/json-api';

export default class ApplicationSerializer extends JSONAPISerializer {
  keyForAttribute(attr: string): string {
    return underscore(attr);
  }

  keyForRelationship(rawKey: string): string {
    return underscore(rawKey);
  }
}

// https://github.com/emberjs/data/blob/3ec0359017d321d43d914f4fffda51c0672f58b5/packages/serializer/src/json-api.js#L734-L764
// that method is not defined when not in the DEBUG mode
// fixing it so it doesn't throw an error in prod
ApplicationSerializer.reopen({
  warnMessageForUndefinedType() {
    return (
      'Encountered a resource object with an undefined type (resolved resource using ' +
      this.constructor.toString() +
      ')'
    );
  },

  warnMessageNoModelForType(modelName, originalType, usedLookup) {
    return `Encountered a resource object with type "${originalType}", but no model was found for model name "${modelName}" (resolved model name using '${this.constructor.toString()}.${usedLookup}("${originalType}")').`;
  },
});

declare module 'ember-data/types/registries/serializer' {
  interface SerializerRegistry {
    application: ApplicationSerializer;
  }
}
