import Component from '@glimmer/component';
import { OccasionFilterType } from 'uplisting-frontend/models/schemas';
import { OccasionFilterGroup } from 'uplisting-frontend/utils/occasion-filters';
import { action } from '@ember/object';
import { IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import { cached } from '@glimmer/tracking';

interface IArgs {
  filterGroup: OccasionFilterGroup;
  canChangeOperator?: boolean;
  onFilterTypeChange?(): void;
}

interface ReportFiltersOperatorSelectorSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiReportFiltersOperatorSelectorComponent extends Component<ReportFiltersOperatorSelectorSignature> {
  filterTypes = [OccasionFilterType.and, OccasionFilterType.or];

  @cached
  get filterGroup(): OccasionFilterGroup {
    return this.args.filterGroup;
  }

  @cached
  get canChangeOperator(): boolean {
    return this.args.canChangeOperator ?? true;
  }

  @action
  handleFilterTypeChange(filterType: OccasionFilterType, dd: IDropdown): void {
    this.filterGroup.type = filterType;

    dd.closeDropdown();

    this.args.onFilterTypeChange?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::OperatorSelector': typeof UiReportFiltersOperatorSelectorComponent;
  }
}
