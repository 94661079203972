import { isPresent } from '@ember/utils';

export function isAlreadyConnectedError(error): boolean {
  if (isPresent(error.errors)) {
    if (error.errors[0].status === '409') {
      return true;
    }
  }

  return false;
}
