export default {
  "word-break-md": "_word-break-md_1kphek",
  "cta": "_cta_1kphek",
  "btn": "_btn_1kphek",
  "form-control": "_form-control_1kphek",
  "scrollable": "_scrollable_1kphek",
  "multi-line": "_multi-line_1kphek",
  "table-small": "_table-small_1kphek",
  "table-vertical-small": "_table-vertical-small_1kphek"
};
