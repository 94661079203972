import { validateFormat } from 'ember-changeset-validations/validators';
import { customMessageValidator } from 'uplisting-frontend/validators';

export const MessageValidation = {
  content: [
    customMessageValidator(
      'message_content',
      validateFormat({
        regex: /\{[^}]+\}/,
        inverse: true,
      }),
    ),
  ],
  image: validateFormat({ allowBlank: true, type: 'url' }),
};
