import { cached, tracked } from '@glimmer/tracking';
import DataModel from '@ember-data/model';
import {
  BaseAbstractFilterParams,
  IBaseFilterParams,
  BaseAbstractFilter,
  FilterComponent,
} from 'uplisting-frontend/utils/filters/abstract/base';

class SingleSelectFilterParams<Model = DataModel | undefined>
  extends BaseAbstractFilterParams<string>
  implements IBaseFilterParams<string, string | Model>
{
  @cached @tracked value?: string;

  public updateValue(value: string | Model): void {
    if (typeof value === 'string') {
      this.value = value;
    }
  }

  public toQuery(): string | undefined {
    return this.value;
  }

  public reset(): void {
    this.value = undefined;
  }
}

export abstract class SingleSelectFilter<
  Model = DataModel | undefined,
> extends BaseAbstractFilter<string, string | Model> {
  component = FilterComponent.singleSelect;

  constructor(value?: string) {
    super(new SingleSelectFilterParams<Model>(value));
  }
}
