import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import UpsellsController, {
  UpsellType,
} from 'uplisting-frontend/pods/upsells/controller';
import { type Registry as Services, inject as service } from '@ember/service';

interface IParams {
  type: UpsellType;
}

export default class UpsellsRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  permission = 'upsells.index';

  queryParams = {
    type: {
      refreshModel: true,
    },
  };

  model(params: IParams) {
    const controller = this.controllerFor('upsells') as UpsellsController;

    Object.assign(controller, params);

    return this.propertyRepository.findAll();
  }
}
