import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import UserModel from 'uplisting-frontend/models/user';

export default class UserRepositoryService extends BaseRepositoryService<UserModel> {
  recordName = 'user';
  implementMethods = ['query', 'findRecord'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/user': UserRepositoryService;
  }
}
