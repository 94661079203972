import Model, { attr } from '@ember-data/model';
import { IOccupancyMetricSchema } from 'uplisting-frontend/models/schemas';

export default class OccupancyMetricModel
  extends Model
  implements IOccupancyMetricSchema
{
  @attr('number') booked!: number;
  @attr('number') available!: number;
  @attr('number') occupancy!: number;
  @attr('number') averageLengthOfStay!: number;
  @attr('number') averageLeadTime!: number;
  @attr('date') month!: Date;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'occupancy-metric': OccupancyMetricModel;
  }
}
