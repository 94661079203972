import Model, { hasMany } from '@ember-data/model';
import { ICalendarSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import ChangeoverModel from 'uplisting-frontend/models/changeover';

export default class CalendarModel extends Model implements ICalendarSchema {
  @hasMany('booking', { inverse: 'calendar', async: false })
  bookings!: BookingModel[];

  @hasMany('changeover', { inverse: 'calendar', async: false })
  changeovers!: ChangeoverModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    calendar: CalendarModel;
  }
}
