import BaseController from 'uplisting-frontend/pods/base/controller';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import DirectBookingConfigurationModel from 'uplisting-frontend/models/direct-booking-configuration';
import { GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { isAnyInvalid } from 'uplisting-frontend/validations/helpers';

export default class ConnectDirectGoogleAnalyticsController extends BaseController {
  @service('repositories/direct-booking-configuration')
  directBookingConfigurationRepository!: Services['repositories/direct-booking-configuration'];

  @cached
  get changeset(): GenericChangeset<DirectBookingConfigurationModel> {
    return this.directBookingConfigurationRepository.currentChangeset;
  }

  @action
  async handleSave(): Promise<void> {
    const validations = await this.changeset.validate('googleAnalyticsKey');

    if (isAnyInvalid(validations)) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifications.success(
        'connect_direct_google_analytics.alert.success',
      );
    } catch {
      this.notifications.error();
    }
  }
}
