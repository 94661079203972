import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ClientModel from 'uplisting-frontend/models/client';
import { ClientValidation } from 'uplisting-frontend/validations';

export default class ClientRepositoryService extends BaseRepositoryService<ClientModel> {
  recordName = 'client';
  implementMethods = [
    'findAll',
    'findRecord',
    'createRecord',
    'buildChangeset',
    'save',
  ];

  validation = ClientValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/client': ClientRepositoryService;
  }
}
